import { Table } from "react-bootstrap";
import React, { useContext, useState } from "react";
import styles from "./Home.module.scss";
import { observer } from "mobx-react-lite";
import moment from "moment";

import YandexLogo from "assets/icons/yandex.png";
import OzonLogo from "assets/icons/ozon.png";
import WildberriesLogo from "assets/icons/wildberries.png";
import { Cart } from "../../../stores/OrderStore";
import { RootStoreContext } from "../../../stores/RootStore";
import Modal from "components/Modal";
import CommentPopup from "../Cart/CommentPopup";
import Button from "components/Button";

const OrderTable: React.FC = observer(() => {
  const {
    userStore: { info },
    orderStore: { requestedOrders },
  } = useContext(RootStoreContext);

  const [order, setOrder] = useState<Cart | null>(null);
  console.log(requestedOrders);
  return (
    <div className={styles.OrderTable}>
      <Table striped hover>
        <thead>
          <tr>
            {info &&
              (info?.roles.includes("SuperAdmin") ||
                info?.roles.includes("WarehouseOwner")) && (
                <th>
                  <div className={styles.theadTh}>Продавец</div>
                </th>
              )}
            <th>
              <div className={styles.theadTh}>Наименование товар (1С)</div>
            </th>
            <th>Склад</th>
            <th>Кол.</th>
            <th>Цена РС</th>
            <th>Цена МП</th>
            <th>МП</th>
            <th>Номер заказа на МП</th>
            <th>Код поставщика</th>
            <th>Дата заказа</th>
            <th>Дата отгрузки</th>
            <th>Статус</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {requestedOrders &&
            requestedOrders.map((item, index) => (
              <tr
                key={index}
                className={
                  item.returnRequest === 2
                    ? styles.orangeColor
                    : item.returnRequest === 4
                    ? styles.greenColor
                    : "auto"
                }
              >
                {info &&
                  (info?.roles.includes("SuperAdmin") ||
                    info?.roles.includes("WarehouseOwner")) && (
                    <td>{item.seller}</td>
                  )}
                <td>
                  <a
                    href={`/warehouse/product/${item.productID}`}
                    target="_blank"
                    className={styles.cardTitle}
                  >
                    {item.productTitle}
                  </a>
                </td>
                <td>{item.warehouseName}</td>
                <td>{item.quantity}</td>
                <td>{parseFloat(Number(item.stockPrice).toFixed(2))} ₽</td>
                <td>{parseFloat(Number(item.price).toFixed(2))} ₽</td>
                <td className={styles.MPLogos}>
                  {item.marketPlaceName === "YandexMarket" ? (
                    <img src={YandexLogo} alt="ozon logo" />
                  ) : item.marketPlaceName === "Ozon" ? (
                    <img src={OzonLogo} alt="ozon logo" />
                  ) : (
                    <img src={WildberriesLogo} alt="ozon logo" />
                  )}
                </td>
                <td>{item.postingNumber}</td>
                <td>{item.supplierVendorCode}</td>
                <td>{moment(item.orderDate).format("DD.MM.YYYY")}</td>
                <td>
                  {item.shipmentDate
                    ? moment(item.shipmentDate).format("DD.MM.YYYY")
                    : ""}
                </td>
                <td>{item.status}</td>
                <td>
                  <Button
                    className={styles.payTbButton}
                    onClick={() => {
                      setOrder(item);
                    }}
                    disabled={item.returnRequest === 4}
                  >
                    {item.returnRequest === 1
                      ? "Комментировать"
                      : "Редактировать"}
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {order && (
        <Modal
          className={styles.cartModal}
          children={
            <CommentPopup
              id={order.id}
              pageName={"homePage"}
              operationType={order.returnRequest === 1 ? "Create" : "Edit"}
            />
          }
          isOpen={order !== null}
          onClose={() => {
            setOrder(null);
          }}
        />
      )}
    </div>
  );
});
export default OrderTable;
