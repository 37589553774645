import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import Button from "components/Button";
import { HiDocumentDownload } from "react-icons/hi";
import toast from "react-hot-toast";
import { TorgTablePart } from "stores/OrderStore";
import Modal from "components/Modal";
import TorgTablePartPopup from "./TorgTablePart";

interface Props {
  type: number;
}
const Torg12: React.FC<Props> = observer(({ type }) => {
  const {
    orderStore: {
      getTorg12List,
      signTorg12,
      torg12List,
      downloadTorg12,
      setTorg12List,
    },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [torgTablePart, setTorgTablePart] = useState<TorgTablePart[]>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalVat, setTotalVat] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [labelLoading, setLabelLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    setLoading(true);
    if (info) {
      getTorg12List(info?.id, info?.id, startDate, endDate).then(() => {
        setLoading(false);
      });
    }
  }, [getTorg12List, startDate, endDate, info]);

  return (
    <>
      <div className={styles.dates}>
        <div>
          <div>Период изменений</div>

          <div>
            от:
            {
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            }
          </div>

          <div>
            до:
            {
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            }
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={loading} size={50} />
        </div>
      ) : (
        <Table striped hover>
          <thead>
            <tr>
              <th>Номер документа</th>
              <th>Продавец</th>
              <th>Покупатель</th>
              <th>Дата создания</th>
              <th>Цена доставки и упаковки</th>
              <th>Итого цена</th>
              <th>Итого НДС</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {torg12List &&
              torg12List
                .filter((i) => i.torg12SellerType === type)
                .map((item, index) => (
                  <tr key={index}>
                    <td
                      className={styles.cardTitle}
                      onClick={() => {
                        setTorgTablePart(item.torg12TablePartModel);
                        setTotalPrice(item.totalPrice);
                        setTotalVat(item.totalVat);
                      }}
                    >
                      {item.torg12Nm}
                    </td>
                    <td>{item.seller}</td>
                    <td>{item.buyer}</td>
                    <td>{moment(item.creationDate).format("DD.MM.YYYY")}</td>
                    <td>{item.totalPackingPrice} ₽</td>
                    <td>{item.totalPrice} ₽</td>
                    <td>{item.totalVat} ₽</td>
                    <td className={styles.packageLabel}>
                      <div
                        style={{
                          cursor: labelLoading ? "progress" : "pointer",
                        }}
                        onClick={() => {
                          setLabelLoading(true);

                          downloadTorg12(item.torg12Nm).then((i) => {
                            if (!i) {
                              toast.error("Этикетка еще не готова.");
                            }
                            setLabelLoading(false);
                          });
                        }}
                        className={styles.packageLabelA}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Скачать</Tooltip>}
                        >
                          <div>
                            <HiDocumentDownload size={38} />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </td>
                    <td>
                      <Button
                        className={styles.payTbButton}
                        disabled={
                          (info?.id === item.sellerID && item.sellerSign) ||
                          (info?.id === item.buyerID && item.buyerSign)
                            ? true
                            : false
                        }
                        onClick={() => {
                          if (info?.id) {
                            signTorg12(info?.id, [item.torg12Nm]).then((i) => {
                              if (i) {
                                setTorg12List(
                                  torg12List.map((torg12) => {
                                    if (torg12.torg12Nm === item.torg12Nm) {
                                      return {
                                        ...torg12,
                                        sellerSign:
                                          info.id === torg12.sellerID
                                            ? "true"
                                            : null,
                                        buyerSign:
                                          info.id === torg12.buyerID
                                            ? "true"
                                            : null,
                                      };
                                    } else {
                                      return torg12;
                                    }
                                  })
                                );
                              }
                            });
                          }
                        }}
                      >
                        {(info?.id === item.sellerID && item.sellerSign) ||
                        (info?.id === item.buyerID && item.buyerSign)
                          ? "Подписано"
                          : "Подписать"}
                      </Button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      )}
      {torgTablePart?.length !== undefined && torgTablePart.length > 0 && (
        <Modal
          className={styles.cartModal}
          children={
            <TorgTablePartPopup
              torgTablePart={torgTablePart}
              totalPrice={totalPrice}
              totalVat={totalVat}
            />
          }
          isOpen={!!torgTablePart.length}
          onClose={() => {
            setTorgTablePart([]);
          }}
        />
      )}
    </>
  );
});
export default Torg12;
