import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import extendDefaultHeaders from "../utils/extendDefaultHeaders";
import handleApiPath from "../utils/handleApiPath";
import { toast } from "react-hot-toast";

interface EmailItems {
  emailHeader: string;
  emailContent: string;
  isHtmlContent: boolean;
  importance: boolean;
  createdDate: string;
  id: number;
}
class EmailStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  emails: EmailItems[] | null = null;

  unsentEmails: any | null = null;

  filters: { name: string; value: string; label: string }[] = [
    { name: "pageSize", value: "20", label: "20" },
  ];

  setEmails = (data: EmailItems[]) => {
    this.emails = data;
  };

  setUnsentEmails = (data: any) => {
    this.unsentEmails = data;
  };

  getEmails = async (): Promise<EmailItems[] | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(`${endpoint}/EmailsIndex`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setEmails(data);
      }

      return data;
    } catch (error) {
      return null;
    }
  };

  getUnsentEmails = async (): Promise<any | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(`${endpoint}/UnsentEmails`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setUnsentEmails(data);
      }

      return data;
    } catch (error) {
      return null;
    }
  };

  getSend = async (emailID: number): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(
        `${endpoint}/SendEmail?EmailID=${emailID}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Письмо отправлено");
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  addEmail = async (
    header: string,
    content: string,
    isHtmlContent: boolean,
    importance: boolean
  ) => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        header,
        content,
        isHtmlContent,
        importance,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(`${endpoint}/AddEmail`, requestOptions);

      if (response.ok) {
        toast.success("Письмо добавлено!");
      } else {
        toast.error("Произошла ошибка");
      }
    } catch (error) {
      toast.error("Произошла ошибка");
    }
  };

  updateEmail = async (
    id: number,
    header: string,
    content: string,
    isHtmlContent: boolean,
    importance: boolean
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(
        `${endpoint}/EditEmail?MppEmailID=${id}&Header=${header}&Content=${content}&IsHtmlContent=${isHtmlContent}&Importance=${importance}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Письмо отредактировано!");
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  deleteEmail = async (emailID: number): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "DELETE",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(
        `${endpoint}/DeleteEmail?MppEmailID=${emailID}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Письмо удалено!");
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  deleteSubscribtion = async (groupId: number): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "DELETE",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(
        `${endpoint}/DeleteSubscribtion?GroupID=${groupId}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Подписка удалена!");
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  getUserSubscription = async (userID: string): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(
        `${endpoint}/GetUserSubscription?UserID=${userID}`,
        requestOptions
      );
      const data = await response.text();
      if (response.ok) {
        return data === "true" ? true : false;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  updateUserSubscription = async (bool: boolean): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_EMAIL");
      const response = await fetch(
        `${endpoint}/EmailSubscribe?Subscribe=${bool}`,
        requestOptions
      );
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
}

export default EmailStore;
