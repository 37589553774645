import styles from "./Home.module.scss";
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import Product from "../components/Product";
import BeatLoader from "react-spinners/BeatLoader";
import ToggleSwitch from "../../../components/ToggleSwitch";
import Modal from "components/Modal";
import ModalChat from "./ModalChat/ModalChat";
import PriceChangesPopup from "./PriceChangesPopup";
import moment from "moment";
import "moment/locale/ru";
import { Cart } from "stores/OrderStore";
import OrderTable from "./OrderTable";
moment.locale("ru");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);

interface Revenue {
  item1: number;
  item2: number;
}

interface RevenueByWarehouse {
  item1: number;
  item2: number;
  item3: string;
}

interface SalesDynamics {
  item1: string;
  item2: number;
}

interface PriceChanges {
  item1: string;
  item2: string;
  item3: number;
  item4: string;
}
export interface QuestionsChat {
  id: number;
  productID: string;
  question: string;
  lenght?: number | string;
}

const Home = observer(() => {
  const {
    warehouseStore: { newProducts, getNewProducts, getProductChatQA },
    orderStore: {
      getOrdersRevenue,
      getSalesDynamics,
      getTodayPriceChanges,
      getSalesDynamicsByWarehouse,
      getRequestedOrders,
      requestedOrders,
    },
    userStore: { info, userInfo },
  } = useContext(RootStoreContext);

  const navigate = useNavigate();

  const [salesDynamicsByWarehouse, setSalesDynamicsByWarehouse] =
    useState<boolean>(false);

  const [loadingSalesDynamics, setLoadingSalesDynamics] =
    useState<boolean>(false);
  const [todayPriceChanges, setTodayPriceChanges] =
    useState<PriceChanges[][]>();
  const [priceChanges, setPriceChanges] = useState<PriceChanges[]>();

  const [ozonRevenue, setOzonRevenue] = useState<Revenue>();
  const [yandexRevenue, setYandexRevenue] = useState<Revenue>();
  const [wildberriesRevenue, setWildberriesRevenue] = useState<Revenue>();

  const [ozonRevenueByWarehouse, setOzonRevenueByWarehouse] =
    useState<RevenueByWarehouse[]>();
  const [yandexRevenueByWarehouse, setYandexRevenueByWarehouse] =
    useState<RevenueByWarehouse[]>();
  const [wildberriesRevenueByWarehouse, setWildberriesRevenueByWarehouse] =
    useState<RevenueByWarehouse[]>();

  const [ozonSalesDynamics, setOzonSalesDynamics] = useState<SalesDynamics[]>();
  const [yandexSalesDynamics, setYandexSalesDynamics] =
    useState<SalesDynamics[]>();
  const [wildberriesSalesDynamics, setWildberriesSalesDynamics] =
    useState<SalesDynamics[]>();

  const [loading, setLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<QuestionsChat[]>([]);

  const [orders, setOrders] = useState<Cart[]>([]);

  useEffect(() => {
    getProductChatQA().then((data) => {
      setQuestions(data);
    });
  }, [getProductChatQA]);

  useEffect(() => {
    if (info && userInfo) {
      getTodayPriceChanges(info?.id).then((i) => {
        setTodayPriceChanges(i);
      });
      getOrdersRevenue(info?.id, 1, salesDynamicsByWarehouse).then((i) => {
        if (salesDynamicsByWarehouse) {
          setOzonRevenueByWarehouse(i);
        } else {
          setOzonRevenue(i);
        }
      });
      getOrdersRevenue(info?.id, 2, salesDynamicsByWarehouse).then((i) => {
        if (salesDynamicsByWarehouse) {
          setYandexRevenueByWarehouse(i);
        } else {
          setYandexRevenue(i);
        }
      });
      getOrdersRevenue(info?.id, 3, salesDynamicsByWarehouse).then((i) => {
        if (salesDynamicsByWarehouse) {
          setWildberriesRevenueByWarehouse(i);
        } else {
          setWildberriesRevenue(i);
        }
      });
      if (salesDynamicsByWarehouse) {
        setLoadingSalesDynamics(true);
        getSalesDynamicsByWarehouse(info?.id, userInfo?.mpWarehouseID, 1).then(
          (i) => {
            setOzonSalesDynamics(i);
            setLoadingSalesDynamics(false);
          }
        );
        getSalesDynamicsByWarehouse(info?.id, userInfo?.mpWarehouseID, 2).then(
          (i) => {
            setYandexSalesDynamics(i);
            setLoadingSalesDynamics(false);
          }
        );
        getSalesDynamicsByWarehouse(info?.id, userInfo?.mpWarehouseID, 3).then(
          (i) => {
            setWildberriesSalesDynamics(i);
            setLoadingSalesDynamics(false);
          }
        );
      } else {
        setLoadingSalesDynamics(true);
        getSalesDynamics(info?.id, 1).then((i) => {
          setOzonSalesDynamics(i);
          setLoadingSalesDynamics(false);
        });
        getSalesDynamics(info?.id, 2).then((i) => {
          setYandexSalesDynamics(i);
          setLoadingSalesDynamics(false);
        });
        getSalesDynamics(info?.id, 3).then((i) => {
          setWildberriesSalesDynamics(i);
          setLoadingSalesDynamics(false);
        });
      }
      if (info.roles.includes("WarehouseOwner")) {
        getRequestedOrders(userInfo.mpWarehouseID);
      }
    }
  }, [
    getOrdersRevenue,
    getSalesDynamics,
    getTodayPriceChanges,
    getSalesDynamicsByWarehouse,
    info,
    salesDynamicsByWarehouse,
    userInfo,
    getRequestedOrders,
  ]);

  useEffect(() => {
    if (newProducts === null) {
      setLoading(true);
      getNewProducts().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [getNewProducts, newProducts]);

  const labels = ozonSalesDynamics?.map((i) => i.item1.split("T")[0]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "Динамика продаж в рублях",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Ozon",
        data: ozonSalesDynamics?.map((i) => i.item2),
        backgroundColor: "rgba(0, 91, 255, 0.6)",
        borderColor: "rgba(0, 91, 255, 0.6)",
      },
      {
        label: "Yandex",
        data: yandexSalesDynamics?.map((i) => i.item2),
        backgroundColor: "rgba(245, 188, 0, 0.6)",
        borderColor: "rgba(245, 188, 0, 0.6)",
      },
      {
        label: "Wildberries",
        data: wildberriesSalesDynamics?.map((i) => i.item2),
        backgroundColor: "rgba(203, 17, 171, 0.6)",
        borderColor: "rgba(203, 17, 171, 0.6)",
      },
    ],
  };
  const revenueBlocks = [
    {
      name: "Ozon",
      item1: ozonRevenue?.item1,
      item2: ozonRevenue?.item2,
      color: "#005bff",
    },
    {
      name: "Yandex",
      item1: yandexRevenue?.item1,
      item2: yandexRevenue?.item2,
      color: "#f5bc00",
    },
    {
      name: "Wildberries",
      item1: wildberriesRevenue?.item1,
      item2: wildberriesRevenue?.item2,
      color: "#cb11ab",
    },
  ];

  const revenueBlocksByWarehouse = [
    {
      name: "Ozon",
      amount: ozonRevenueByWarehouse,
      color: "#005bff",
    },
    {
      name: "Yandex",
      amount: yandexRevenueByWarehouse,
      color: "#f5bc00",
    },
    {
      name: "Wildberries",
      amount: wildberriesRevenueByWarehouse,
      color: "#cb11ab",
    },
  ];
  const [openChat, setOpenChat] = useState<boolean>(false);
  const handlerClick = () => {
    setOpenChat(true);
  };

  return (
    <Row className={styles.root}>
      <p
        className={styles.newLink}
        onClick={() => {
          navigate(`/new`);
        }}
      >
        Новинки
      </p>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <Row>
          {loading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={loading} size={50} />
            </div>
          ) : newProducts ? (
            newProducts.slice(0, 3).map((item, index) => (
              <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index + item.id}>
                <Product data={item} />
              </Col>
            ))
          ) : (
            ""
          )}
        </Row>
      </Col>
      <Col lg={9} className={styles.content}>
        <div className={styles.banner}>
          <Row className={styles.revenueChart}>
            {info?.roles.includes("WarehouseOwner") ? (
              <div className={styles.salesToggleDiv}>
                <div>
                  <p className={styles.revenueTitle}>
                    Динамика продаж (
                    {salesDynamicsByWarehouse ? "Суммарная" : "Собственная"})
                  </p>
                </div>
                <div>
                  <ToggleSwitch
                    checked={salesDynamicsByWarehouse}
                    cursor={loadingSalesDynamics ? "progress" : "pointer"}
                    onChange={() =>
                      setSalesDynamicsByWarehouse(!salesDynamicsByWarehouse)
                    }
                    name="import"
                    leftText="Отключить"
                    rightText="Подключить"
                    icons={false}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <Line options={options} data={data} />
          </Row>
          <Row className={styles.revenueBlocks}>
            <p className={styles.revenueTitle}>Выручка и заказы с 1-го числа</p>
            {salesDynamicsByWarehouse
              ? revenueBlocksByWarehouse.map(({ name, amount, color }) => {
                  return (
                    <Col key={name} lg={4} className={styles.revenueBlockCol}>
                      {amount?.map((i) => (
                        <div
                          key={i.item3}
                          className={styles.revenueBlock}
                          style={{
                            borderColor: color,
                            boxShadow: `rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px`,
                            margin: "0 0 20px 0",
                          }}
                        >
                          <p
                            style={{ color: color }}
                            className={styles.revenueBlockName}
                          >
                            {name}
                          </p>
                          <div className={styles.revenueBlockValue}>
                            <p>Склад: {i.item3}</p>
                            <p>
                              Выручка:{" "}
                              {i.item2
                                ? parseFloat(Number(i.item2).toFixed(2)) + " ₽"
                                : ""}
                            </p>
                            <p>Заказы: {i.item1}</p>
                          </div>
                        </div>
                      ))}
                    </Col>
                  );
                })
              : revenueBlocks.map(({ name, item1, item2, color }) => {
                  return (
                    <Col key={name} lg={4} className={styles.revenueBlockCol}>
                      <div
                        className={styles.revenueBlock}
                        style={{
                          borderColor: color,
                          boxShadow: `rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px`,
                        }}
                      >
                        <p
                          style={{ color: color }}
                          className={styles.revenueBlockName}
                        >
                          {name}
                        </p>
                        <div className={styles.revenueBlockValue}>
                          <p>
                            Выручка:{" "}
                            {item2
                              ? parseFloat(Number(item2).toFixed(2)) + " ₽"
                              : ""}
                          </p>
                          <p>Заказы: {item1}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
          </Row>
        </div>
      </Col>
      <Col lg={3}>
        <div className={styles.messagesTitle}>
          <p>Сообщения</p>
          {todayPriceChanges?.length !== 0 ? (
            <p className={styles.priceChangeTitle}>
              Изменения цен
              {todayPriceChanges?.length !== 0 ? (
                <Badge className={styles.favoriteCountBadge}>
                  {todayPriceChanges?.length}
                </Badge>
              ) : (
                ""
              )}
            </p>
          ) : (
            ""
          )}
          {todayPriceChanges?.map((i, index) => {
            return (
              <div key={index}>
                <p
                  className={styles.priceChangeItems}
                  onClick={() => {
                    setPriceChanges(i);
                  }}
                >
                  <span>{moment(i[0].item4).format("LL")}</span>
                </p>
              </div>
            );
          })}
          {info?.roles.includes("WarehouseOwner") ? (
            <div className={styles.messagesTitle}>
              <p onClick={handlerClick}>
                {questions && questions.length !== 0 ? (
                  <p className={styles.priceChangeTitle}>У вас новые вопросы</p>
                ) : (
                  ""
                )}
                {questions && questions.length !== 0 ? (
                  <Badge className={styles.favoriteCountBadge}>
                    {questions?.length}
                  </Badge>
                ) : (
                  ""
                )}
              </p>
              {openChat && questions.length !== 0 && (
                <Modal
                  isOpen={openChat}
                  onClose={() => {
                    setOpenChat(false);
                  }}
                  children={
                    <ModalChat
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                  }
                />
              )}

              {requestedOrders ? (
                <p onClick={() => setOrders(requestedOrders)}>
                  {requestedOrders?.length !== 0 ? (
                    <p className={styles.priceChangeTitle}>
                      Возвраты
                      {requestedOrders?.length !== 0 ? (
                        <Badge className={styles.favoriteCountBadge}>
                          {requestedOrders?.length}
                        </Badge>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
      {priceChanges?.length !== undefined && priceChanges.length > 0 && (
        <Modal
          className={styles.cartModal}
          children={<PriceChangesPopup changes={priceChanges} />}
          isOpen={!!priceChanges.length}
          onClose={() => {
            setPriceChanges([]);
          }}
        />
      )}
      {orders?.length !== undefined && orders.length > 0 && (
        <Modal
          className={styles.cartModal}
          children={<OrderTable />}
          isOpen={!!orders.length}
          onClose={() => {
            setOrders([]);
          }}
        />
      )}
    </Row>
  );
});
export default Home;
