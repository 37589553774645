import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { useFormik } from "formik";
import { ReactComponent as Wallpaper } from "assets/icons/login-wallpaper.svg";
import { Spinner } from "react-bootstrap";
import CustomInput from "components/CustomInput";
import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import LogoLight from "../../Home/HomePageImages/LogoLight.png";
import * as yup from "yup";

import styles from "./Login.module.scss";
import Modal from "../../../components/Modal";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Вы ввели некорректный Email")
    .required("Поле обязательно для заполнения"),
  password: yup.string().required("Поле обязательно для заполнения"),
});

const Login: React.FC = observer(() => {
  const {
    loginStore: { login },
  } = useContext(RootStoreContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [loadingTwoFactor, setLoadingTwoFactor] = useState<boolean>(false);

  const [twoFactorEnabled, setTwoFactorEnabled] = useState<boolean>(false);

  const [twoFactorEnabledCode, setTwoFactorEnabledCode] = useState<string>("");

  const navigate = useNavigate();
  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async () => {
      setSubmitting(true);
      setLoading(true);
      login(values.email, values.password).then(() => setLoading(false));
    },
  });

  useEffect(() => {
    const keyPressEvent = (e: any) => {
      if (e.keyCode === 13) {
        if (values.email && values.password) {
          setLoading(true);
          handleSubmit();
        }
      }
    };
    window.addEventListener("keydown", keyPressEvent);
    return () => {
      window.removeEventListener("keydown", keyPressEvent);
    };
  }, [handleSubmit, values]);
  return (
    <div className={styles.root}>
      <Wallpaper className={styles.wallpaper} />
      <div className={styles.content}>
        <div className={styles.window}>
          <div className={styles.welcomeTitle}>
            <Link to="/">
              <img src={LogoLight} alt="Logo" className={styles.Logo} />
            </Link>
            <p className={styles.title}>Пожалуйста, авторизуйтесь!</p>
          </div>
          <CustomInput
            value={values.email}
            error={touched.email ? errors.email : ""}
            placeholder="E-mail"
            name="email"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            clearable={() => {
              setFieldValue("email", "");
            }}
          />
          <CustomInput
            value={values.password}
            error={touched.password ? errors.password : ""}
            placeholder="Пароль"
            name="password"
            onBlur={handleBlur}
            onChange={(e) => handleChange(e)}
            type="password"
          />
          <Button
            disabled={loading}
            text={loading ? "" : "Войти"}
            children={
              loading ? <Spinner animation="grow" variant="light" /> : ""
            }
            fullWidth
            onClick={() => {
              handleSubmit();
            }}
            color="primary"
            className={styles.login}
          />
          <Button
            text="Регистрация"
            fullWidth
            onClick={() => navigate("/register")}
            color="secondary"
            className={styles.registerButton}
          />
          {/* <div className={styles.or}>или</div>
          <div className={styles.socials}>
            <div className={styles.social}>
              <Google />
            </div>
            <div className={styles.social}>
              <Facebook />
            </div>
            <div className={styles.social}>
              <LinkedIn />
            </div>
          </div> */}
          <Link to={"/forgot"} className={styles.forgot}>
            Забыли логин или пароль?
          </Link>
        </div>
        <div className={styles.options}>
          <Link to="/">На главную</Link>
          {/* <Link to="/">Помощь</Link>
          <div className={styles.divider} />
          <Link to="/">Политика конфиденциальности</Link> */}
        </div>
      </div>
      {twoFactorEnabled && (
        <Modal
          className={styles.cartModal}
          children={
            <div>
              <CustomInput
                value={twoFactorEnabledCode}
                placeholder="Код подтверждения"
                name="twoFactorEnabledCode"
                onChange={(e) => setTwoFactorEnabledCode(e.target.value)}
                type="text"
              />
              <Button
                disabled={loadingTwoFactor}
                text={loadingTwoFactor ? "" : "Войти"}
                children={
                  loadingTwoFactor ? (
                    <Spinner animation="grow" variant="light" />
                  ) : (
                    ""
                  )
                }
                fullWidth
                onClick={() => {
                  handleSubmit();
                }}
                color="primary"
                className={styles.login}
              />
            </div>
          }
          isOpen={twoFactorEnabled}
          onClose={() => {
            setTwoFactorEnabled(false);
          }}
        />
      )}
    </div>
  );
});
export default Login;
