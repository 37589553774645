import { FC, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Container } from "react-bootstrap";
import * as DOMPurify from "dompurify";
import styles from "./TermsOfCondition.module.scss";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RootStoreContext } from "stores/RootStore";

//const FontAttributor = Quill.import("attributors/class/font");
//FontAttributor.whitelist = [
//  "IRANSans",
//  "roboto",
//  "cursive",
//  "fantasy",
//  "monospace",
//];
//Quill.register(FontAttributor, true);

//import { convertDeltaToHtml } from "node-quill-converter";

const TermsOfCondition: FC = observer((): any => {
  const {
    userStore: { SaveTermsOfUsageAsFile, getTermsUsageAsString },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [value, setValue] = useState("");
  //  const html = convertDeltaToHtml(value);
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ size: [] }], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image", "video", "formula"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    [
      {
        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"],
        },
      },
    ],
    ["clean"], // remove formatting button
  ];

  const module = {
    toolbar: toolbarOptions,
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "align",
  ];

  useEffect(() => {
    getTermsUsageAsString().then((data) => {
      if (data) {
        if (data.includes("<img")) {
          const newData = data.replaceAll(/\\/g, "");
          setValue(newData.trim());
        } else {
          setValue(data.trim());
        }
      }
    });
  }, [getTermsUsageAsString]);
  function createMarkup(html: any): any {
    // const data = JSON.parse(html).item2;
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  return (
    <Container>
      {info?.roles.includes("SuperAdmin") ? (
        <ReactQuill
          modules={module}
          theme="snow"
          value={value}
          onChange={setValue}
          formats={formats}
        />
      ) : (
        ""
      )}

      {info?.roles.includes("SuperAdmin") ? (
        <button
          className={styles.btnSave}
          onClick={() => {
            SaveTermsOfUsageAsFile(value, "TermsOfUsage");
          }}
        >
          Сохранить
        </button>
      ) : (
        ""
      )}
      {info?.roles.includes("SuperAdmin") ? (
        ""
      ) : (
        <div
          className="ql-editor editorText"
          dangerouslySetInnerHTML={createMarkup(value)}
        ></div>
      )}
    </Container>
  );
});

export default TermsOfCondition;
