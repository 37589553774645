import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { useFormik } from "formik";
import { ReactComponent as Wallpaper } from "assets/icons/login-wallpaper.svg";
import CustomInput from "components/CustomInput";
import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import LogoLight from "../../Home/HomePageImages/LogoLight.png";
import * as yup from "yup";

import styles from "./Forgot.module.scss";

const forgotSchema = yup.object().shape({
  email: yup
    .string()
    .email("Вы ввели некорректный Email")
    .required("Поле обязательно для заполнения"),
});

const Forgot: React.FC = observer(() => {
  const {
    forgotStore: { forgot },
  } = useContext(RootStoreContext);

  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();
  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotSchema,
    onSubmit: async () => {
      setSubmitting(true);
      forgot(values.email).then(() => setSubmitted(true));
    },
  });

  return (
    <div className={styles.root}>
      <Wallpaper className={styles.wallpaper} />
      <div className={styles.content}>
        {!isSubmitted ? (
          <div className={styles.window}>
            <div className={styles.welcomeTitle}>
              <Link to="/">
                <img src={LogoLight} alt="Logo" className={styles.Logo} />
              </Link>
              <p className={styles.title}>Восстановление пароля</p>
            </div>
            <p className={styles.subtitle}>
              Укажите e-mail вашего аккаунта и мы вышлем инструкцию по
              восстановлению пароля
            </p>
            <CustomInput
              value={values.email}
              error={touched.email ? errors.email : ""}
              placeholder="E-mail"
              name="email"
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
              clearable={() => {
                setFieldValue("email", "");
              }}
            />
            <Button
              text="Далее"
              fullWidth
              onClick={() => handleSubmit()}
              color="primary"
              className={styles.back}
            />
            <Button
              text="Назад"
              fullWidth
              onClick={() => navigate("/login")}
              color="secondary"
            />
          </div>
        ) : (
          <div className={styles.window}>
            <div className={styles.welcomeTitle}>
              <Link to="/">
                <img src={LogoLight} alt="Logo" className={styles.Logo} />
              </Link>
              <p className={styles.title}>Восстановление пароля</p>
            </div>
            <p className={styles.subtitle}>
              Мы отправили инструкцию для восстановления пароля на адрес{" "}
              <span className={styles.link}>{values.email}</span>
            </p>
            <Button
              text="Назад"
              fullWidth
              onClick={() => navigate("/login")}
              color="secondary"
              className={styles.back}
            />
          </div>
        )}
        <div className={styles.options}>
          <Link to="/">На главную</Link>
          {/* <Link to="/">Помощь</Link>
          <div className={styles.divider} />
          <Link to="/">Политика конфиденциальности</Link> */}
        </div>
      </div>
    </div>
  );
});
export default Forgot;
