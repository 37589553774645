import React, { useRef, useState } from "react";
import classnames from "classnames";

import Icon from "components/Icon";
import useOutsideClick from "hooks/useOutsideClick";

import styles from "./CustomInput.module.scss";

export type Colors = "primary" | "secondary" | "transparent";

type Variant = "primary" | "secondary";

interface Props {
  variant?: Variant;
  type?: string;
  value?: string | number;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: (
    e: React.FormEvent<HTMLInputElement>,
    meta?: { action: string; name: string }
  ) => void;
  className?: string;
  onFocus?: () => void;
  error?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  noPadding?: boolean;
  noPaddingRoot?: boolean;
  max?: number;
  min?: number;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  numbersOnly?: boolean;
  isBorderLeft?: boolean;
  size?: "normal" | "small" | "extraSmall" | "micro" | "big";
  color?: Colors;
  handleSetInputRef?: (e: HTMLInputElement) => void;
  capitalize?: boolean;
  minWidth?: number;
  withoutSpaces?: boolean;
  noSpecialCharacters?: boolean;
  clearable?: () => void;
  label?: string;
  required?: boolean;
}
const CustomInput: React.FC<Props> = ({
  variant = "primary",
  type,
  value = "",
  name,
  placeholder,
  disabled,
  onBlur,
  onFocus,
  error,
  onChange,
  noPadding,
  noPaddingRoot,
  max,
  min,
  onKeyUp,
  onKeyPress,
  maxLength = 128,
  capitalize = false,
  isBorderLeft,
  size = "normal",
  color = "primary",
  className,
  autoComplete,
  handleSetInputRef,
  minWidth,
  withoutSpaces = false,
  noSpecialCharacters = false,
  clearable,
  label,
  required,
}) => {
  const [isActive, setActive] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const ref = useRef(null);

  const handleOnBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };
  const handleOnFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (withoutSpaces) e.target.value = e.target.value.trim();
    const reg = /[^\w\s'-]/;
    if (noSpecialCharacters) {
      e.target.value = e.target.value.replace(reg, "");
    }

    onChange(e);
  };

  const handleOnClick = () => {
    setActive(true);
  };
  useOutsideClick(ref, () => {
    setActive(false);
  });

  return (
    <>
      {!!label?.length && (
        <label htmlFor={name} className={styles.label}>
          {label}
          {required ? <span className={styles.requiredSpan}> *</span> : ""}
        </label>
      )}
      <div
        ref={ref}
        className={classnames(
          styles.root,
          className,
          styles[size],
          styles[`${variant}Variant`],
          {
            [styles[color]]: styles[color],
            [styles.activeInput]: isActive,
            [styles.disabled]: disabled,
            [styles.borderLeft]: isBorderLeft,
            [styles.noPaddingRoot]: noPaddingRoot,
            [styles.errorInput]: error,
          }
        )}
        style={minWidth ? { minWidth: `${minWidth}rem` } : {}}
      >
        {type === "search" && (
          <div className={styles.searchIcon}>
            <Icon variant="search" size="big" />
          </div>
        )}
        <input
          autoComplete={autoComplete || "nope"}
          className={classnames(styles.input, {
            [styles.noPadding]: noPadding,
            [styles.capitalize]: capitalize,
            [styles.passwordInput]: type === "password",
          })}
          id={name}
          type={type === "password" && isPasswordShown ? "text" : type}
          value={value}
          name={name}
          disabled={disabled}
          onClick={handleOnClick}
          onFocus={handleOnFocus}
          onBlur={(e) => {
            if (withoutSpaces) {
              e.target.value = e.target.value.trim();
            }

            handleOnBlur(e);
          }}
          onChange={handleOnChange}
          max={max}
          min={min}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          maxLength={maxLength}
          placeholder={placeholder}
          ref={
            handleSetInputRef
              ? (e: HTMLInputElement) => handleSetInputRef(e)
              : null
          }
        />
        {type !== "password" && clearable && value !== "" && (
          <Icon onClick={clearable} variant="cross" size="medium" />
        )}
        {type === "password" && (
          <div className={styles.visibility}>
            {isPasswordShown ? (
              <Icon
                onClick={() => setIsPasswordShown(!isPasswordShown)}
                variant="invisible-eye"
                size="big"
              />
            ) : (
              <Icon
                onClick={() => setIsPasswordShown(!isPasswordShown)}
                variant="visible"
                size="big"
              />
            )}
          </div>
        )}
        {error && <label className={styles.error}>{error}</label>}
      </div>
    </>
  );
};
export default CustomInput;
