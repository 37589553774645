import { useState } from "react";
import styles from "./AskAQuestion.module.scss";
import InputField from "../InputField/InputField";
interface Props {
  productId: string;
}
const AskAQuestion: React.FC<Props> = ({ productId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (): void => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <div className={styles.AskAQuestionContainer}>
      <div className={styles.askAQuestionBtn} onClick={handleClick}>
        Задать вопрос
      </div>
      <div>{isOpen && <InputField productId={productId} />}</div>
    </div>
  );
};

export default AskAQuestion;
