import { Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./UpdateInfo.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import CustomSelect, { SelectValue } from "components/CustomSelect";
import { components } from "react-select";
import { useSearchParams } from "react-router-dom";
import YandexLogo from "../../../assets/icons/yandex.png";
import OzonLogo from "../../../assets/icons/ozon.png";
import WildberriesLogo from "../../../assets/icons/wildberries.png";
import moment from "moment/moment";
import Icon from "components/Icon/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import classNames from "classnames";
import Pagination from "components/Pagination/Pagination";

const UpdateInfo: React.FC = observer(() => {
  const {
    warehouseStore: { getUpdateInfo, updateInfo },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>("");

  const newParams = new URLSearchParams(searchParams);

  const pageSize = searchParams.get("pageSize");
  const pageNumber = searchParams.get("pageNumber");
  const searchText = searchParams.get("search");

  const filters = [{ label: "search", value: searchText }];

  useEffect(() => {
    setLoading(true);
    getUpdateInfo({
      ...(pageSize && { pageSize: Number(pageSize) }),
      ...(pageNumber && { pageNumber: Number(pageNumber) }),
      ...(searchText && { searchText: searchText }),
      sortingOrder: 0,
    }).then(() => {
      setLoading(false);
    });
  }, [getUpdateInfo, pageNumber, pageSize, searchText]);

  const handlePages = (updatePage: number) => {
    newParams.set("pageNumber", `${updatePage}`);
    setSearchParams(newParams);
  };

  const handleSearchFilter = () => {
    newParams.set("search", searchValue);
    setSearchParams(newParams);
  };

  const handleFilters = (e: SelectValue, name: string) => {
    newParams.set(name, e.value);
    setSearchParams(newParams);
  };

  const MenuList: React.FC<any> = (props) => {
    const { children, ...otherProps } = props;
    return (
      <components.MenuList {...otherProps}>{children}</components.MenuList>
    );
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>Изменение остатков</p>
      <div className={styles.wrapper}>
        <div className={styles.search}>
          <CustomInput
            type="search"
            value={searchValue}
            placeholder="Поиск..."
            name={`search`}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (!e.target.value) {
                newParams.delete("search");
                setSearchParams(newParams);
              }
            }}
            onKeyPress={(event) =>
              event.key === "Enter" && handleSearchFilter()
            }
            className={styles.searchInput}
          />
        </div>
        <div className={styles.tags}>
          {filters.map(
            (item, index) =>
              item.value && (
                <div className={styles.tag} key={index}>
                  <div className={styles.label}>{item.value}</div>
                  <Icon
                    onClick={() => {
                      newParams.delete(item.label);
                      setSearchParams(newParams);
                    }}
                    variant="cross"
                    size="small"
                  />
                </div>
              )
          )}
          {filters.filter((i) => i.value).length ? (
            <a
              className={styles.reset}
              onClick={() => {
                setSearchValue("");
                setSearchParams({ pageNumber: "1", pageSize: "20" });
              }}
            >
              Сбросить все фильтры
            </a>
          ) : (
            ""
          )}
        </div>
        {loading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={loading} size={50} />
          </div>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Наименование товар</th>
                <th style={{ textAlign: "center" }}>МП</th>
                <th>Дата обновления</th>
                <th>Код поставщика</th>
                <th>Было</th>
                <th>Стало</th>
                <th>Дата обновления</th>
                <th>Источник</th>
                <th>Файл</th>
              </tr>
            </thead>
            <tbody>
              {updateInfo &&
                updateInfo.updateHistories.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={`/warehouse/product/${item.productID}`}
                        target="_blank"
                        className={styles.cardTitle}
                      >
                        {item.productTitle}
                      </a>
                    </td>
                    <td className={styles.MPLogos}>
                      {item.marketplaceName === "YandexMarket" ? (
                        <img src={YandexLogo} alt="ozon logo" />
                      ) : item.marketplaceName === "Ozon" ? (
                        <img src={OzonLogo} alt="ozon logo" />
                      ) : (
                        <img src={WildberriesLogo} alt="ozon logo" />
                      )}
                    </td>
                    <td>
                      {moment(item.updateDate).format("DD.MM.YYYY HH:mm")}
                    </td>
                    <td>{item.vendorCode}</td>
                    <td style={{ textWrap: "nowrap" }}>
                      {item.previousAmount ? item.previousAmount + "шт." : ""}
                    </td>
                    <td style={{ textWrap: "nowrap" }}>{item.amount} шт.</td>
                    <td>{moment(item.updateDate).format("DD.MM.YYYY")}</td>
                    <td>{item.updateSource}</td>
                    <td>{item.fileName}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        {updateInfo && updateInfo.pageCount > 1 && (
          <div className={classNames(styles.pagination)}>
            <CustomSelect
              components={{ MenuList }}
              className={styles.small}
              name="pageSize"
              placeholder="qty"
              onChange={(e) => {
                handleFilters(e, "pageSize");
              }}
              value={pageSize}
              list={[
                { value: "20", label: "20" },
                { value: "40", label: "40" },
                { value: "60", label: "60" },
              ]}
            />
            <Pagination
              page={updateInfo.pageNumber}
              totalPages={updateInfo.pageCount}
              handlePagination={handlePages}
            />
          </div>
        )}
      </div>
    </div>
  );
});
export default UpdateInfo;
