import classnames from "classnames";

import styles from "./Checkbox.module.scss";

export type CheckboxSize = "small" | "normal";

interface Classes {
  root?: string;
  container?: string;
  label?: string;
  input?: string;
}

interface Props {
  type?: "minus" | "tick";
  name?: string;
  id?: string;
  size?: CheckboxSize;
  checked: boolean;
  disabled?: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  label?: string | JSX.Element;
  className?: string;
  classes?: Classes;
}

const Checkbox: React.FC<Props> = ({
  checked,
  name,
  onChange,
  onKeyDown,
  id,
  size = "small",
  label,
  className,
  disabled,
  type = "tick",
  classes = {},
}) => {
  return (
    <div
      className={classnames(styles.root, className, classes.root, {
        [styles[type]]: type,
        [styles[size]]: styles[size],
      })}
    >
      <div className={classnames(styles.container, classes.container)}>
        <label htmlFor={id}>
          <input
            className={classnames(styles.input, classes.input)}
            id={id}
            name={name}
            checked={checked}
            type="checkbox"
            onChange={(e) => {
              if (!disabled) {
                onChange(e);
              }
            }}
            disabled={disabled}
            onKeyDown={onKeyDown}
          />
          <span
            className={classnames(styles.checkmark, {
              [styles.checked]: checked,
            })}
          />
        </label>
      </div>
      <span
        onClick={(e) => {
          if (!disabled) {
            onChange(e);
          }
        }}
        className={classnames(styles.label, classes.label, {
          [styles.active]: checked,
        })}
      >
        {label}
      </span>
    </div>
  );
};

export default Checkbox;
