import styles from "./bettingSection.module.scss";
import { Container } from "react-bootstrap";
export const BettingSection = () => {
  return (
    <Container className={styles.bettingSection}>
      <h1>ТАРИФЫ</h1>
      <p>Стоимость пользования платформой</p>
      <p className={styles.bettingSectionText}>Первые 3 месяца бесплатно</p>
      <div className={styles.percentContainer}>
        <div className={styles.percentContainerIntem}>
          <p className={styles.percentContainerIntemNumber}>
            5 <span className={styles.percent}>%</span>
          </p>

          <p>При обороте (руб./месяц) до 1 000 000</p>
        </div>
        <div className={styles.percentContainerIntem}>
          <p className={styles.percentContainerIntemNumber}>
            4 <span className={styles.percent}>%</span>
          </p>
          <p>При обороте (руб./месяц) 1 000 000 до 2 000 000 </p>
        </div>
        <div className={styles.percentContainerIntem}>
          <p className={styles.percentContainerIntemNumber}>
            3 <span className={styles.percent}>%</span>
          </p>
          <p>При обороте (руб./месяц) свыше 2 000 000</p>
        </div>
      </div>
    </Container>
  );
};
