import React from "react";
import styles from "./SellerSection.module.scss";
import { Col, Row } from "react-bootstrap";
import Seller1 from "../HomePageImages/seller1.png";
import Seller2 from "../HomePageImages/seller2.png";

interface Props {
  sellerRef: any;
}

const youContent = [
  "Добавляете товары из платформы в свои магазины на маркетплейсах",
  "Устанавливаете свою цену",
  "Совершаете продажи",
];

const weContent = [
  "Пополняем ассортимент",
  "Готовим фотографии и описание",
  "Принимаем заказы из ваших магазинов на маркетплейсах",
  "Упаковываем товар",
  "Доставляем заказ на склад от вашего имени",
  "Обновляем остатки на маркетплейсах",
  "Забираем возвраты от вашего имени",
];

const SellerSection: React.FC<Props> = ({ sellerRef }) => {
  return (
    <div>
      <Row className={styles.YouContainer} ref={sellerRef}>
        <Col xl={6} lg={5} md={12} sm={12} className={styles.ImageContainer}>
          <img src={Seller1} alt="groupOne" />
        </Col>
        <Col xl={6} lg={7} md={12} sm={12} className={styles.NumberedDescribe}>
          <div className={styles.SellerContent}>
            <h1>ВЫ</h1>
            {youContent.map((text, index) => {
              return (
                <p key={index}>
                  <span>{index + 1}</span>
                  {text}
                </p>
              );
            })}
          </div>
        </Col>
      </Row>
      <Row className={styles.WeContainer}>
        <Col xl={6} lg={5} md={12} sm={12} className={styles.ImageContainer}>
          <img src={Seller2} alt="groupOne" />
        </Col>
        <Col xl={6} lg={7} md={12} sm={12} className={styles.NumberedDescribe}>
          <div className={styles.SellerContent}>
            <h1>МЫ</h1>
            {weContent.map((text, index) => {
              return (
                <p key={index}>
                  <span>{index + 1}</span>
                  {text}
                </p>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SellerSection;
