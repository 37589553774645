export const DiagramOne = () => {
  return (
    <svg
      width="169"
      height="149"
      viewBox="0 0 169 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M136.574 39.4261C126.948 17.3958 104.966 2 79.3874 2C47.0431 2 20.4484 26.6181 17.3188 58.1391"
        stroke="#FFB801"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M115.802 35.3305L134.748 40.2855C136.175 40.6587 137.634 39.8045 138.007 38.3777L142.962 19.432"
        stroke="#FFB801"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0776 60.6142C15.5902 84.6094 28.1256 108.34 50.8861 120.011C79.6668 134.77 114.565 125 131.733 98.3795"
        stroke="#FFB801"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M111.274 76.5155V48.1931C111.272 46.9514 110.945 45.7319 110.323 44.6569C109.702 43.5819 108.809 42.6892 107.733 42.0683L82.9512 27.9071C81.8748 27.2857 80.6538 26.9585 79.4109 26.9585C78.1679 26.9585 76.9469 27.2857 75.8705 27.9071L51.0884 42.0683C50.0131 42.6892 49.1199 43.5819 48.4985 44.6569C47.8771 45.7319 47.5494 46.9514 47.5481 48.1931V76.5155C47.5494 77.7572 47.8771 78.9767 48.4985 80.0517C49.1199 81.1267 50.0131 82.0194 51.0884 82.6403L75.8705 96.8015C76.9469 97.4229 78.1679 97.7501 79.4109 97.7501C80.6538 97.7501 81.8748 97.4229 82.9512 96.8015L107.733 82.6403C108.809 82.0194 109.702 81.1267 110.323 80.0517C110.945 78.9767 111.272 77.7572 111.274 76.5155Z"
        stroke="#21A235"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.5042 44.5112L79.411 62.3898L110.318 44.5112"
        stroke="#21A235"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M79.4109 98.0408V62.3545"
        stroke="#21A235"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M166.9 52.2388L140.079 80.5612L127.941 67.8289"
        stroke="#21A235"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
