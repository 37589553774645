import React, { useContext, useEffect, useState } from "react";
import styles from "./NewProducts.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { Col, Row } from "react-bootstrap";
import Product from "../components/Product";
import BeatLoader from "react-spinners/BeatLoader";

const NewProducts = observer(() => {
  const {
    warehouseStore: { newProducts, getNewProducts },
  } = useContext(RootStoreContext);

  const viewType = 1;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getNewProducts().then(() => {
      setLoading(false);
    });
  }, [getNewProducts]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Новинки</p>
      <div className={styles.wrapper}>
        <Row>
          {loading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={loading} size={50} />
            </div>
          ) : newProducts ? (
            newProducts.map((item, index) => (
              <Col xl={3} lg={4} md={6} sm={6} xs={12} key={index + item.id}>
                <Product data={item} list={viewType === 1} />
              </Col>
            ))
          ) : (
            ""
          )}
        </Row>
      </div>
    </div>
  );
});

export default NewProducts;
