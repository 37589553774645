import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import handleInternalServerError from "utils/handleInternalServerError";
import { RootStore } from "./RootStore";

interface CreateSessionToken {
  token: string;
}

interface CreateSessionError {
  error_id: string;
  error_message: string;
  error_fields: Array<{
    code: string;
    name: string;
    message: string;
  }>;
  error_code: string;
  status_code?: number;
}

class ForgotStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  errorData: CreateSessionError | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setErrorData = (error: CreateSessionError | null): void => {
    this.errorData = error;
  };

  forgot = async (
    email: string
  ): Promise<CreateSessionToken | CreateSessionError | null> => {
    const { userStore, toastStore, navigationStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({}),
      body: JSON.stringify({
        email,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FORGOT");
      const response = await fetch(endpoint, requestOptions);
      const data = await response.json();

      if (response.ok) {
        userStore.setToken(data.token);
        toastStore.notify.success("Logged in successfully");
        await navigationStore.navigateTo("/home");
      } else if (!response.ok && [400, 401].indexOf(data.status_code) !== -1) {
        this.setErrorData(data);
        toastStore.notify.error(data.error_message);
      }
      if (!response.ok && !data.status_code) {
        handleInternalServerError(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  reset = async (
    email: string,
    password: string,
    confirmPassword: string,
    code: string
  ): Promise<CreateSessionToken | CreateSessionError | null> => {
    const { userStore, toastStore, navigationStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({}),
      body: JSON.stringify({
        email,
        password,
        confirmPassword,
        code,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_RESET_PASSWORD");
      const response = await fetch(endpoint, requestOptions);
      const data = await response.json();

      if (response.ok) {
        userStore.setToken(data.token);
        toastStore.notify.success("Reset successfully");
        await navigationStore.navigateTo("/home");
      }
      if (!response.ok && [400, 401].indexOf(data.status_code) !== -1) {
        this.setErrorData(data);
        toastStore.notify.error(data.error_message);
      }
      if (!response.ok && !data.status_code) {
        handleInternalServerError(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };
}

export default ForgotStore;
