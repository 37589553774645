import { Table } from "react-bootstrap";
import React from "react";
import styles from "./Home.module.scss";

interface Props {
  changes: { item1: string; item2: string; item3: number; item4: string }[];
}

const PriceChangesPopup: React.FC<Props> = ({ changes }) => {
  return (
    <div className={styles.PriceChangePopup}>
      <Table striped hover className={styles.PriceChangeTable}>
        <tbody>
          {changes.map((i, index) => (
            <tr key={index + 1}>
              <td>
                <a
                  href={`/warehouse/product/${i.item1}`}
                  target="_blank"
                  className={styles.cardTitle}
                >
                  {i.item2}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
export default PriceChangesPopup;
