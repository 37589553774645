import styes from "./salesGrowth.module.scss";
import { Diagram } from "./diagram";
import { Container } from "react-bootstrap";
export const SalesGrowth = () => {
  return (
    <Container className={styes.salesGrowth}>
      <h1>РОСТ ПРОДАЖ SKU ПОСТАВЩИКОВ ПРИ РАБОТЕ ЧЕРЕЗ RS</h1>
      <div>
        <p>
          RoomSeller обеспечивает рост продаж также за счет увеличения
          ассортимента. Продажи SKU на маркетплейсах за 8 месяцев 2023 года.
          Количество проданных SKU выросло на 22%
        </p>
        <Diagram />
      </div>
    </Container>
  );
};
