import React from "react";
import styles from "./ReliablySection.module.scss";
import { Col, Row } from "react-bootstrap";
import Icon1 from "../HomePageImages/icon1New.png";
import Icon2 from "../HomePageImages/icon2New.png";
import Icon3 from "../HomePageImages/icon3New.png";
import Icon4 from "../HomePageImages/icon4New.png";
import Icon5 from "../HomePageImages/icon5New.png";
import Icon6 from "../HomePageImages/icon6New.png";
import Icon7 from "../HomePageImages/icon7New.png";
import Icon8 from "../HomePageImages/icon8New.png";

interface Props {
  reliablyRef: any;
}

const ReliablySection: React.FC<Props> = ({ reliablyRef }) => {
  const items = [
    {
      icon: Icon1,
      text: "Единый личный кабинет для всех маркетплейсов",
    },
    {
      icon: Icon2,
      text: "Можно работать из любой точки мира",
    },
    {
      icon: Icon3,
      text: "Более 5000 товаров, постоянное увеличение ассортимента",
    },
    {
      icon: Icon4,
      text: "Сборка и упаковка заказов",
    },
    {
      icon: Icon5,
      text: "Ежедневная доставка до маркетплейсов",
    },
    { icon: Icon6, text: "Автоматический обмен остатками" },
    { icon: Icon7, text: "Автоматическая передача заказов" },
    { icon: Icon8, text: "Контроль качества товаров" },
  ];
  return (
    <div className={styles.ReliablySection} ref={reliablyRef}>
      <div>
        <h1>ПРЕИМУЩЕСТВА</h1>
        <Row className={styles.ReliablySectionContent}>
          {items.map(({ icon, text }, index) => {
            return (
              <Col
                key={index}
                className={styles.ReliablyLine}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div>
                  <div>
                    <img src={icon} alt={text} />
                  </div>
                </div>
                <div>
                  <p>{text}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default ReliablySection;
