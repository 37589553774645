import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ProductItem.module.scss";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import {
  Accordion,
  Badge,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { GalleriesModel } from "components/Gallery/Gallery";
import GalleryDetail from "components/GalleryDetail";
import { BsFiles } from "react-icons/bs";
import toast from "react-hot-toast";
import OzonLogo from "assets/icons/ozon.png";
import WildberriesLogo from "assets/icons/wildberries.png";
import YandexLogo from "assets/icons/yandex.png";
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from "react-icons/md";
import { AiOutlineSync } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
import moment from "moment";
import CustomInput from "../../../components/CustomInput";
import {
  Product,
  ProductCharacteristicI,
} from "../../../stores/WarehouseStore";
import BeatLoader from "react-spinners/BeatLoader";
import Modal from "../../../components/Modal";
import AddProductToCollection from "./AddProductToCollection";
import { CiEdit } from "react-icons/ci";
import EditCollectionDesc from "./EditCollectionDesc";
import CommentSection from "./CommentSection/CommentSection";

interface Props {
  product: Product;
}

const ProductItemTables: React.FC<Props> = observer(({ product }) => {
  const {
    warehouseStore: {
      getProductCharacteristics,
      productCharacteristics,
      setProductCharacteristics,
      updateProductFavorite,
      syncProduct,
      getFavoriteCount,
      getChildrenCategoryIDs,
      addImage,
    },
    ozonStore: { GetOzonProductOffer, setOzonProductNewPrice },
    yandexStore: { GetYandexProductPrice, setYandexProductNewPrice },
    wildberriesStore: {
      setWildberriesProductNewPrice,
      GetWildberriesProductPrice,
    },
    userStore: { info },
    collectionsStore: { getCollectionProducts, collectionProducts },
    marketPlacesStore: { getIsMPBanned, userWarehouses },
  } = useContext(RootStoreContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [syncLoad, setSyncLoad] = useState<boolean>(false);
  const [like, setLike] = useState<boolean>(false);
  const [changePriceLoading, setChangePriceLoading] = useState<boolean>(false);

  const [parentIds, setParentIds] = useState<string[]>();
  const [childrenIds, setChildrenIds] = useState<string[]>();

  const [oldOzonPrice, setOldOzonPrice] = useState<string>("");
  const [oldYandexPrice, setOldYandexPrice] = useState<string>("");
  const [oldWildberriesPrice, setOldWildberriesPrice] = useState<string>("");

  const [ozonPrice, setOzonPrice] = useState<string>("");
  const [yandexPrice, setYandexPrice] = useState<string>("");
  const [wildberriesPrice, setWildberriesPrice] = useState<string>("");

  const [ozonPriceLoad, setOzonPriceLoad] = useState<boolean>(true);
  const [yandexPriceLoad, setYandexPriceLoad] = useState<boolean>(true);
  const [wildberriesPriceLoad, setWildberriesPriceLoad] =
    useState<boolean>(true);

  const [ozonIsBanned, setOzonIsBanned] = useState<boolean>(false);
  const [yandexIsBanned, setYandexIsBanned] = useState<boolean>(false);
  const [wildberriesIsBanned, setWildberriesIsBanned] =
    useState<boolean>(false);

  const [openAddCollection, setOpenAddCollection] = useState<boolean>(false);
  const [openEditDescription, setOpenEditDescription] =
    useState<boolean>(false);

  const [collectionProdsChar, setCollectionProdsChar] = useState<
    {
      name: string;
      count: number;
      characteristics: ProductCharacteristicI[];
    }[]
  >([]);

  const [collectionProdsCharLoad, setCollectionProdsCharLoad] =
    useState<boolean>(false);

  useEffect(() => {
    getIsMPBanned(product.mpWarehouseID, 1).then((i) => {
      setOzonIsBanned(i);
    });
    getIsMPBanned(product.mpWarehouseID, 2).then((i) => {
      setYandexIsBanned(i);
    });
    getIsMPBanned(product.mpWarehouseID, 3).then((i) => {
      setWildberriesIsBanned(i);
    });
    setProductCharacteristics([]);
    if (product.isCollection) {
      getCollectionProducts(product.id).then((i) => {
        if (i) {
          setCollectionProdsCharLoad(true);
          i.forEach((item) => {
            getProductCharacteristics(item.item1, item.item3).then((chars) => {
              if (chars) {
                setCollectionProdsChar((state) => [
                  ...state,
                  {
                    name: item.item2,
                    count: item.item3,
                    characteristics: chars,
                  },
                ]);
              }
              setCollectionProdsCharLoad(false);
            });
          });
        }
      });
    } else {
      getProductCharacteristics(product.id, product.mpWarehouseID);
    }
  }, [
    product,
    getIsMPBanned,
    setOzonIsBanned,
    setYandexIsBanned,
    setWildberriesIsBanned,
    getProductCharacteristics,
    getCollectionProducts,
    setProductCharacteristics,
  ]);

  useEffect(() => {
    setLike(product.isFavorite);
    if (product.childParentID) {
      getChildrenCategoryIDs("", product.childParentID).then((r) => {
        if (r) {
          setChildrenIds(r);
        }
      });
    }
    if (product.parentGroupID) {
      getChildrenCategoryIDs(product.parentGroupID, "").then((r) => {
        if (r) {
          setParentIds(r);
        }
      });
    }

    if (info?.id) {
      setOzonPriceLoad(true);
      setYandexPriceLoad(true);
      setWildberriesPriceLoad(true);
      GetOzonProductOffer(info?.id, location.pathname.split("/")[3]).then(
        (i) => {
          if (i) {
            setOzonPrice(i.item1);
            setOldOzonPrice(i.item1);
          }
          setOzonPriceLoad(false);
        }
      );
      GetYandexProductPrice(info?.id, location.pathname.split("/")[3]).then(
        (i) => {
          if (i) {
            setYandexPrice(i.item1);
            setOldYandexPrice(i.item1);
          }
          setYandexPriceLoad(false);
        }
      );
      GetWildberriesProductPrice(
        info?.id,
        location.pathname.split("/")[3]
      ).then((i) => {
        if (i) {
          setWildberriesPrice(i.item1);
          setOldWildberriesPrice(i.item1);
        }
        setWildberriesPriceLoad(false);
      });
    }
  }, [
    info,
    navigate,
    product,
    location,
    getChildrenCategoryIDs,
    setParentIds,
    setChildrenIds,
    GetOzonProductOffer,
    GetYandexProductPrice,
    GetWildberriesProductPrice,
  ]);

  const copy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast.success("текст скопирован");
  };

  const buttons = [
    {
      image: OzonLogo,
      url: product?.ozonProductID
        ? `https://seller.ozon.ru/app/products/${product?.ozonProductID}/edit`
        : "https://seller.ozon.ru/app/products/add/general-info",
      text: product?.ozonProductID
        ? "Редактировать на Ozon"
        : "Отправить на Ozon",
      className: styles.addOzonProduct,
      disabled: ozonIsBanned,
    },
    {
      image: WildberriesLogo,
      url: product?.wildberriesProductID
        ? `https://seller.wildberries.ru/new-goods/card?nmID=${product.nmID}&type=EXIST_CARD`
        : "https://seller.wildberries.ru/new-goods/product-card",
      text: product?.wildberriesProductID
        ? "Редактировать на Wildberries"
        : "Отправить на Wildberries",
      className: styles.addWildberriesProduct,
      disabled: wildberriesIsBanned,
    },
    {
      image: YandexLogo,
      url: product?.yandexProductID
        ? `https://partner.market.yandex.ru/supplier/26078913/assortment/offer-card?offerId=${product?.yandexProductID}&source=businessAssortment&withCommonErrors=true`
        : "https://partner.market.yandex.ru/supplier/26078913/assortment/offer-card?source=businessAssortment",
      text: product?.yandexProductID
        ? "Редактировать на Yandex"
        : "Отправить на Yandex",
      className: styles.addYandexProduct,
      disabled: yandexIsBanned,
    },
  ];

  const changePriceInputs = [
    {
      name: "Yandex Market",
      onclick: () => {
        if (info) {
          setChangePriceLoading(true);
          setYandexProductNewPrice(
            info?.id,
            location.pathname.split("/")[3],
            yandexPrice
          ).then(() => {
            setChangePriceLoading(false);
          });
        }
      },
      price: yandexPrice,
      oldPrice: oldYandexPrice,
      isShow: oldYandexPrice && Number(oldYandexPrice) !== 0 ? true : false,
    },
    {
      name: "Ozon",
      onclick: () => {
        if (info) {
          setChangePriceLoading(true);
          setOzonProductNewPrice(
            info?.id,
            location.pathname.split("/")[3],
            ozonPrice
          ).then(() => {
            setChangePriceLoading(false);
          });
        }
      },
      price: ozonPrice,
      oldPrice: oldOzonPrice,
      isShow: oldOzonPrice && Number(oldOzonPrice) !== 0 ? true : false,
    },
    {
      name: "Wildberries",
      onclick: () => {
        if (info) {
          setChangePriceLoading(true);
          setWildberriesProductNewPrice(
            info?.id,
            location.pathname.split("/")[3],
            wildberriesPrice
          ).then(() => {
            setChangePriceLoading(false);
          });
        }
      },
      price: wildberriesPrice,
      oldPrice: oldWildberriesPrice,
      isShow:
        oldWildberriesPrice && Number(oldWildberriesPrice) !== 0 ? true : false,
    },
  ];
  const Gallery = () => {
    const sizes: any = { thumbnail: 150, product: 550, zoom: 850 };
    return product?.images.map((id, i) => {
      const o: any = {};
      for (const size in sizes) {
        o[size] = {
          url: id.imageUri,
          alt: `foto ${i + 1} - ${sizes[size]}px`,
          format: size,
        };
      }
      return o;
    }) as GalleriesModel[];
  };

  const likeClick = (id: string) => {
    updateProductFavorite(id).then((data) => {
      if (data) {
        if (!like) {
          toast.success("Товар добавлен в избранные");
        } else {
          toast.success("Товар удален из избранных");
        }
        getFavoriteCount();
        setLike(!like);
      }
    });
  };
  return (
    <Row className={styles.wrapper}>
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={styles.breadcrumb}
      >
        {product.isCollection ? (
          <a
            href={`/collections?mpWarehouseID=${product.mpWarehouseID}`}
            className={styles.warehouseLink}
          >
            Наборы
          </a>
        ) : (
          <div>
            <a
              href={`/warehouse?pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}
              className={styles.warehouseLink}
            >
              Склад
            </a>
            {parentIds && (
              <a
                href={`/warehouse?group=${product.parentGroupID}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}
                className={styles.categoryLink}
              >
                {" / " + product.parentGroup}
              </a>
            )}
            {childrenIds && (
              <a
                href={`/warehouse?group=${product.childParentID}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}
                className={styles.categoryLink}
              >
                {" / " + product.childrenParentGroup}
              </a>
            )}
            {/*<a*/}
            {/*  href={`/warehouse?category=${product.group}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}*/}
            {/*  className={styles.categoryLink}*/}
            {/*>*/}
            {/*  {" / " + product.group}*/}
            {/*</a>*/}
          </div>
        )}

        <div className={styles.ProductInfo}>
          <span className={styles.warehouseName}>
            {
              userWarehouses?.filter(
                (i) => i.item1 === product.mpWarehouseID
              )[0].item2
            }
          </span>
          {product.isCollection ? (
            ""
          ) : (
            <Button
              text={`Добавить товар в набор`}
              color="primary"
              onClick={() => setOpenAddCollection(true)}
              className={styles.addProductToCollectionButton}
            />
          )}
          {like ? (
            <MdOutlineFavorite
              size={32}
              onClick={() => likeClick(product.id)}
              className={styles.favoriteIcon}
              color="#f49100"
            />
          ) : (
            <MdOutlineFavoriteBorder
              size={32}
              onClick={() => likeClick(product.id)}
              className={styles.favoriteIcon}
              color="#f49100"
            />
          )}
          <OverlayTrigger overlay={<Tooltip>Синхронизировать</Tooltip>}>
            <div>
              <AiOutlineSync
                size={32}
                className={styles.syncProductButton}
                style={{ cursor: syncLoad ? "progress" : "" }}
                onClick={() => {
                  setSyncLoad(true);
                  syncProduct(product.mpWarehouseID).then((req) => {
                    if (req) {
                      toast.success("Товар синхронизирован");
                      setSyncLoad(false);
                    } else {
                      toast.error("Что-то пошло не так, попробуйте позже");
                      setSyncLoad(false);
                    }
                  });
                }}
              />
            </div>
          </OverlayTrigger>
        </div>
      </Col>
      <Row className={styles.content}>
        <Col xl={5} lg={5} md={6} sm={12} xs={12} className={styles.gallery}>
          {product.images[0].imageUri ? (
            <GalleryDetail
              galleries={Gallery()}
              thumbsPerView={3}
              isCollection={product.isCollection}
              isImage={true}
              addImage={addImage}
              productID={product.id}
            />
          ) : (
            <GalleryDetail
              galleries={[
                {
                  product: {
                    alt: "foto 1 - 550px",
                    format: "product",
                    url: "https://kare.ee/images/no-image.jpg",
                  },
                  thumbnail: {
                    alt: "foto 1 - 150px",
                    format: "thumbnail",
                    url: "https://kare.ee/images/no-image.jpg",
                  },
                  zoom: {
                    alt: "foto 1 - 550px",
                    format: "product",
                    url: "https://kare.ee/images/no-image.jpg",
                  },
                },
              ]}
              thumbsPerView={3}
              isCollection={product.isCollection}
              isImage={false}
              addImage={addImage}
              productID={product.id}
            />
          )}
          <p className={styles.description}>
            {product.description ? (
              <>
                {product.description}
                <OverlayTrigger overlay={<Tooltip>Копировать</Tooltip>}>
                  <span>
                    <BsFiles
                      size={24}
                      className={styles.copyIcon}
                      onClick={() => copy(product.description)}
                    />
                  </span>
                </OverlayTrigger>
              </>
            ) : (
              ""
            )}
            {product.isCollection ? (
              <OverlayTrigger
                overlay={<Tooltip>Редактировать описание набора</Tooltip>}
              >
                <span>
                  <CiEdit
                    size={30}
                    className={styles.editIcon}
                    onClick={() => setOpenEditDescription(true)}
                  />
                </span>
              </OverlayTrigger>
            ) : (
              ""
            )}{" "}
          </p>
          <CommentSection productId={product.id} />
        </Col>
        <Col xl={7} lg={7} md={6} sm={12} xs={12} className={styles.list}>
          <p className={styles.name}>{product.title}</p>

          <div className={styles.price}>
            Цена: {parseFloat(Number(product.stockPrice).toFixed(2))} ₽
          </div>
          <div className={styles.vendorCode}>
            <div>
              Цена упаковки:{" "}
              {parseFloat(Number(product.packingPrice).toFixed(2))} ₽
            </div>
            <div>
              Артикул: {product.vendorCode}
              <OverlayTrigger overlay={<Tooltip>Копировать</Tooltip>}>
                <span>
                  <BsFiles
                    size={24}
                    className={styles.copyVendorCodeIcon}
                    onClick={() => copy(product.vendorCode)}
                  />
                </span>
              </OverlayTrigger>
            </div>

            <p className={styles.vendorCodeWarningText}>
              <RiErrorWarningFill size={24} />
              Вводите именно этот Артикул на МаркетПлейсах и не меняйте его, а
              то связь с Платформой будет потеряна.
            </p>
          </div>

          <Table striped hover responsive>
            <tbody>
              {product.brand && (
                <tr className={styles.tr}>
                  <td>Бренд</td>
                  <td>
                    <a
                      href={`/warehouse?brand=${product.brandID}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}
                      className={styles.filterHover}
                    >
                      {product.brand}
                    </a>
                  </td>
                </tr>
              )}
              {product.category && (
                <tr className={styles.tr}>
                  <td>Справочник</td>
                  <td>{product.category}</td>
                </tr>
              )}
              {product.character && (
                <tr className={styles.tr}>
                  <td>Персонаж</td>
                  <td>
                    <a
                      href={`/warehouse?character=${product.characterID}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}r`}
                      className={styles.filterHover}
                    >
                      {product.character}
                    </a>
                  </td>
                </tr>
              )}
              {(product.wildberriesProductID ||
                product.ozonProductID ||
                product.yandexProductID) && (
                <tr className={styles.tr}>
                  <td>Товар добавлен в маркетплейсах</td>
                  <td>
                    <div className={styles.marketPlacesLogo}>
                      {product.ozonProductID ? (
                        <a href={product.ozonLink} target="_blank">
                          <img src={OzonLogo} alt="ozon logo" />
                        </a>
                      ) : (
                        ""
                      )}
                      {product.wildberriesProductID ? (
                        <a href={product.wildberriesLink} target="_blank">
                          <img
                            src={WildberriesLogo}
                            alt="wildberries logo"
                            className={styles.wildberriesLogo}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {product.yandexProductID ? (
                        <a href={product.yandexMarketLink} target="_blank">
                          <img src={YandexLogo} alt="yandex market logo" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              )}
              {product.gender && (
                <tr className={styles.tr}>
                  <td>Пол</td>
                  <td>
                    <a
                      href={`/warehouse?gender=${product.genderID}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}
                      className={styles.filterHover}
                    >
                      {product.gender}
                    </a>
                  </td>
                </tr>
              )}
              {product.age && (
                <tr className={styles.tr}>
                  <td>Возраст</td>
                  <td>
                    <a
                      href={`/warehouse?age=${product.ageID}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}
                      className={styles.filterHover}
                    >
                      {product.age}
                    </a>
                  </td>
                </tr>
              )}
              {product.vendorCode && (
                <tr className={styles.tr}>
                  <td>Код поставщика</td>
                  <td>
                    <p className={styles.noCopyText}>
                      {product.additionalParameter}
                      <OverlayTrigger overlay={<Tooltip>Копировать</Tooltip>}>
                        <span>
                          <BsFiles
                            size={24}
                            className={styles.copyIcon}
                            onClick={() => copy(product.additionalParameter)}
                          />
                        </span>
                      </OverlayTrigger>
                    </p>
                  </td>
                </tr>
              )}
              {product.vendorCode && (
                <tr className={styles.tr}>
                  <td>Штрих-код</td>
                  <td>
                    <p className={styles.noCopyText}>
                      {product.barcode}
                      <OverlayTrigger overlay={<Tooltip>Копировать</Tooltip>}>
                        <span>
                          <BsFiles
                            size={24}
                            className={styles.copyIcon}
                            onClick={() => copy(product.barcode)}
                          />
                        </span>
                      </OverlayTrigger>
                    </p>
                  </td>
                </tr>
              )}
              {product.producingCountry && (
                <tr className={styles.tr}>
                  <td>Страна-изготовитель</td>
                  <td>
                    <a
                      href={`/warehouse?country=${product.producingCountryID}&pageNumber=1&pageSize=20&mpWarehouseID=${product.mpWarehouseID}`}
                      className={styles.filterHover}
                    >
                      {product.producingCountry}
                    </a>
                  </td>
                </tr>
              )}
              {product.minPrice ? (
                <tr className={styles.tr}>
                  <td>Минимальная розничная цена</td>
                  <td>{product.minPrice}</td>
                </tr>
              ) : (
                ""
              )}
              {product.mainSupplier ? (
                <tr className={styles.tr}>
                  <td>Основной поставщик</td>
                  <td>{product.mainSupplier}</td>
                </tr>
              ) : (
                ""
              )}
              {product.targetAudience ? (
                <tr className={styles.tr}>
                  <td>Целевая аудитория</td>
                  <td>{product.targetAudience}</td>
                </tr>
              ) : (
                ""
              )}
              {product.carType ? (
                <tr className={styles.tr}>
                  <td>Марка автомобиля</td>
                  <td>{product.carType}</td>
                </tr>
              ) : (
                ""
              )}
              {product.commercialType ? (
                <tr className={styles.tr}>
                  <td>Коммерческий тип</td>
                  <td>{product.commercialType}</td>
                </tr>
              ) : (
                ""
              )}
              {info?.roles.includes("SuperAdmin") ? (
                product.amount >= 0 ? (
                  <tr className={styles.tr}>
                    <td>Количество</td>
                    <td>{product.amount}</td>
                  </tr>
                ) : (
                  ""
                )
              ) : product.amount >= 0 ? (
                <tr className={styles.tr}>
                  <td>Количество</td>
                  <td>{product.amount}</td>
                </tr>
              ) : (
                ""
              )}

              {!product.isCollection &&
                productCharacteristics?.map((characteristic) => {
                  return (
                    <tr className={styles.tr} key={characteristic.item1}>
                      <td>{characteristic.item1}</td>
                      <td>{characteristic.item2}</td>
                    </tr>
                  );
                })}
              {product.isCollection ? (
                collectionProdsCharLoad ? (
                  <tr className={styles.tr}>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <div style={{ width: "fit-content", margin: "auto" }}>
                        <BeatLoader
                          color={"#f49100"}
                          loading={true}
                          size={40}
                        />
                      </div>
                    </td>
                  </tr>
                ) : collectionProdsChar.length ? (
                  <>
                    <tr className={styles.tr}>
                      <td colSpan={2} style={{ textAlign: "center" }}>
                        Характеристики товаров в наборе
                      </td>
                    </tr>
                    <tr className={styles.tr}>
                      <td colSpan={2}>
                        <Accordion>
                          {collectionProdsChar.map((i, index) => {
                            return (
                              <Accordion.Item
                                key={index}
                                eventKey={index.toString()}
                              >
                                <Accordion.Header>
                                  <Badge pill bg="primary">
                                    {i.count}
                                  </Badge>
                                  <span style={{ marginLeft: "10px" }}>
                                    {i.name}
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Table striped hover responsive>
                                    <tbody>
                                      {i.characteristics?.map(
                                        (characteristic) => {
                                          return (
                                            <tr
                                              className={styles.tr}
                                              key={characteristic.item1}
                                            >
                                              <td>{characteristic.item1}</td>
                                              <td>{characteristic.item2}</td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {changePriceInputs[0].isShow ||
              changePriceInputs[1].isShow ||
              changePriceInputs[2].isShow ? (
                <tr className={styles.tr}>
                  <td colSpan={2}>
                    <p className={styles.vendorCodeWarningText}>
                      <RiErrorWarningFill size={24} />
                      Цена на МП должна быть более чем на 20% выше цены на
                      RoomSeller.
                    </p>
                    <p className={styles.vendorCodeWarningText}>
                      <RiErrorWarningFill size={24} />
                      На Ozon Новая цена должна отличаться от старой минимум на
                      5%.
                    </p>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {changePriceInputs.map((i) => {
                return (
                  i.isShow && (
                    <tr className={styles.tr} key={i.name}>
                      <td>Цена на {i.name}</td>
                      <td>
                        <div className={styles.priceInputs}>
                          <CustomInput
                            name={i.name}
                            value={i.price}
                            error={
                              Number(i.price) < product.stockPrice * 1.2
                                ? " "
                                : ""
                            }
                            onChange={(e) => {
                              if (i.name === "Ozon") {
                                setOzonPrice(e.target.value);
                              } else if (i.name === "Wildberries") {
                                setWildberriesPrice(e.target.value);
                              } else {
                                setYandexPrice(e.target.value);
                              }
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <Button
                            disabled={
                              Number(i.oldPrice) === Number(i.price) ||
                              Number(i.price) < product.stockPrice * 1.2
                            }
                            className={
                              changePriceLoading ? styles.loadChangePrice : ""
                            }
                            onClick={i.onclick}
                          >
                            Изменить
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                );
              })}
              {ozonPriceLoad || wildberriesPriceLoad || yandexPriceLoad ? (
                <tr className={styles.tr}>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <div style={{ width: "fit-content", margin: "auto" }}>
                      <BeatLoader color={"#f49100"} loading={true} size={40} />
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>
          <div className={styles.allButtons}>
            {buttons.map((item, index) => {
              return (
                <div key={index}>
                  <a
                    href={item.url}
                    target="_blank"
                    onClick={(event) =>
                      item.disabled ? event.preventDefault() : {}
                    }
                    style={{ cursor: "default" }}
                    className={item.disabled ? styles.disabledButton : ""}
                  >
                    <Button
                      text={item.text}
                      size="big"
                      className={item.className}
                      disabled={item.disabled}
                    />
                  </a>
                </div>
              );
            })}
          </div>
          {product.productPrices && (
            <Table striped hover>
              <thead>
                <tr className={styles.tr}>
                  <th colSpan={3} style={{ textAlign: "center" }}>
                    История цены
                  </th>
                </tr>
                <tr className={styles.tr}>
                  <th>Дата</th>
                  <th>Цена</th>
                  <th>Колебание</th>
                </tr>
              </thead>
              <tbody>
                {product.productPrices
                  .slice()
                  .reverse()
                  .map((item, index) => (
                    <tr key={index} className={styles.tr}>
                      <td>
                        {item.changeDate
                          ? moment(item.changeDate).format("DD.MM.YYYY")
                          : ""}
                      </td>
                      <td>{item.price}</td>
                      <td>
                        {index < product.productPrices.length - 1 && (
                          <p
                            style={{
                              color:
                                ((product.productPrices[index + 1].price -
                                  product.productPrices[index].price) *
                                  100) /
                                  product.productPrices[index].price >
                                0
                                  ? "#228B22"
                                  : "#B22222",
                            }}
                          >
                            {(
                              ((product.productPrices[index + 1].price -
                                product.productPrices[index].price) *
                                100) /
                              product.productPrices[index].price
                            ).toFixed(2) + "%"}
                          </p>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>

      {openAddCollection && (
        <Modal
          className={styles.cartModal}
          children={
            <AddProductToCollection
              productID={product.id}
              setClose={setOpenAddCollection}
              warehouseID={product.mpWarehouseID}
            />
          }
          isOpen={openAddCollection}
          onClose={() => {
            setOpenAddCollection(false);
          }}
        />
      )}
      {openEditDescription && (
        <Modal
          className={styles.cartModal}
          children={
            <EditCollectionDesc
              productID={product.id}
              description={product.description}
              setClose={setOpenEditDescription}
            />
          }
          isOpen={openEditDescription}
          onClose={() => {
            setOpenEditDescription(false);
          }}
        />
      )}
    </Row>
  );
});
export default ProductItemTables;
