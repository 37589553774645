import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import styles from "./ProfileSettings.module.scss";
import { OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { MdDeleteOutline, MdOutlinePostAdd } from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";
import { HiDocumentDownload } from "react-icons/hi";
import DeleteModal from "../../../components/DeleteModal";

const UploadDocuments: React.FC = observer(() => {
  const {
    userStore: {
      deleteUserDoc,
      uploadUserDocument,
      setUserDocs,
      userDocs,
      info,
    },
  } = useContext(RootStoreContext);

  const [DocumentLoad, setDocumentLoad] = useState<boolean>(false);

  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");

  const onChange = (e: any) => {
    setDocumentLoad(true);
    uploadUserDocument(e.target.files[0]).then(() => {
      setDocumentLoad(false);
    });
  };
  return (
    <Row className={styles.documentUpload}>
      <input
        id="file-input"
        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
        onChange={(e) => {
          onChange(e);
          e.target.value = "";
        }}
        type="file"
        style={{ display: "none" }}
      />

      <Table striped hover>
        <thead>
          <tr>
            <th>Наименование файла</th>
            <th className={styles.packageLabel}>
              {DocumentLoad ? (
                <div style={{ width: "fit-content", margin: "auto" }}>
                  <BeatLoader color={"#fff"} loading={DocumentLoad} size={38} />
                </div>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Добавить файл</Tooltip>}
                >
                  <div>
                    <label
                      htmlFor="file-input"
                      className={styles.packageLabelA}
                    >
                      <MdOutlinePostAdd size={38} color={"#fff"} />
                    </label>
                  </div>
                </OverlayTrigger>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {userDocs &&
            info &&
            userDocs.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item[0]}</td>
                  <td className={styles.packageLabel}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Скачать</Tooltip>}
                    >
                      <div>
                        <a href={item[1]} className={styles.packageLabelA}>
                          <HiDocumentDownload size={38} />
                        </a>
                      </div>
                    </OverlayTrigger>
                  </td>
                  <td style={{ width: "38px" }}>
                    <MdDeleteOutline
                      size={38}
                      onClick={() => {
                        setIsDelete(true);
                        setDeleteId(item[0]);
                      }}
                      className={styles.deleteIcon}
                      color={"#f33"}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {isDelete && info && userDocs && (
        <DeleteModal
          text={"Удалить файл?"}
          close={() => setIsDelete(false)}
          okFunc={() => {
            deleteUserDoc(deleteId, info.id).then((i) => {
              if (i) {
                setUserDocs(userDocs.filter((i) => i[0] !== deleteId));
              }
              setIsDelete(false);
            });
          }}
        />
      )}
    </Row>
  );
});
export default UploadDocuments;
