import { Col, Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./ProfileSettings.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import CustomInput from "../../../components/CustomInput";
import Button from "../../../components/Button";
import {
  OzonLogisticsI,
  WildberriesLogisticsI,
  YandexLogisticsI,
} from "stores/MarketPlaceStore";
import BeatLoader from "react-spinners/BeatLoader";
import ToggleSwitch from "components/ToggleSwitch";

const Logistics: React.FC = observer(() => {
  const {
    userStore: { userInfo },
    marketPlacesStore: {
      getOzonLogisticsProperties,
      getYandexLogisticsProperties,
      getWildberriesLogisticsProperties,
      changeOzonLogistics,
      changeYandexLogistics,
      changeWildberriesLogistics,
      getIsMPBanned,
      addTradeBanMP,
      deleteTradeBanMP,
    },
  } = useContext(RootStoreContext);

  const [ozonLogisticsInfo, setOzonLogisticsInfo] =
    useState<OzonLogisticsI | null>();
  const [yandexLogisticsInfo, setYandexLogisticsInfo] =
    useState<YandexLogisticsI | null>();
  const [wildberriesLogisticsInfo, setWildberriesLogisticsInfo] =
    useState<WildberriesLogisticsI | null>();

  const [changeOzonLogisticsLoad, setChangeOzonLogisticsLoad] =
    useState<boolean>(false);
  const [changeYandexLogisticsLoad, setChangeYandexLogisticsLoad] =
    useState<boolean>(false);
  const [changeWildberriesLogisticsLoad, setChangeWildberriesLogisticsLoad] =
    useState<boolean>(false);

  const [ozonLogisticsLoad, setOzonLogisticsLoad] = useState<boolean>(false);
  const [yandexLogisticsLoad, setYandexLogisticsLoad] =
    useState<boolean>(false);
  const [wildberriesLogisticsLoad, setWildberriesLogisticsLoad] =
    useState<boolean>(false);

  const [ozonIsBanned, setOzonIsBanned] = useState<boolean>(false);
  const [yandexIsBanned, setYandexIsBanned] = useState<boolean>(false);
  const [wildberriesIsBanned, setWildberriesIsBanned] =
    useState<boolean>(false);

  useEffect(() => {
    if (userInfo?.mpWarehouseID) {
      setOzonLogisticsLoad(true);
      setWildberriesLogisticsLoad(true);
      setYandexLogisticsLoad(true);
      getOzonLogisticsProperties(userInfo.mpWarehouseID).then((i) => {
        setOzonLogisticsInfo(i);
        setOzonLogisticsLoad(false);
      });
      getWildberriesLogisticsProperties(userInfo.mpWarehouseID).then((i) => {
        setWildberriesLogisticsInfo(i);
        setWildberriesLogisticsLoad(false);
      });
      getYandexLogisticsProperties(userInfo.mpWarehouseID).then((i) => {
        setYandexLogisticsInfo(i);
        setYandexLogisticsLoad(false);
      });
      getIsMPBanned(userInfo.mpWarehouseID, 1).then((i) => {
        setOzonIsBanned(i);
      });
      getIsMPBanned(userInfo.mpWarehouseID, 2).then((i) => {
        setYandexIsBanned(i);
      });
      getIsMPBanned(userInfo.mpWarehouseID, 3).then((i) => {
        setWildberriesIsBanned(i);
      });
    }
  }, [
    userInfo?.mpWarehouseID,
    getOzonLogisticsProperties,
    getWildberriesLogisticsProperties,
    getYandexLogisticsProperties,
    setOzonIsBanned,
    setWildberriesIsBanned,
    setYandexIsBanned,
    getIsMPBanned,
  ]);

  return (
    <>
      {ozonLogisticsLoad ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={true} size={38} />
        </div>
      ) : ozonLogisticsInfo && userInfo ? (
        <Col xl={4} lg={6} md={12} sm={12} xs={12}>
          <Table striped hover responsive className={styles.bankInfoTable}>
            <tbody>
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <div className={styles.MPLogisticTitle}>
                    <div>
                      <b style={{ color: "#005bff" }}>Ozon</b>
                    </div>
                    <div>
                      <ToggleSwitch
                        checked={!ozonIsBanned}
                        cursor={"pointer"}
                        onChange={() => {
                          if (!ozonIsBanned) {
                            addTradeBanMP(userInfo.mpWarehouseID, 1).then(
                              (i) => {
                                if (i) {
                                  setOzonIsBanned(true);
                                }
                              }
                            );
                          } else {
                            deleteTradeBanMP(userInfo.mpWarehouseID, 1).then(
                              (i) => {
                                if (i) {
                                  setOzonIsBanned(false);
                                }
                              }
                            );
                          }
                        }}
                        name="import"
                        leftText="Отключить"
                        rightText="Подключить"
                        icons={false}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Метод работы</b>
                </td>
                <td>
                  <CustomInput
                    value={ozonLogisticsInfo.workingMethodOzon}
                    name={"workingMethod"}
                    onChange={(e) =>
                      setOzonLogisticsInfo({
                        ...ozonLogisticsInfo,
                        workingMethodOzon: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Адрес склада</b>
                </td>
                <td>
                  <CustomInput
                    value={ozonLogisticsInfo.warehouseAddress}
                    name={"warehouseAddress"}
                    onChange={(e) =>
                      setOzonLogisticsInfo({
                        ...ozonLogisticsInfo,
                        warehouseAddress: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Режим работы</b>
                </td>
                <td>
                  <CustomInput
                    value={ozonLogisticsInfo.operatingMode}
                    name={"operatingMode"}
                    onChange={(e) =>
                      setOzonLogisticsInfo({
                        ...ozonLogisticsInfo,
                        operatingMode: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Способ доставки</b>
                </td>
                <td>
                  <CustomInput
                    value={ozonLogisticsInfo.deliveryMethod}
                    name={"deliveryMethod"}
                    onChange={(e) =>
                      setOzonLogisticsInfo({
                        ...ozonLogisticsInfo,
                        deliveryMethod: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Адрес точки отгрузки</b>
                </td>
                <td>
                  <CustomInput
                    value={ozonLogisticsInfo.shippingPointAddress}
                    name={"shippingPointAddress"}
                    onChange={(e) =>
                      setOzonLogisticsInfo({
                        ...ozonLogisticsInfo,
                        shippingPointAddress: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Таймслот отгрузки</b>
                </td>
                <td>
                  <CustomInput
                    value={ozonLogisticsInfo.shipmentTimeslot}
                    name={"shipmentTimeslot"}
                    onChange={(e) =>
                      setOzonLogisticsInfo({
                        ...ozonLogisticsInfo,
                        shipmentTimeslot: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Прием заказов</b>
                </td>
                <td>
                  <CustomInput
                    value={ozonLogisticsInfo.acceptingOrders}
                    name={"acceptingOrders"}
                    onChange={(e) =>
                      setOzonLogisticsInfo({
                        ...ozonLogisticsInfo,
                        acceptingOrders: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <div
                    className={
                      changeOzonLogisticsLoad
                        ? styles.changeButtonLoad
                        : styles.changeButton
                    }
                  >
                    <Button
                      onClick={() => {
                        setChangeOzonLogisticsLoad(true);
                        changeOzonLogistics(
                          ozonLogisticsInfo,
                          userInfo?.mpWarehouseID
                        ).then(() => {
                          setChangeOzonLogisticsLoad(false);
                        });
                      }}
                    >
                      Изменить данные
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      ) : (
        ""
      )}
      {wildberriesLogisticsLoad ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={true} size={38} />
        </div>
      ) : wildberriesLogisticsInfo && userInfo ? (
        <Col xl={4} lg={6} md={12} sm={12} xs={12}>
          <Table striped hover responsive className={styles.bankInfoTable}>
            <tbody>
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <div className={styles.MPLogisticTitle}>
                    <div>
                      <b style={{ color: "#cb11ab" }}>Wildberries</b>
                    </div>
                    <div>
                      <ToggleSwitch
                        checked={!wildberriesIsBanned}
                        cursor={"pointer"}
                        onChange={() => {
                          if (!wildberriesIsBanned) {
                            addTradeBanMP(userInfo.mpWarehouseID, 3).then(
                              (i) => {
                                if (i) {
                                  setWildberriesIsBanned(true);
                                }
                              }
                            );
                          } else {
                            deleteTradeBanMP(userInfo.mpWarehouseID, 3).then(
                              (i) => {
                                if (i) {
                                  setWildberriesIsBanned(false);
                                }
                              }
                            );
                          }
                        }}
                        name="import"
                        leftText="Отключить"
                        rightText="Подключить"
                        icons={false}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Метод работы</b>
                </td>
                <td>
                  <CustomInput
                    value={wildberriesLogisticsInfo.workingMethodWildberries}
                    name={"workingMethod"}
                    onChange={(e) =>
                      setWildberriesLogisticsInfo({
                        ...wildberriesLogisticsInfo,
                        workingMethodWildberries: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Адрес склада</b>
                </td>
                <td>
                  <CustomInput
                    value={wildberriesLogisticsInfo.warehouseAddress}
                    name={"warehouseAddress"}
                    onChange={(e) =>
                      setWildberriesLogisticsInfo({
                        ...wildberriesLogisticsInfo,
                        warehouseAddress: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Тип доставки</b>
                </td>
                <td>
                  <CustomInput
                    value={wildberriesLogisticsInfo.deliveryType}
                    name={"deliveryType"}
                    onChange={(e) =>
                      setWildberriesLogisticsInfo({
                        ...wildberriesLogisticsInfo,
                        deliveryType: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Адрес доставки</b>
                </td>
                <td>
                  <CustomInput
                    value={wildberriesLogisticsInfo.deliveryAddress}
                    name={"deliveryAddress"}
                    onChange={(e) =>
                      setWildberriesLogisticsInfo({
                        ...wildberriesLogisticsInfo,
                        deliveryAddress: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>ПВЗ для возвратов</b>
                </td>
                <td>
                  <CustomInput
                    value={wildberriesLogisticsInfo.returnedOrdersPoint}
                    name={"returnedOrdersPoint"}
                    onChange={(e) =>
                      setWildberriesLogisticsInfo({
                        ...wildberriesLogisticsInfo,
                        returnedOrdersPoint: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <div
                    className={
                      changeWildberriesLogisticsLoad
                        ? styles.changeButtonLoad
                        : styles.changeButton
                    }
                  >
                    <Button
                      onClick={() => {
                        setChangeWildberriesLogisticsLoad(true);
                        changeWildberriesLogistics(
                          wildberriesLogisticsInfo,
                          userInfo?.mpWarehouseID
                        ).then(() => {
                          setChangeWildberriesLogisticsLoad(false);
                        });
                      }}
                    >
                      Изменить данные
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      ) : (
        ""
      )}
      {yandexLogisticsLoad ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={true} size={38} />
        </div>
      ) : yandexLogisticsInfo && userInfo ? (
        <Col xl={4} lg={6} md={12} sm={12} xs={12}>
          <Table striped hover responsive className={styles.bankInfoTable}>
            <tbody>
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <div className={styles.MPLogisticTitle}>
                    <div>
                      <b style={{ color: "#f5bc00" }}>Яндекс Маркет</b>
                    </div>
                    <div>
                      <ToggleSwitch
                        checked={!yandexIsBanned}
                        cursor={"pointer"}
                        onChange={() => {
                          if (!yandexIsBanned) {
                            addTradeBanMP(userInfo.mpWarehouseID, 2).then(
                              (i) => {
                                if (i) {
                                  setYandexIsBanned(true);
                                }
                              }
                            );
                          } else {
                            deleteTradeBanMP(userInfo.mpWarehouseID, 2).then(
                              (i) => {
                                if (i) {
                                  setYandexIsBanned(false);
                                }
                              }
                            );
                          }
                        }}
                        name="import"
                        leftText="Отключить"
                        rightText="Подключить"
                        icons={false}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Метод работы</b>
                </td>
                <td>
                  <CustomInput
                    value={yandexLogisticsInfo.workingMethodYandex}
                    name={"workingMethod"}
                    onChange={(e) =>
                      setYandexLogisticsInfo({
                        ...yandexLogisticsInfo,
                        workingMethodYandex: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Количество отгружаемых заказов в день</b>
                </td>
                <td>
                  <CustomInput
                    value={yandexLogisticsInfo.ordersPerDay}
                    name={"workingMethod"}
                    onChange={(e) =>
                      setYandexLogisticsInfo({
                        ...yandexLogisticsInfo,
                        ordersPerDay: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Место отгрузки</b>
                </td>
                <td>
                  <CustomInput
                    value={yandexLogisticsInfo.shippingPointAddressYandex}
                    name={"shippingPointAddress"}
                    onChange={(e) =>
                      setYandexLogisticsInfo({
                        ...yandexLogisticsInfo,
                        shippingPointAddressYandex: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Сколько дней до отгрузки</b>
                </td>
                <td>
                  <CustomInput
                    value={yandexLogisticsInfo.daysToShipping}
                    name={"daysToShipping"}
                    onChange={(e) =>
                      setYandexLogisticsInfo({
                        ...yandexLogisticsInfo,
                        daysToShipping: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Заказы в отгрузку поступают</b>
                </td>
                <td>
                  <CustomInput
                    value={yandexLogisticsInfo.shippingTime}
                    name={"shippingTime"}
                    onChange={(e) =>
                      setYandexLogisticsInfo({
                        ...yandexLogisticsInfo,
                        shippingTime: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>График работы отгрузок</b>
                </td>
                <td>
                  <CustomInput
                    value={yandexLogisticsInfo.shippingSchedule}
                    name={"workingMethod"}
                    onChange={(e) =>
                      setYandexLogisticsInfo({
                        ...yandexLogisticsInfo,
                        shippingSchedule: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <div
                    className={
                      changeYandexLogisticsLoad
                        ? styles.changeButtonLoad
                        : styles.changeButton
                    }
                  >
                    <Button
                      onClick={() => {
                        setChangeYandexLogisticsLoad(true);
                        changeYandexLogistics(
                          yandexLogisticsInfo,
                          userInfo?.mpWarehouseID
                        ).then(() => {
                          setChangeYandexLogisticsLoad(false);
                        });
                      }}
                    >
                      Изменить данные
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      ) : (
        ""
      )}
    </>
  );
});
export default Logistics;
