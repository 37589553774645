import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import handleApiPath from "../utils/handleApiPath";
import { toast } from "react-hot-toast";

interface ICollection {
  id: string;
  title: string;
  description: string;
  amount: number;
  stockPrice: number;
  vendorCode: string;
  mpWarehouseID?: number;
  childProducts?: string[];
  isAddedToOzon: boolean;
  isAddedToWildberries: boolean;
  isAddedToYandexMarket: boolean;
  image: string;
  isFavorite: boolean;
}
class CollectionsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  collectionProducts: { item1: string; item2: string; item3: number }[] | null =
    null;

  collections: ICollection[] | null = null;

  setCollectionProducts = (
    data: { item1: string; item2: string; item3: number }[]
  ): void => {
    this.collectionProducts = data;
  };

  setCollections = (data: ICollection[]): void => {
    this.collections = data;
  };

  newCollection = async (
    Name: string,
    MpWarehouseID: string
  ): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_COLLECTIONS");
      const response = await fetch(
        `${endpoint}/NewCollection?Name=${Name}&MpWarehouseID=${MpWarehouseID}`,
        requestOptions
      );

      const data = await response.text();

      if (response.ok) {
        toast.success(`Набор "${Name}" успешно создан!`);
        return data;
      } else {
        toast.error(
          "Набор создать не удалось. Попробуйте снова с другим именем."
        );
        return null;
      }
    } catch (error) {
      toast.error(
        "Набор создать не удалось. Попробуйте снова с другим именем."
      );
      return null;
    }
  };

  addProductToCollection = async (
    collectionId: string,
    productID: string,
    quantity: number
  ): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_COLLECTIONS");
      const response = await fetch(
        `${endpoint}/AddProductToCollection?CollectionsID=${collectionId}&ProductID=${productID}&Quantity=${quantity}`,
        requestOptions
      );

      if (response.ok) {
        return "Товар успешно добавлен в набор";
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  getCollectionProducts = async (
    collectionId: string
  ): Promise<{ item1: string; item2: string; item3: number }[] | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_COLLECTIONS");
      const response = await fetch(
        `${endpoint}/ChildCollection?ProductID=${collectionId}`,
        requestOptions
      );

      const data = await response.json();

      if (response.ok) {
        this.setCollectionProducts(data);
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  getCollections = async (
    mpWarehouseID: number
  ): Promise<ICollection[] | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_COLLECTIONS");
      const response = await fetch(
        `${endpoint}/Index?MpWarehouseID=${mpWarehouseID}`,
        requestOptions
      );

      const data = await response.json();

      if (response.ok) {
        this.setCollections(data);
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  deleteCollection = async (collectionsID: string): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_COLLECTIONS");
      const response = await fetch(
        `${endpoint}/DeleteCollection?CollectionID=${collectionsID}`,
        requestOptions
      );

      const data = await response.text();

      if (response.ok) {
        toast.success(`Набор успешно удалён!`);
        return data;
      } else {
        toast.error("Произошла ошибка!");
        return null;
      }
    } catch (error) {
      toast.error("Произошла ошибка!");
      return null;
    }
  };

  editCollectionDescription = async (
    collectionID: string,
    description: string
  ): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_COLLECTIONS");
      const response = await fetch(
        `${endpoint}/Description?CollectionID=${collectionID}&Description=${description}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      } else {
        toast.error("Произошла ошибка!");
        return null;
      }
    } catch (error) {
      toast.error("Произошла ошибка!");
      return null;
    }
  };

  changeCollectionStockPrice = async (
    collectionID: string,
    price: string
  ): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_COLLECTIONS");
      const response = await fetch(
        `${endpoint}/StockPrice?CollectionID=${collectionID}&Price=${price}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      } else {
        toast.error("Произошла ошибка!");
        return null;
      }
    } catch (error) {
      toast.error("Произошла ошибка!");
      return null;
    }
  };
}

export default CollectionsStore;
