import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import s from "./ProfileSettings.module.scss";
import { RootStoreContext } from "stores/RootStore";
import { check } from "prettier";
const ChooseFilters: FC = observer(() => {
  const {
    warehouseStore: { getFilters, addFilters },
    userStore: { userInfo },
  } = useContext(RootStoreContext);
  const [checkedFilters, setCheckedFilters] = useState<string[]>([]);
  const [filters, setFilters] = useState<
    { id: string; title: string; isSelected: boolean }[]
  >([]);

  useEffect(() => {
    getFilters(userInfo?.mpWarehouseID).then((data) => {
      setFilters(data);
    });
  }, [getFilters, userInfo?.mpWarehouseID]);
  function checkboxHandler(event: any) {
    const { value, checked } = event.target;
    if (!checked) {
      setCheckedFilters((pre) => pre.filter((i) => i !== value));
    } else {
      setCheckedFilters((pre) => [...pre, value]);
    }
    setFilters((filters) =>
      filters.map((i) => {
        if (i.id === value) {
          return { ...i, isSelected: checked };
        } else {
          return i;
        }
      })
    );
  }
  return filters ? (
    <div className={s.containerTable}>
      <Table hover responsive className={s.table}>
        <thead>
          <tr>
            <th>Фильтры</th>
            <th style={{ textAlign: "center" }}>Выбрать фильтр</th>
          </tr>
        </thead>
        <tbody>
          {filters?.map((item: any) => {
            return (
              <tr>
                <td>{item.title}</td>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={item.isSelected}
                    value={item.id}
                    onChange={checkboxHandler}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        style={{
          textAlign: "center",
          marginTop: "10px",
          background: "#F79633",
          border: "none",
          borderRadius: "20px",
          fontSize: "16px",
        }}
        onClick={() => {
          addFilters(userInfo?.mpWarehouseID, checkedFilters).then(() => {
            console.log("ok");
          });
        }}
      >
        Изменить данные
      </Button>
    </div>
  ) : (
    <></>
  );
});
export default ChooseFilters;
