import React from "react";
import styles from "./infoSection.module.scss";
import { Col, Row } from "react-bootstrap";

export const InfoSection = () => {
  const provider = [
    "Загрузка ассортимента на платформу",
    "Упаковка и доставка заказа",
    "Забор возврата",
  ];

  const platform = [
    "Подбор менеджеров",
    "Создание карточки товара",
    "Инфографика",
    "Слежение за алгоритмами ранжирования",
    "Выведение товаров в топ поиска",
    "Участие в акциях маркетплейсов",
    "Расчет ценообразования",
    "Аналитика и анализ",
    "Обратная связь с клиентами",
  ];
  return (
    <Row>
      <Col className={styles.infoSectionContainer}>
        <div className={styles.infoSectionContent}>
          <h1>ПОСТАВЩИК</h1>
          {provider.map((str, index) => (
            <p key={index}>
              <span>{index + 1}</span>

              <p> {str}</p>
            </p>
          ))}

          <h1>ПЛАТФОРМА</h1>
          {platform.map((str, index) => (
            <p key={index}>
              <span>{index + 1}</span>

              <p> {str}</p>
            </p>
          ))}
        </div>
      </Col>
    </Row>
  );
};
