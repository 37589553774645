import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import styles from "./Finance.module.scss";
import { Accordion } from "react-bootstrap";
import ABody from "./ABody";

const Finance: React.FC = observer(() => {
  const {
    financeStore: { getSellers, financeSellers },
    marketPlacesStore: { userWarehouses },
    userStore: { info, userInfo },
  } = useContext(RootStoreContext);

  const [sellersLoading, setSellersLoading] = useState<boolean>(true);

  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    if (info) {
      if (info.roles.includes("WarehouseOwner")) {
        setSellersLoading(true);
        getSellers().then(() => {
          setSellersLoading(false);
        });
      }
    }
  }, [getSellers, setSellersLoading, info]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Финансы</p>
      <div className={styles.wrapper}>
        {info ? (
          !info.roles.includes("WarehouseOwner") ? (
            !userWarehouses ? (
              <div style={{ width: "fit-content", margin: "auto" }}>
                <BeatLoader
                  color={"#f49100"}
                  loading={sellersLoading}
                  size={50}
                />
              </div>
            ) : (
              <Accordion>
                {userWarehouses.map((i, index) => {
                  return (
                    <Accordion.Item eventKey={index.toString()} key={i.item1}>
                      <Accordion.Header
                        onClick={() => setSelected(i.item1.toString())}
                      >
                        {i.item2}
                      </Accordion.Header>
                      <Accordion.Body>
                        {selected === i.item1.toString() ? (
                          <ABody userId={info.id} mpWarehouseId={i.item1} />
                        ) : (
                          ""
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            )
          ) : sellersLoading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader
                color={"#f49100"}
                loading={sellersLoading}
                size={50}
              />
            </div>
          ) : (
            <Accordion>
              {financeSellers &&
                userInfo &&
                financeSellers?.map((i, index) => {
                  return (
                    <Accordion.Item eventKey={index.toString()} key={i[0]}>
                      <Accordion.Header onClick={() => setSelected(i[0])}>
                        {i[1]}
                      </Accordion.Header>
                      <Accordion.Body>
                        {selected === i[0] ? (
                          <ABody
                            userId={i[0]}
                            mpWarehouseId={userInfo.mpWarehouseID}
                          />
                        ) : (
                          ""
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
});
export default Finance;
