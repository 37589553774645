import React from "react";
import styles from "./Footer.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LightLogo, SocialMedia } from "../../../components/SvgIcons";

interface IProps {
  aboutRef: any;
  contactRef: any;
  advantageRef: any;
  reilablyRef: any;
  sellerRef: any;
}

const Footer: React.FC<IProps> = ({
  aboutRef,
  contactRef,
  advantageRef,
  reilablyRef,
  sellerRef,
}) => {
  const handleScrollTo = (element: HTMLElement | null) => {
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 120,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <footer>
      <div className={styles.footer}>
        <Container className={styles.footerContent}>
          <div className={styles.contact}>
            <Link to="/" className={styles.Logo}>
              <LightLogo />
            </Link>
            <h3>8 (989) 299-10-10</h3>
            <h3>INFO@ROOMSELLER.RU</h3>
          </div>
          <ul className={styles.centerDiv}>
            <li
              onClick={() => {
                handleScrollTo(aboutRef.current);
              }}
            >
              О НАС
            </li>
            <li>УСЛУГИ</li>
            <li
              onClick={() => {
                handleScrollTo(reilablyRef.current);
              }}
            >
              ПРЕИМУЩЕСТВА
            </li>
          </ul>
          <ul className={styles.centerDiv2}>
            <li
              onClick={() => {
                handleScrollTo(advantageRef.current);
              }}
            >
              МАРКЕТПЛЕЙСЫ
            </li>
            <li
              onClick={() => {
                handleScrollTo(sellerRef.current);
              }}
            >
              ПОСТАВЩИКАМ
            </li>
            <li>УСЛОВИЯ</li>
          </ul>
          <ul className={styles.social}>
            <li>МЫ В СОЦСЕТЯХ</li>
            <SocialMedia />
          </ul>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
