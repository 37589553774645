import styles from "./Marketplaces.module.scss";
import Button from "components/Button";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import toast from "react-hot-toast";
import { MPParams } from "../../../stores/MarketPlaceStore";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BsFiles } from "react-icons/bs";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { MdDeleteOutline, MdOutlineAddBox } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Wildberries } from "components/SvgIcons";

interface Props {
  setEdit: any;
  setFieldValue: any;
  setOpen: any;
  marketPlaceName: string;
  marketPlaceID: number;
  mpParams: MPParams[];
  setDeleteParams: React.Dispatch<
    React.SetStateAction<{
      marketPlaceParametersId: number;
      marketPlaceID: number;
      stockID: number;
      mpWarehouseID: number;
    }>
  >;
}

const MarketplacesTB: React.FC<Props> = observer(
  ({
    setEdit,
    setFieldValue,
    setOpen,
    marketPlaceName,
    marketPlaceID,
    mpParams,
    setDeleteParams,
  }) => {
    const [checkLoading, setCheckLoading] = useState<boolean>(false);
    const [yandexTokenLink, setYandexTokenLink] = useState<string>("");

    const {
      marketPlacesStore: {
        getMarketPlacesList,
        checkWildberries,
        checkOzon,
        checkYandex,
        getCheckOzon,
        getCheckWildberries,
        getCheckYandex,
        userWarehouses,
      },
      yandexStore: { getYandexToken },
    } = useContext(RootStoreContext);

    useEffect(() => {
      if (marketPlaceName === "YandexMarket") {
        getYandexToken().then((i) => {
          if (i) {
            setYandexTokenLink(i);
          }
        });
      }
    }, [marketPlaceName, getMarketPlacesList, getYandexToken]);

    const copy = async (text: string) => {
      await navigator.clipboard.writeText(text);
      toast.success("текст скопирован");
    };
    return (
      <div className={styles.row}>
        <div className="d-flex">
          <p className={styles.titleMarketplace}>
            {marketPlaceName}
            {mpParams.length > 0 && marketPlaceName === "Wildberries" ? (
              ""
            ) : (
              <MdOutlineAddBox
                size={32}
                color={"#198754"}
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={() => {
                  setFieldValue("mpName", marketPlaceName);
                  setFieldValue("marketPlaceID", marketPlaceID);
                  setFieldValue("apiKey", "");
                  setFieldValue("clientId", "");
                  setFieldValue("stockID", "");
                  setFieldValue("stockName", "");
                  setFieldValue("campaignId", "");
                  setFieldValue("mpWarehouseID", "");
                  setOpen(true);
                }}
              />
            )}
          </p>
          {/*{marketPlaceName === "YandexMarket" && yandexTokenLink ? (
            <a href={yandexTokenLink} target="_blank">
              <Button className={styles.getTokenButton}>Получить Token</Button>
            </a>
          ) : (
            ""
          )}*/}
        </div>

        <Table striped hover responsive>
          <thead>
            <tr>
              <th>
                {marketPlaceName === "YandexMarket"
                  ? "Токен (access_token)"
                  : marketPlaceName === "Ozon"
                  ? "Api key"
                  : "Токен combined"}
              </th>
              {marketPlaceName !== "Wildberries" ? <th>Client ID</th> : ""}
              {marketPlaceName === "YandexMarket" ? <th>Campaign ID</th> : ""}
              <th>Stock ID</th>
              <th> {marketPlaceName === "Wildberries" ? "" : "Stock name"}</th>
              <th>Склад</th>
              <th colSpan={2}>Подключение</th>
            </tr>
          </thead>
          <tbody>
            {mpParams.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item.apiKey.length > 32
                      ? item.apiKey.slice(0, 32) + "..."
                      : item.apiKey}
                    <OverlayTrigger overlay={<Tooltip>Редактировать</Tooltip>}>
                      <span>
                        <CiEdit
                          size={32}
                          className={styles.editIcon}
                          onClick={() => {
                            setOpen(true);
                            setEdit(marketPlaceID);
                            setFieldValue(
                              "marketPlaceParameterID",
                              item.marketPlaceParameterID
                            );
                            setFieldValue("mpName", marketPlaceName);
                            setFieldValue("marketPlaceID", marketPlaceID);
                            setFieldValue("apiKey", item.apiKey);
                            setFieldValue("clientId", item.clientId);
                            setFieldValue("stockID", item.stockID);
                            setFieldValue("stockName", item.stockName);
                            setFieldValue("campaignId", item.campaignId);
                            setFieldValue("mpWarehouseID", item.mpWarehouseID);
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Копировать</Tooltip>}>
                      <span>
                        <BsFiles
                          size={24}
                          className={styles.copyIcon}
                          onClick={() => copy(item.apiKey)}
                        />
                      </span>
                    </OverlayTrigger>
                  </td>
                  {marketPlaceName !== "Wildberries" ? (
                    <td>
                      {item.clientId}
                      <OverlayTrigger overlay={<Tooltip>Копировать</Tooltip>}>
                        <span>
                          <BsFiles
                            size={24}
                            className={styles.copyIcon}
                            onClick={() => copy(item.clientId)}
                          />
                        </span>
                      </OverlayTrigger>
                    </td>
                  ) : (
                    ""
                  )}
                  {marketPlaceName === "YandexMarket" ? (
                    <td>{item.campaignId}</td>
                  ) : (
                    ""
                  )}
                  <td>{item.stockID}</td>
                  <td>
                    {marketPlaceName === "Wildberries" ? "" : item.stockName}
                    {marketPlaceName === "Wildberries" ? (
                      ""
                    ) : (
                      <OverlayTrigger overlay={<Tooltip>Копировать</Tooltip>}>
                        <span>
                          <BsFiles
                            size={24}
                            className={styles.copyIcon}
                            onClick={() => copy(item.stockID.toString())}
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </td>

                  {/*{marketPlaceName === "YandexMarket" ? (
                    <td>{item.stockName}</td>
                  ) : (
                    ""
                  )}*/}
                  <td>
                    {
                      userWarehouses?.filter(
                        (i) => i.item1 === item.mpWarehouseID
                      )[0].item2
                    }
                  </td>
                  <td className={styles.check}>
                    <div className={styles.checkRoot}>
                      <div>
                        <span
                          style={checkLoading ? { cursor: "progress" } : {}}
                          onClick={() => {
                            if (marketPlaceName === "Wildberries") {
                              setCheckLoading(true);
                              getCheckWildberries(
                                item.marketPlaceParameterID
                              ).then(() => setCheckLoading(false));
                            } else if (marketPlaceName === "YandexMarket") {
                              setCheckLoading(true);
                              getCheckYandex(item.marketPlaceParameterID).then(
                                () => setCheckLoading(false)
                              );
                            } else {
                              setCheckLoading(true);
                              getCheckOzon(item.marketPlaceParameterID).then(
                                () => setCheckLoading(false)
                              );
                            }
                          }}
                        >
                          Проверить
                        </span>
                      </div>

                      <div>
                        {marketPlaceName === "Ozon" ? (
                          checkOzon[item.marketPlaceParameterID] !==
                          undefined ? (
                            checkOzon[item.marketPlaceParameterID] === true ? (
                              <OverlayTrigger
                                overlay={<Tooltip>Подключено</Tooltip>}
                              >
                                <div>
                                  <AiOutlineCheck size={32} color="#228B22" />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                overlay={<Tooltip>Не подключено</Tooltip>}
                              >
                                <div>
                                  <AiOutlineClose size={32} color="#B22222" />
                                </div>
                              </OverlayTrigger>
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {marketPlaceName === "Wildberries" ? (
                          checkWildberries !== null ? (
                            checkWildberries ? (
                              <OverlayTrigger
                                overlay={<Tooltip>Подключено</Tooltip>}
                              >
                                <div>
                                  <AiOutlineCheck size={32} color="#228B22" />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                overlay={<Tooltip>Не подключено</Tooltip>}
                              >
                                <div>
                                  <AiOutlineClose size={32} color="#B22222" />
                                </div>
                              </OverlayTrigger>
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {marketPlaceName === "YandexMarket" ? (
                          checkYandex[item.marketPlaceParameterID] !==
                          undefined ? (
                            checkYandex[item.marketPlaceParameterID] ===
                            true ? (
                              <OverlayTrigger
                                overlay={<Tooltip>Подключено</Tooltip>}
                              >
                                <div>
                                  <AiOutlineCheck size={32} color="#228B22" />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                overlay={<Tooltip>Не подключено</Tooltip>}
                              >
                                <div>
                                  <AiOutlineClose size={32} color="#B22222" />
                                </div>
                              </OverlayTrigger>
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <OverlayTrigger overlay={<Tooltip>Удалить</Tooltip>}>
                      <span>
                        <MdDeleteOutline
                          size={32}
                          onClick={() => {
                            setDeleteParams({
                              marketPlaceParametersId:
                                item.marketPlaceParameterID,
                              marketPlaceID: marketPlaceID,
                              stockID: item.stockID,
                              mpWarehouseID: item.mpWarehouseID,
                            });
                          }}
                          className={styles.editIcon}
                          color={"#f33"}
                        />
                      </span>
                    </OverlayTrigger>
                  </td>
                  {/* <td>
                    <FormCheck
                      type="switch"
                      size={40}
                      checked={item.stockState}
                      color={"#f49100"}
                      onChange={() => {}}
                    />
                  </td>*/}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
);
export default MarketplacesTB;
