import React from "react";
import styles from "./AdvantageSection.module.scss";
import Yandex from "../HomePageImages/yandex.png";
import { Ozon, Wildberries } from "../../../components/SvgIcons";

interface Props {
  advantageRef: any;
}

const AdvantageSection: React.FC<Props> = ({ advantageRef }) => {
  return (
    <div className={styles.AdvantageSection} ref={advantageRef}>
      <div className={styles.AdvantageHeader}>
        <h1>МАРКЕТПЛЕЙСЫ</h1>
        <p>
          Платформа на данный момент работает с 3 ведущими маркетплейсами
          России.
        </p>
      </div>
      <div className={styles.AdvantageInfo}>
        <div className={styles.AdvantageImgWild}>
          <Wildberries />
          <p>
            Вайлдберриз — международный маркетплейс, самый крупный в России.
            Объем продаж за 2022 год составил 317 млрд. руб. Более 100
            сортировочных центров. Маркетплейс производит еженедельные расчеты
            со своими продавцами, что очень удобно при финансовом планировании
          </p>
        </div>
        <div className={styles.AdvantageImgOzon}>
          <Ozon />
          <p>
            Озон — второй по обороту маркетплейс в России. Продажи за 2022 год
            выросли на 47% и составили 261 млрд.руб. Маркетплейс открыл Банк
            Озон, который позволяет клиентам экономить на комиссиях маркетплейса
            для продавцов. Доступна бесплатная система обучения работы продавцов
            на площадке.
          </p>
        </div>
        <div className={styles.AdvantageImgYandex}>
          <img className="image" src={Yandex} alt="Wild" />
          <p>
            Яндекс маркет — самый динамично развивающийся маркетплейс по итогам
            2022 года. Прирост продаж составил 67%. Маркетплейс активно
            привлекает новых продавцов, и предлагает продвинутые инструменты для
            продвижения. Частоту выплат от маркетплейса продавец может выбрать
            сам.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdvantageSection;
