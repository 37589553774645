import { Row, Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import styles from "./Products.module.scss";
import BeatLoader from "react-spinners/BeatLoader";

interface Props {
  warehouseID: string;
  mpName: string;
}

const LogisticPopup: React.FC<Props> = observer(({ warehouseID, mpName }) => {
  const {
    marketPlacesStore: {
      getOzonLogisticsProperties,
      getYandexLogisticsProperties,
      getWildberriesLogisticsProperties,
      ozonLogistics,
      yandexLogistics,
      wildberriesLogistics,
    },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (mpName === "Ozon") {
      getOzonLogisticsProperties(Number(warehouseID)).then((i) => {
        setLoading(false);
      });
    } else if (mpName === "Wildberries") {
      getWildberriesLogisticsProperties(Number(warehouseID)).then((i) => {
        setLoading(false);
      });
    } else {
      getYandexLogisticsProperties(Number(warehouseID)).then((i) => {
        setLoading(false);
      });
    }
  }, [
    mpName,
    warehouseID,
    getOzonLogisticsProperties,
    getWildberriesLogisticsProperties,
    getYandexLogisticsProperties,
  ]);

  return (
    <div className={styles.collectionModalRoot}>
      {loading ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={true} size={38} />
        </div>
      ) : (
        <Table striped hover responsive className={styles.bankInfoTable}>
          <tbody>
            <tr>
              <td colSpan={2} style={{ textAlign: "center" }}>
                <b>{mpName}</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>Метод работы</b>
              </td>
              <td>
                {mpName === "Ozon"
                  ? ozonLogistics?.workingMethodOzon
                  : mpName === "Wildberries"
                  ? wildberriesLogistics?.workingMethodWildberries
                  : yandexLogistics?.workingMethodYandex}
              </td>
            </tr>
            {mpName === "Ozon" || mpName === "Wildberries" ? (
              <tr>
                <td>
                  <b>Адрес склада</b>
                </td>
                <td>
                  {mpName === "Ozon"
                    ? ozonLogistics?.warehouseAddress
                    : wildberriesLogistics?.warehouseAddress}
                </td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Ozon" ? (
              <tr>
                <td>
                  <b>Режим работы</b>
                </td>
                <td>{ozonLogistics?.operatingMode}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Ozon" ? (
              <tr>
                <td>
                  <b>Способ доставки</b>
                </td>
                <td>{ozonLogistics?.deliveryMethod}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Ozon" ? (
              <tr>
                <td>
                  <b>Адрес точки отгрузки</b>
                </td>
                <td>{ozonLogistics?.shippingPointAddress}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Ozon" ? (
              <tr>
                <td>
                  <b>Таймслот отгрузки</b>
                </td>
                <td>{ozonLogistics?.shipmentTimeslot}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Ozon" ? (
              <tr>
                <td>
                  <b>Прием заказов</b>
                </td>
                <td>{ozonLogistics?.acceptingOrders}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Wildberries" ? (
              <tr>
                <td>
                  <b>Тип доставки</b>
                </td>
                <td>{wildberriesLogistics?.deliveryType}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Wildberries" ? (
              <tr>
                <td>
                  <b>Адрес доставки</b>
                </td>
                <td>{wildberriesLogistics?.deliveryAddress}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Wildberries" ? (
              <tr>
                <td>
                  <b>ПВЗ для возвратов</b>
                </td>
                <td>{wildberriesLogistics?.returnedOrdersPoint}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Яндекс Маркет" ? (
              <tr>
                <td>
                  <b>Количество отгружаемых заказов в день</b>
                </td>
                <td>{yandexLogistics?.ordersPerDay}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Яндекс Маркет" ? (
              <tr>
                <td>
                  <b>Место отгрузки</b>
                </td>
                <td>{yandexLogistics?.shippingPointAddressYandex}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Яндекс Маркет" ? (
              <tr>
                <td>
                  <b>Сколько дней до отгрузки</b>
                </td>
                <td>{yandexLogistics?.daysToShipping}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Яндекс Маркет" ? (
              <tr>
                <td>
                  <b>Заказы в отгрузку поступают</b>
                </td>
                <td>{yandexLogistics?.shippingTime}</td>
              </tr>
            ) : (
              ""
            )}
            {mpName === "Яндекс Маркет" ? (
              <tr>
                <td>
                  <b>График работы отгрузок</b>
                </td>
                <td>{yandexLogistics?.shippingSchedule}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
});

export default LogisticPopup;
