import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../../stores/RootStore";
import AskAQuestion from "./AskAQuestion/AskAQuestion";
import Questions from "./Questions/Questions";

interface Props {
  productId: string;
}
const CommentSection: React.FC<Props> = observer(({ productId }) => {
  const {
    warehouseStore: { product },
    userStore: { info },
  } = useContext(RootStoreContext);
  return (
    <div>
      {info?.roles.includes("Seller") ? (
        <AskAQuestion productId={productId} />
      ) : (
        ""
      )}
      <Questions productId={productId} />
    </div>
  );
});
export default CommentSection;
