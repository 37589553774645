import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import { RootStore } from "./RootStore";
import { toast } from "react-hot-toast";

class WildberriesStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  wildberriesStocks: any | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setWildberriesStocks = (data: any): void => {
    this.wildberriesStocks = data;
  };

  getWildberriesShipmentAct = async (userId: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WILDBERRIES");
      const response = await fetch(
        `${endpoint}/GetShipmentAct/${userId}`,
        requestOptions
      );
      const data = await response.blob();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  GetWildberriesProductPrice = async (
    UserID: string,
    ProductID: string
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WILDBERRIES");
      const response = await fetch(
        `${endpoint}/GetWildberriesProductPrice?UserID=${UserID}&ProductID=${ProductID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  setWildberriesProductNewPrice = async (
    UserID: string,
    ProductID: string,
    NewPriceOffer: string
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WILDBERRIES");
      const response = await fetch(
        `${endpoint}/SetWildberriesProductNewPrice?UserID=${UserID}&ProductID=${ProductID}&NewPriceOffer=${NewPriceOffer}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Цена товара успешно установлена");
      } else {
        toast.error("Произошла ошибка");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Произошла ошибка");
    }
  };

  wildberriesCancelOrder = async (OrderId: number): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "PATCH",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WILDBERRIES");
      const response = await fetch(
        `${endpoint}/CancelOrder?OrderID=${OrderId}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Заказ отменён");
      } else {
        toast.error("Произошла ошибка");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Произошла ошибка");
    }
  };

  getWildberriesStocks = async (id: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WILDBERRIES");
      const response = await fetch(
        `${endpoint}/GetWildberriesStocks?UserID=${id}`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        this.setWildberriesStocks(data);
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  ApproveWildberriesShipment = async (UserID: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WILDBERRIES");
      const response = await fetch(
        `${endpoint}/ApproveShipment/${UserID}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Акт отгрузки подтвержден");
      } else {
        toast.error("Повторите попытку позже");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Повторите попытку позже");
    }
  };
}

export default WildberriesStore;
