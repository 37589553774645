import {
  FC,
  ReactElement,
  useState,
  createContext,
  SetStateAction,
  Dispatch,
  useContext,
} from "react";
import { Container } from "react-bootstrap";
import Button from "components/Button";
import styles from "./Faq.module.scss";
import { Modal } from "../Faq/components/modal/modal";
import { AddFaqs } from "./components/addFaqs/addFaqs";
import { RootStoreContext } from "stores/RootStore";

type TypeContext = {
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
};

const cont = {
  reRender: false,
  setReRender: () => false,
};
export const Context = createContext<TypeContext>(cont);
const Faq: FC = (): ReactElement => {
  const {
    faqStore: { getFAQs, setFAQs, faqs, deleteFAQ, updateFaq },
    userStore: { info },
  } = useContext(RootStoreContext);
  const [open, setOpen] = useState(false);
  const [reRender, setReRender] = useState<boolean>(false);

  function handleClick(): void {
    setOpen(true);
  }
  return (
    <Context.Provider value={{ reRender, setReRender }}>
      <div className={styles.page}>
        <Container>
          <p className={styles.title}>часто задаваемые вопросы</p>
          <div className={styles.faqsBody}>
            <AddFaqs />
          </div>
          {info?.roles.includes("SuperAdmin") ? (
            <Button
              text="Добавить"
              color="primary"
              className={styles.addFaq}
              onClick={handleClick}
            />
          ) : (
            ""
          )}
        </Container>
        <Modal open={open} setOpen={setOpen} setReRender={setReRender} />
      </div>
    </Context.Provider>
  );
};

export default Faq;
