import { Tab, Tabs } from "react-bootstrap";
import React, { useContext } from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import PaymentBills from "./PaymentBills";
import Torg12 from "./Torg12";
import { RootStoreContext } from "stores/RootStore";

const InvoicesPage: React.FC = observer(() => {
  const {
    userStore: { info },
  } = useContext(RootStoreContext);
  return (
    <div className={styles.root}>
      <p className={styles.title}>Накладные</p>
      <div className={styles.wrapper}>
        <Tabs
          defaultActiveKey={
            info?.roles.includes("Seller")
              ? "torg12_other"
              : "torg12_Roomseller"
          }
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {info?.roles.includes("WarehouseOwner") ||
          info?.roles.includes("SuperAdmin") ? (
            <Tab eventKey="torg12_Roomseller" title="Торг 12 Roomseller">
              <Torg12 type={0} />
            </Tab>
          ) : (
            ""
          )}
          {info?.roles.includes("Seller") ||
          info?.roles.includes("SuperAdmin") ? (
            <Tab eventKey="torg12_other" title="Торг 12 другие">
              <Torg12 type={1} />
            </Tab>
          ) : (
            ""
          )}
          <Tab eventKey="paymentBills" title="Оплата счетов">
            <PaymentBills />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});
export default InvoicesPage;
