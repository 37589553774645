import React, { useContext, useEffect, useState } from "react";
import styles from "./Videos.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import CustomInput from "../../../components/CustomInput";
import Button from "../../../components/Button";
import { toast } from "react-hot-toast";
import { TfiVideoClapper } from "react-icons/tfi";
import { BsPlayFill } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import Modal from "components/Modal";
import { MdDelete, MdDriveFileRenameOutline } from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";

const Videos: React.FC = observer(() => {
  const {
    warehouseStore: {
      addVideosCategory,
      getVideos,
      videos,
      addVideo,
      getVideo,
      deleteVideo,
      deleteCategory,
    },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [addCategoryLoading, setAddCategoryLoading] = useState<boolean>(false);
  const [videosLoading, setVideosLoading] = useState<boolean>(false);
  const [addVideoLoading, setAddVideoLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<string>("");
  const [categoryId, setCategoryId] = useState<number>(0);
  const [video, setVideo] = useState<string>("");

  useEffect(() => {
    setVideosLoading(true);
    getVideos().then(() => {
      setVideosLoading(false);
    });
  }, [getVideos, setVideosLoading]);

  const addCategory = () => {
    if (category !== "") {
      setAddCategoryLoading(true);
      addVideosCategory(category).then((i) => {
        if (i) {
          setVideosLoading(true);
          getVideos().then(() => {
            setVideosLoading(false);
          });
          toast.success("Категория добавлена");
          setCategory("");
        } else {
          toast.error("Произошла ошибка");
        }
        setAddCategoryLoading(false);
      });
    }
  };

  const uploadVideo = (e: any) => {
    setAddVideoLoading(true);
    addVideo(e.target.files[0], categoryId).then(() => {
      setAddVideoLoading(false);
    });
  };

  const getVideoById = (videoId: number) => {
    getVideo(videoId).then((i) => {
      setVideo(i);
    });
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>Видео</p>
      <div className={styles.wrapper}>
        {info?.roles.includes("SuperAdmin") ? (
          <div className={styles.addCategory}>
            <CustomInput
              name={"category"}
              onChange={(e) => setCategory(e.target.value)}
              value={category}
            />
            <Button
              onClick={() => addCategory()}
              className={addCategoryLoading ? styles.loading : ""}
            >
              Добавить категорию
            </Button>
          </div>
        ) : (
          ""
        )}

        <div>
          {videosLoading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={videosLoading} size={50} />
            </div>
          ) : (
            videos &&
            videos.map((item) => {
              return (
                <div key={item.id}>
                  <div className={styles.categoryName}>
                    <span style={{ marginRight: "10px" }}>
                      {item.categoryName}
                    </span>
                    {info?.roles.includes("SuperAdmin") ? (
                      <>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              <span>Переименовать</span>
                            </Tooltip>
                          }
                        >
                          <span>
                            <MdDriveFileRenameOutline
                              size={24}
                              className={styles.playButton}
                            />
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              <span>Удалить</span>
                            </Tooltip>
                          }
                        >
                          <span onClick={() => deleteCategory(item.id)}>
                            <MdDelete size={24} className={styles.playButton} />
                          </span>
                        </OverlayTrigger>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex">
                    {item.videos.map((i) => (
                      <div className={styles.videoItem} key={i.id}>
                        <TfiVideoClapper size={24} />
                        <p>{i.originalName}</p>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              <span>Смотреть</span>
                            </Tooltip>
                          }
                        >
                          <div onClick={() => getVideoById(i.id)}>
                            <BsPlayFill
                              size={32}
                              className={styles.playButton}
                            />
                          </div>
                        </OverlayTrigger>
                        {info?.roles.includes("SuperAdmin") ? (
                          <>
                            {" "}
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  <span>Удалить</span>
                                </Tooltip>
                              }
                            >
                              <div onClick={() => deleteVideo(i.id)}>
                                <MdDelete
                                  size={28}
                                  className={styles.playButton}
                                />
                              </div>
                            </OverlayTrigger>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}

                    {info?.roles.includes("SuperAdmin") ? (
                      !addVideoLoading ? (
                        <label
                          htmlFor="file-input"
                          className={
                            addVideoLoading
                              ? styles.uploadButtonLoad
                              : styles.uploadButton
                          }
                          onClick={() => setCategoryId(item.id)}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                <span>Добавить видео</span>
                              </Tooltip>
                            }
                          >
                            <div>
                              <AiOutlineVideoCameraAdd
                                className={styles.addVideoItem}
                                size={32}
                              />
                            </div>
                          </OverlayTrigger>
                        </label>
                      ) : (
                        <BeatLoader
                          className={styles.addVideoItem}
                          color={"#f49100"}
                          loading={addVideoLoading}
                          size={32}
                        />
                      )
                    ) : (
                      ""
                    )}
                    <input
                      id="file-input"
                      type="file"
                      accept="*/*"
                      onChange={(e) => uploadVideo(e)}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              );
            })
          )}
          {video ? (
            <Modal
              className={styles.videoModal}
              children={
                <div>
                  <video
                    src={video}
                    controls
                    preload="auto"
                    autoPlay
                    muted
                    height="500px"
                  ></video>
                </div>
              }
              isOpen={video !== ""}
              onClose={() => setVideo("")}
              maxWidth="80%"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});
export default Videos;
