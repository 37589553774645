import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Collations.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import classNames from "classnames";
import CustomSelect, { SelectValue } from "components/CustomSelect";
import Pagination from "components/Pagination/Pagination";
import { components } from "react-select";
import Modal from "../../../components/Modal";
import CollatePopup from "./CollatePopup";
import Icon from "../../../components/Icon";
import CustomInput from "../../../components/CustomInput";
import { useSearchParams } from "react-router-dom";

const Collations: React.FC = observer(() => {
  const {
    warehouseStore: { getCollations, collations },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [isCollate, setIsCollate] = useState<boolean>(false);
  const [productId, setProductId] = useState<string>("");
  const [marketplaceId, setMarketplaceId] = useState<number>(0);
  const [supplierVendorCode, setSupplierVendorCode] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>("");

  const newParams = new URLSearchParams(searchParams);

  const pageSize = searchParams.get("pageSize");
  const pageNumber = searchParams.get("pageNumber");
  const searchText = searchParams.get("search");

  const filters = [{ label: "search", value: searchText }];

  useEffect(() => {
    setLoading(true);
    getCollations({
      ...(pageSize && { pageSize: Number(pageSize) }),
      ...(pageNumber && { pageNumber: Number(pageNumber) }),
      ...(searchText && { searchText: searchText }),
    }).then(() => {
      setLoading(false);
    });
  }, [getCollations, pageNumber, pageSize, searchText]);

  const handlePages = (updatePage: number) => {
    newParams.set("pageNumber", `${updatePage}`);
    setSearchParams(newParams);
  };

  const handleSearchFilter = () => {
    newParams.set("search", searchValue);
    setSearchParams(newParams);
  };

  const handleFilters = (e: SelectValue, name: string) => {
    newParams.set(name, e.value);
    setSearchParams(newParams);
  };

  const MenuList: React.FC<any> = (props) => {
    const { children, ...otherProps } = props;
    return (
      <components.MenuList {...otherProps}>{children}</components.MenuList>
    );
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>Сопоставление</p>
      <div className={styles.wrapper}>
        <div className={styles.search}>
          <CustomInput
            type="search"
            value={searchValue}
            placeholder="Поиск..."
            name={`search`}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (!e.target.value) {
                newParams.delete("search");
                setSearchParams(newParams);
              }
            }}
            onKeyPress={(event) =>
              event.key === "Enter" && handleSearchFilter()
            }
            className={styles.searchInput}
          />
        </div>
        <div className={styles.tags}>
          {filters.map(
            (item, index) =>
              item.value && (
                <div className={styles.tag} key={index}>
                  <div className={styles.label}>{item.value}</div>
                  <Icon
                    onClick={() => {
                      newParams.delete(item.label);
                      setSearchParams(newParams);
                    }}
                    variant="cross"
                    size="small"
                  />
                </div>
              )
          )}
          {filters.filter((i) => i.value).length ? (
            <a
              className={styles.reset}
              onClick={() => {
                setSearchValue("");
                setSearchParams({ pageNumber: "1", pageSize: "20" });
              }}
            >
              Сбросить все фильтры
            </a>
          ) : (
            ""
          )}
        </div>
        {loading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={loading} size={50} />
          </div>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Код поставщика</th>
                <th colSpan={4}>Наименование товар</th>
              </tr>
            </thead>
            <tbody>
              {collations &&
                collations.productCollations.map((item, index) => (
                  <tr key={index}>
                    <td>{item.supplierVendorCode}</td>
                    <td>
                      <a
                        href={`/warehouse/product/${item.productID}`}
                        target="_blank"
                        className={styles.cardTitle}
                      >
                        {item.productTitle}
                      </a>
                    </td>
                    <td className={styles.collationLinks}>
                      <div>
                        {item.ozonLink ? (
                          <a
                            href={item.ozonLink}
                            className={styles.collationA}
                            target="_blank"
                          >
                            Ozon
                          </a>
                        ) : (
                          <span className={styles.disabledLinks}>Ozon</span>
                        )}
                        <OverlayTrigger
                          overlay={<Tooltip>Сопоставить на Ozon</Tooltip>}
                        >
                          <div>
                            <Icon
                              variant="edit"
                              size="medium"
                              onClick={() => {
                                setIsCollate(true);
                                setProductId(item.productID);
                                setSupplierVendorCode(item.supplierVendorCode);
                                setMarketplaceId(1);
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </td>
                    <td className={styles.collationLinks}>
                      <div>
                        {item.wildberriesLink ? (
                          <a
                            href={item.wildberriesLink}
                            className={styles.collationA}
                            target="_blank"
                          >
                            Wildberries
                          </a>
                        ) : (
                          <span className={styles.disabledLinks}>
                            Wildberries
                          </span>
                        )}
                        <OverlayTrigger
                          overlay={
                            <Tooltip>Сопоставить на Wildberries</Tooltip>
                          }
                        >
                          <div>
                            <Icon
                              variant="edit"
                              size="medium"
                              onClick={() => {
                                setIsCollate(true);
                                setProductId(item.productID);
                                setSupplierVendorCode(item.supplierVendorCode);
                                setMarketplaceId(3);
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </td>
                    <td className={styles.collationLinks}>
                      <div>
                        {item.yandexMarketLink ? (
                          <a
                            href={item.yandexMarketLink}
                            className={styles.collationA}
                            target="_blank"
                          >
                            Yandex Market
                          </a>
                        ) : (
                          <span className={styles.disabledLinks}>
                            Yandex Market
                          </span>
                        )}
                        <OverlayTrigger
                          overlay={
                            <Tooltip>Сопоставить на Yandex Market</Tooltip>
                          }
                        >
                          <div>
                            <Icon
                              variant="edit"
                              size="medium"
                              onClick={() => {
                                setIsCollate(true);
                                setProductId(item.productID);
                                setSupplierVendorCode(item.supplierVendorCode);
                                setMarketplaceId(2);
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        {collations && collations.pageCount > 1 && (
          <div className={classNames(styles.pagination)}>
            <CustomSelect
              components={{ MenuList }}
              className={styles.small}
              name="pageSize"
              placeholder="qty"
              onChange={(e) => {
                handleFilters(e, "pageSize");
              }}
              value={pageSize}
              list={[
                { value: "20", label: "20" },
                { value: "40", label: "40" },
                { value: "60", label: "60" },
              ]}
            />
            <Pagination
              page={collations.pageNumber}
              totalPages={collations.pageCount}
              handlePagination={handlePages}
            />
          </div>
        )}
        {isCollate && (
          <Modal
            className={styles.cartModal}
            children={
              <CollatePopup
                productId={productId}
                marketplaceId={marketplaceId}
                supplierVendorCode={supplierVendorCode}
              />
            }
            isOpen={isCollate}
            onClose={() => {
              setIsCollate(false);
            }}
          />
        )}
      </div>
    </div>
  );
});
export default Collations;
