import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { FormikErrors, FormikValues, useFormik } from "formik";
import { ReactComponent as Wallpaper } from "assets/icons/login-wallpaper.svg";
import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect from "components/CustomSelect";
import { components } from "react-select";
import Icon from "components/Icon";

import styles from "./Register.module.scss";

const FirstStep: React.FC = observer(() => {
  const {
    registerStore: { setCountry },
  } = useContext(RootStoreContext);

  const navigate = useNavigate();
  const { values, setFieldValue, setSubmitting } = useFormik({
    initialValues: {
      country: "1",
    },
    validate: async () => {
      const errors: FormikErrors<FormikValues> = {};

      return errors;
    },
    onSubmit: async () => {
      setSubmitting(true);
    },
  });

  const Option = (props: any) => {
    const { isSelected, label } = props;
    return (
      <components.Option {...props}>
        <div className={styles.option}>
          <p className={styles.name}>{label}</p>
          {isSelected && (
            <div className={styles.tick}>
              <Icon variant="tick" size="medium" />
            </div>
          )}
        </div>
      </components.Option>
    );
  };
  return (
    <div className={styles.root}>
      <Wallpaper className={styles.wallpaper} />
      <div className={styles.content}>
        <div className={styles.window}>
          <p className={styles.title}>Выберите страну</p>
          <p className={styles.subtitle}>
            Укажите, откуда производите торговлю
          </p>

          <div className={styles.select}>
            <Icon className={styles.icon} variant="search" size="medium" />
            <CustomSelect
              components={{ Option }}
              placeholder="Поиск"
              menuIsOpen
              name="country"
              onChange={(e: any) => {
                setFieldValue("country", e.value);
                setCountry(e.label);
              }}
              value={values.country}
              list={[
                { label: "russia", value: "1" },
                { label: "russia", value: "2" },
                { label: "russia", value: "3" },
                { label: "russia", value: "4" },
                { label: "russia", value: "5" },
                { label: "russia", value: "6" },
                { label: "russia", value: "7" },
                { label: "russia", value: "8" },
                { label: "russia", value: "9" },
                { label: "russia", value: "10" },
                { label: "russia", value: "11" },
                { label: "russia", value: "12" },
                { label: "russia", value: "13" },
                { label: "russia", value: "14" },
                { label: "russia", value: "15" },
                { label: "russia", value: "16" },
              ]}
            />
          </div>

          <Button
            text="Назад"
            fullWidth
            onClick={() => navigate("/login")}
            color="secondary"
            className={styles.back}
          />
          <Button
            text="Далее"
            fullWidth
            onClick={() => navigate("/register/main")}
            color="primary"
          />
        </div>
        <div className={styles.options}>
          <Link to="/">На главную</Link>
          {/* <Link to="/">Помощь</Link>
          <div className={styles.divider} />
          <Link to="/">Политика конфиденциальности</Link> */}
        </div>
      </div>
    </div>
  );
});

export default FirstStep;
