import { Table } from "react-bootstrap";
import React from "react";
import styles from "./Invoices.module.scss";
import { TorgTablePart } from "stores/OrderStore";

interface Props {
  torgTablePart: TorgTablePart[];
  totalVat: number;
  totalPrice: number;
}

const TorgTablePartPopup: React.FC<Props> = ({
  torgTablePart,
  totalPrice,
  totalVat,
}) => {
  return (
    <div className={styles.TorgTablePartPopup}>
      <Table striped hover>
        <thead>
          <tr>
            <th>Название </th>
            <th>ID заказа</th>
            <th>Количество </th>
            <th>Код поставщика</th>
            <th>Вес брутто</th>
            <th>Цена доставки и упаковки</th>
            <th>Цена</th>
            <th>НДС</th>
          </tr>
        </thead>
        <tbody>
          {torgTablePart.map((i, index) => (
            <tr key={index + 1}>
              <td>
                <a
                  href={`/warehouse/product/${i.productID}`}
                  target="_blank"
                  className={styles.cardTitle}
                >
                  {i.productTitle}
                </a>
              </td>
              <td>{i.orderID}</td>
              <td>{i.productAmount} шт.</td>
              <td>{i.vendorCode}</td>
              <td>{i.bruttoWeight}</td>
              <td>{i.productPackingPrice} ₽</td>
              <td>{i.productPrice} ₽</td>
              <td>{i.productVat} ₽</td>
            </tr>
          ))}
          <tr>
            <td>
              <b>Итого:</b>
            </td>
            <td colSpan={5}></td>
            <td>{totalPrice} ₽</td>
            <td>{totalVat} ₽</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
export default TorgTablePartPopup;
