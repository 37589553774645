import { Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Cart.module.scss";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import CustomSelect from "components/CustomSelect";
import { BiImageAdd } from "react-icons/bi";
import { RootStoreContext } from "stores/RootStore";

interface Props {
  pageName: string;
  id: number;
  operationType: string;
}

const CommentPopup: React.FC<Props> = observer(
  ({ id, pageName, operationType }) => {
    const {
      orderStore: {
        addCommentOrder,
        editCommentOrder,
        getViewReturnOrder,
        setCarts,
        carts,
        setRequestedOrders,
        requestedOrders,
      },
      userStore: { info },
    } = useContext(RootStoreContext);

    const [status, setStatus] = useState<string>("0");
    const [comment, setComment] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [image, setImage] = useState<any | null>("");

    const [type, setType] = useState<string>(operationType);

    const [loadComment, setLoadComment] = useState<boolean>(false);

    useEffect(() => {
      if (type !== "Create") {
        getViewReturnOrder(id).then((viewComment) => {
          if (viewComment) {
            setStatus(viewComment.status.toString());
            setComment(viewComment.commentOnReturn);
            setImageUrl(viewComment.imageID);
          }
        });
      }
    }, [getViewReturnOrder, id, type]);

    const onChange = (e: any) => {
      setImage(e.target.files[0]);
    };

    const addComment = () => {
      setLoadComment(true);
      addCommentOrder(id, status, comment, image).then((i) => {
        if (i) {
          if (pageName === "cartPage") {
            setCarts({
              ...carts,
              orders: carts?.orders.map((order) => {
                if (order.id === id) {
                  return {
                    ...order,
                    returnRequest: status === "0" || status === "1" ? 4 : 2,
                  };
                } else return order;
              }),
            });
          } else if (pageName === "homePage" && requestedOrders) {
            setRequestedOrders(
              requestedOrders?.map((order) => {
                if (order.id === id) {
                  return {
                    ...order,
                    returnRequest: status === "0" || status === "1" ? 4 : 2,
                  };
                } else return order;
              })
            );
          }
          if (Number(status) === 0 || Number(status) === 1) {
            setType("View");
          } else {
            setType("Edit");
          }
        }

        setLoadComment(false);
      });
    };

    const editComment = () => {
      setLoadComment(true);
      editCommentOrder(id, status, comment, image).then((i) => {
        if (i) {
          if (pageName === "cartPage") {
            setCarts({
              ...carts,
              orders: carts?.orders.map((order) => {
                if (order.id === id) {
                  return {
                    ...order,
                    returnRequest: status === "0" || status === "1" ? 4 : 2,
                  };
                } else return order;
              }),
            });
          } else if (pageName === "homePage" && requestedOrders) {
            setRequestedOrders(
              requestedOrders?.map((order) => {
                if (order.id === id) {
                  return {
                    ...order,
                    returnRequest: status === "0" || status === "1" ? 4 : 2,
                  };
                } else return order;
              })
            );
          }
          if (Number(status) === 0 || Number(status) === 1) {
            setType("View");
          } else {
            setType("Edit");
          }
        }

        setLoadComment(false);
      });
    };
    return (
      <Row className={styles.cartModalRoot}>
        <div className={styles.commentPopup}>
          <div>
            <CustomSelect
              className={styles.marketplaceInput}
              name="status"
              placeholder={"Статус"}
              isDisabled={type === "View"}
              onChange={(e) => {
                setStatus(e.value);
              }}
              value={status}
              list={[
                { label: "Принять", value: "0" },
                { label: "Отказать", value: "1" },
                { label: "Не поступил", value: "2" },
              ]}
            />
          </div>
          <div className={styles.uploadCommentImage}>
            {imageUrl && !image ? (
              <img src={imageUrl} style={{ width: "100%" }} />
            ) : (
              ""
            )}
            {image && type !== "View" ? (
              <img src={URL.createObjectURL(image)} style={{ width: "100%" }} />
            ) : (
              ""
            )}
            {type !== "View" ? (
              <label htmlFor="file-input">
                <BiImageAdd size={32} />
              </label>
            ) : (
              ""
            )}

            <input
              id="file-input"
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              type="file"
              onChange={(e) => onChange(e)}
              style={{ display: "none" }}
            />
          </div>
          <div>
            <textarea
              name="comment"
              placeholder="Комментарий..."
              disabled={type === "View"}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
            />
          </div>
          {info?.roles.includes("WarehouseOwner") ? (
            <Button
              text={type === "Create" ? "Комментировать" : "Редактировать"}
              color="primary"
              className={
                loadComment ? styles.commentButtonLoad : styles.commentButton
              }
              disabled={loadComment || type === "View"}
              onClick={() => (type === "Create" ? addComment() : editComment())}
            />
          ) : (
            ""
          )}
        </div>
      </Row>
    );
  }
);
export default CommentPopup;
