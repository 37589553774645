import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import {
  clearPersistedStore,
  makePersistable,
  stopPersisting,
} from "mobx-persist-store";
import { RootStore } from "./RootStore";
import { toast } from "react-hot-toast";
import moment from "moment";
import { UserInfo } from "os";

export interface ProfileInfo {
  firstName: string;
  lastName: string;
  middleName: string;
  token: string;
  fullName: string;
  id: string;
  roles: string[];
  userName: string;
  normalizedUserName: string;
  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;
  passwordHash: string;
  securityStamp: string;
  concurrencyStamp: string;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  lockoutEnd: string;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  agreementDate?: Date;
  roomsellerConditionsConsent?: string;
}

export interface InfoUser {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  mpWarehouseID: number;
  agreementDate?: string;
  roomsellerConditionsConsent?: string;
  roles: string[];
  documentUrls: string[];
  tin: string;
  msrnie: string;
  passportSeries: string;
  passportNumber: string;
  passportIssueDate: string;
  issuedBy: string;
  bankName: string;
  bik: string;
  correspAccount: string;
  personalAccount: string;
  departmentCode: string;
  actualAddress: string;
  registrationAddress: string;
}

export interface BankInfo {
  bankName: string;
  correspAccount: string;
  bik: string;
  personalAccount: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  email: string;
  userStatus: number;
  phoneNumber: string | null;
  role: string | null;
  marketPlaces: {
    id: number;
    marketPlaceName: string;
    marketPlaceStocks: {
      stockId: number;
      stockName: string;
      stockState: boolean;
    }[];
  }[];
}

class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    makePersistable(this, {
      name: "user",
      properties: ["token", "info"],
      storage: window.localStorage,
    });
  }

  stopStore(): void {
    stopPersisting(this);
  }

  token: string | null = null;

  userDocs: [string, string][] | null = null;

  info: ProfileInfo | null = null;

  userInfo: InfoUser | null = null;

  userPopupInfo: InfoUser | null = null;

  allUsers: User[] | null = null;

  conditionLink: string | null = null;

  agreementSellerLink: string | null = null;

  agreementOwnerLink: string | null = null;

  termsOfUsageLink: string | null = null;

  bankInfo: BankInfo | null = null;

  get isAuthorized(): boolean {
    return !!this.token;
  }

  clearStore = async (): Promise<void> => {
    await clearPersistedStore(this);
    this.setToken(null);
  };

  setToken = (token: string | null): void => {
    this.token = token;
  };

  setInfo = (info: ProfileInfo): void => {
    this.info = info;
  };

  setUserInfo = (userInfo: InfoUser): void => {
    this.userInfo = userInfo;
  };

  setUserPopupInfo = (userPopupInfo: InfoUser): void => {
    this.userPopupInfo = userPopupInfo;
  };

  setUserDocs = (data: [string, string][]): void => {
    this.userDocs = data;
  };

  setAllUsers = (allUsers: User[]): void => {
    this.allUsers = allUsers;
  };

  setTermsOfUsageLink = (guideLink: string | null): void => {
    this.termsOfUsageLink = guideLink;
  };

  setAgreementSellerLink = (agreementSellerLink: string | null): void => {
    this.agreementSellerLink = agreementSellerLink;
  };

  setAgreementOwnerLink = (agreementOwnerLink: string | null): void => {
    this.agreementOwnerLink = agreementOwnerLink;
  };

  setConditionLink = (conditionLink: string | null): void => {
    this.conditionLink = conditionLink;
  };

  getUserInfo = async (id: string): Promise<InfoUser | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };
    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/GetUserInfo?id=${id}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      this.setUserInfo(data);
      this.setUserDocs(Object.entries(data.documentUrls));
    }
    return data;
  };

  changeUserInfo = async (
    id: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    tin: string,
    msrnie: string,
    passportSeries: string,
    passportNumber: string,
    issuedBy: string,
    passportIssueDate: string,
    departmentCode: string,
    registrationAddress: string,
    actualAddress: string
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        id,
        firstName,
        lastName,
        phoneNumber,
        tin,
        msrnie,
        passportSeries,
        passportNumber,
        issuedBy,
        passportIssueDate,
        departmentCode,
        registrationAddress,
        actualAddress,
      }),
    };
    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(`${endpoint}`, requestOptions);
    if (response.ok) {
      toast.success("Данные изменены");
      return true;
    } else {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  getAllUsers = async (): Promise<User[] | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(`${endpoint}/GetAllUsers`, requestOptions);

    const data = await response.json();

    if (response.ok) {
      this.setAllUsers(data);
    }
    return data;
  };

  getSuspendUser = async (id: string): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/SuspendUser/${id}`,
      requestOptions
    );

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  };

  getReactivateUser = async (id: string): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/ReactivateUser/${id}`,
      requestOptions
    );

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  };

  setStockState = async (
    stockId: number,
    stockState: boolean
  ): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/SetStockState?StockID=${stockId}&Enable=${stockState}`,
      requestOptions
    );

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  };

  getTermsOfUsage = async () => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(`${endpoint}/GetTermsOfUsage`, requestOptions);

    const data = await response.text();

    if (response.ok) {
      this.setTermsOfUsageLink(data);
    } else {
      return null;
    }
  };

  uploadUserDocument = async (file: any): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("document", file);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/UploadDocument`,
        requestOptions
      );

      const data = await response.text();

      if (response.ok) {
        toast.success("Документ загружен");
        return data;
      } else {
        toast.error("Произошла ошибка");
        return "";
      }
    } catch (error) {
      toast.error("Произошла ошибка 1");
      return "";
    }
  };

  addTermsOfUsage = async (file: any): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/AddTermsOfUsage`,
        requestOptions
      );
      if (response.ok) {
        toast.success("Документ загружен");
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  getBankInfo = async (id: string): Promise<BankInfo | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/GetBankInfo?id=${id}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      return null;
    }
  };

  changeBankInfo = async (info: BankInfo): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify(info),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(`${endpoint}/BankAccount`, requestOptions);

    if (response.ok) {
      toast.success("Данные изменены");
      return true;
    } else {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  addAgreement = async (file: any, role: string, docVersion: string) => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/AddAgreement?ForRole=${role}&DocVersion=${docVersion}`,
        requestOptions
      );
      if (response.ok) {
        this.resetAgreement(moment().format("YYYY-MM-DD"), role);
        toast.success("Договор загружен");
      } else {
        toast.error("Произошла ошибка");
      }
    } catch (error) {
      toast.error("Произошла ошибка");
    }
  };

  getAgreement = async (role: string) => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/GetAgreement?ForRole=${role}`,
      requestOptions
    );
    const blob = await response.blob();

    if (response.ok && blob) {
      if (role === "Seller") {
        this.setAgreementSellerLink(window.URL.createObjectURL(blob));
      } else {
        this.setAgreementOwnerLink(window.URL.createObjectURL(blob));
      }
    } else {
      return null;
    }
  };

  resetAgreement = async (date: string, ForRole: string): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/ResetAgreement?ResetDate=${date}&ForRole=${ForRole}`,
      requestOptions
    );

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  };

  resetTermsCondition = async (date: string): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/ResetTermsCondition?ResetDate=${date}`,
      requestOptions
    );

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  };

  addTermsOfCondition = async (file: any, docVersion: string) => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/AddTermsCondition?DocVersion=${docVersion}`,
        requestOptions
      );
      if (response.ok) {
        this.resetTermsCondition(moment().format("YYYY-MM-DD"));
        toast.success("Регламент загружен");
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  getTermsCondition = async () => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/GetTermsCondition`,
      requestOptions
    );
    const blob = await response.blob();

    if (response.ok && blob) {
      this.setConditionLink(window.URL.createObjectURL(blob));
    } else {
      return null;
    }
  };

  deleteUserDoc = async (docId: string, userID: string): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "DELETE",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/DeleteDocument?documentId=${docId}&userID=${userID}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  signAgreement = async (userId: string): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/SignAgreement?UserID=${userId}`,
      requestOptions
    );

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  };

  signTermsOfUsage = async (userId: string): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/SignTermsOfUsage?UserID=${userId}`,
      requestOptions
    );

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  };

  getUserPopupInfo = async (id: string): Promise<InfoUser | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };
    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/GetUserInfo?id=${id}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      this.setUserPopupInfo(data);
    }
    return data;
  };

  getAgreementLatest = async (
    role: string
  ): Promise<{ item1: string; item2: string } | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/GetAgreementLatest?ForRole=${role}`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  getAgreementUserSignedVersion = async (): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/GetAgreementUserSignedVersion`,
        requestOptions
      );
      const data = await response.text();
      if (response.ok) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  getTermsConditionsLatestVersion = async (): Promise<{
    item1: string;
    item2: string;
  } | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/GetTermsConditionsLatestVersion`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  getTermsConditionsUserSignedVersion = async (): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/GetTermsConditionsUserSignedVersion`,
        requestOptions
      );
      const data = await response.text();
      if (response.ok) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  SaveTermsOfUsageAsFile = async (
    data: string,
    fileName: string
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;
    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data),
    };
    const endpoint = handleApiPath("REACT_APP_API_REGISTER");

    try {
      console.log(data);
      const response = await fetch(
        `${endpoint}/SaveTermsOfUsageAsFile?FileName=${fileName}`,
        requestOptions
      );
      //const datas = await response.json();
      console.log(data);
      if (response.ok) {
        return true;
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
    return false;
  };

  getTermsUsageAsString = async (): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };
    const endpoint = handleApiPath("REACT_APP_API_REGISTER");
    const response = await fetch(
      `${endpoint}/GetTermsOfUsageAsString`,
      requestOptions
    );
    const data = response.text();
    if (response.ok) {
      return data;
    }
    return null;
  };
}

export default UserStore;
