import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ProductItem.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { Row } from "react-bootstrap";
import Modal from "components/Modal";
import NotFoundPage from "../NotFoundPage";
import ProductItemTables from "./ProductItemTables";
import BeatLoader from "react-spinners/BeatLoader";

const ProductItem: React.FC = observer(() => {
  const {
    warehouseStore: { product, getProduct, getChildrenCategoryIDs, setProduct },
    userStore: { info },
  } = useContext(RootStoreContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [zoomImages, setZoomImages] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setProduct(null);
    setLoading(true);
    getProduct(location.pathname.split("/")[3]).then(() => {
      setLoading(false);
    });
  }, [
    info,
    navigate,
    location,
    getProduct,
    getChildrenCategoryIDs,
    setProduct,
  ]);
  return (
    <div className={styles.root}>
      <div>
        <Modal
          className={styles.zoomImageModal}
          children={
            <img
              src={zoomImages}
              onClick={() => setZoomImages("")}
              style={{ cursor: "zoom-out" }}
            />
          }
          isOpen={zoomImages !== ""}
          onClose={() => setZoomImages("")}
          maxWidth="80%"
        />
        {loading ? (
          <Row className={styles.wrapper}>
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={loading} size={50} />
            </div>
          </Row>
        ) : product ? (
          <ProductItemTables product={product} />
        ) : (
          <NotFoundPage />
        )}
      </div>
    </div>
  );
});
export default ProductItem;
