import { Row, Spinner, Table } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../../stores/RootStore";
import styles from "./Cart.module.scss";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import moment from "moment";

interface Props {
  id: number;
}

const CartPopup: React.FC<Props> = observer(({ id }) => {
  const {
    orderStore: { cart, getOrder },
  } = useContext(RootStoreContext);

  useEffect(() => {
    getOrder(id);
  }, [getOrder, id]);

  return (
    <Row className={styles.cartModalRoot}>
      <Table striped hover responsive className={styles.cartModalTable}>
        <tbody>
          <tr>
            <td>
              <b>Продавец</b>
            </td>
            <td>
              {cart ? (
                cart.seller
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Товар</b>
            </td>
            <td>
              {cart ? (
                <p
                  className={styles.cardTitle}
                  onClick={() =>
                    window.open(
                      `/warehouse/product/${cart.productID}`,
                      "_blank"
                    )
                  }
                >
                  {cart.productTitle}
                </p>
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Маркетплейс</b>
            </td>
            <td>
              {cart ? (
                cart.marketPlaceName
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Номер заказа на маркетплейсе</b>
            </td>
            <td>
              {cart ? (
                cart.postingNumber
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          {/*<tr>
            <td>
              <b>Адрес</b>
            </td>
            <td>
              {cart ? (
                cart.address
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
              </tr>*/}
          <tr>
            <td>
              <b>Статус</b>
            </td>
            <td>
              {cart ? (
                cart.status
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Тип доставки</b>
            </td>
            <td>
              {cart ? (
                cart.deliveryType
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Название Склада</b>
            </td>
            <td>
              {cart ? (
                cart.warehouseName
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Дата заказа</b>
            </td>
            <td>
              {cart ? (
                moment(cart.orderDate).format("DD.MM.YYYY")
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Дата отгрузки</b>
            </td>
            <td>
              {cart ? (
                cart.shipmentDate ? (
                  moment(cart.shipmentDate).format("DD.MM.YYYY")
                ) : (
                  ""
                )
              ) : (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{ marginLeft: 10 }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className={styles.payButtonDiv}>
        <Button text="Оплатить" color="primary" className={styles.payButton} />
      </div>
    </Row>
  );
});
export default CartPopup;
