import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { useFormik } from "formik";
import { ReactComponent as Wallpaper } from "assets/icons/login-wallpaper.svg";
import CustomInput from "components/CustomInput";
import Button from "components/Button";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import LogoLight from "../../Home/HomePageImages/LogoLight.png";

import styles from "./Register.module.scss";
import Checkbox from "../../../components/Checkbox";

const registerSchema = yup.object().shape({
  name: yup.string().required("Поле обязательно для заполнения"),
  surname: yup.string().required("Поле обязательно для заполнения"),
  middleName: yup.string(),
  email: yup
    .string()
    .email("Вы ввели некорректный Email")
    .required("Поле обязательно для заполнения"),
  password: yup
    .string()
    .required("Поле обязательно для заполнения")
    .min(6, "Минимальная длина в 6 символов")
    .matches(/^(?=.*[a-z])/, "Должен содержать строчную букву")
    .matches(/^(?=.*[A-Z])/, "Должен содержать прописную букву")
    .matches(/^(?=.*[0-9])/, "Должен содержать число")
    .matches(
      /^(?=.*[!@#$%^&*()—_+=;:,./?|`~{}])/,
      "Должен содержать специальный символ"
    ),
  phoneNumber: yup
    .string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Номер телефона недействителен")
    .required("Поле обязательно для заполнения"),
  confirmPassword: yup
    .string()
    .required("Поле обязательно для заполнения")
    .oneOf([yup.ref("password")], "Неверный пароль"),
  tin: yup
    .string()
    .required("Поле обязательно для заполнения")
    .matches(/^[0-9]+$/, "поле должно содержать только цифры"),
  msrnie: yup
    .string()
    .required("Поле обязательно для заполнения")
    .matches(/^[0-9]+$/, "поле должно содержать только цифры"),
  passportSeries: yup.string().required(" "),
  passportNumber: yup.string().required("Поле обязательно для заполнения"),
  issuedBy: yup.string().required("Поле обязательно для заполнения"),
  passportIssueDate: yup.string().required("Поле обязательно для заполнения"),
  departmentCode: yup.string().required("Поле обязательно для заполнения"),
  registrationAddress: yup.string().required("Поле обязательно для заполнения"),
  actualAddress: yup.string().required("Поле обязательно для заполнения"),
  birthPlace: yup.string().required("Поле обязательно для заполнения"),
  birthDate: yup.string().required(" "),
});

const Register: React.FC = observer(() => {
  const {
    registerStore: { register },
  } = useContext(RootStoreContext);
  const [isSubmitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataAccuracyConfirmed, setDataAccuracyConfirmed] =
    useState<boolean>(true);
  const [personalDataProcecessingConsent, setPersonalDataProcecessingConsent] =
    useState<boolean>(true);
  const [roomsellerConditionsConsent, setRoomsellerConditionsConsent] =
    useState<boolean>(true);

  const [isBDDate, setIsBDDate] = useState<boolean>(false);
  const [isPDate, setIsPDate] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      surname: "",
      middleName: "",
      email: "",
      password: "",
      phoneNumber: "",
      confirmPassword: "",
      tin: "",
      msrnie: "",
      passportSeries: "",
      passportNumber: "",
      issuedBy: "",
      passportIssueDate: "",
      departmentCode: "",
      registrationAddress: "",
      actualAddress: "",
      birthPlace: "",
      birthDate: "",
    },
    validationSchema: registerSchema,
    onSubmit: async () => {
      setSubmitting(true);
      setLoading(true);
      register(
        values.name,
        values.surname,
        values.email,
        values.password,
        values.phoneNumber,
        values.tin,
        values.msrnie,
        values.passportSeries,
        values.passportNumber,
        values.issuedBy,
        values.passportIssueDate,
        values.departmentCode,
        values.registrationAddress,
        values.actualAddress,
        "Seller",
        values.middleName,
        values.birthPlace,
        values.birthDate,
        dataAccuracyConfirmed,
        personalDataProcecessingConsent,
        roomsellerConditionsConsent
      ).then((r) => {
        if (r) {
          toast.error(r);
          setLoading(false);
        } else {
          if (
            confirm(
              "Вам пришло письмо по указанному адресу. Для завершения регистрации откройте письмо и перейдите по ссылке."
            )
          ) {
            navigate("/");
          }
          setLoading(false);
        }
      });
    },
  });

  return (
    <div className={styles.root}>
      <Wallpaper className={styles.wallpaper} />
      <div className={styles.content}>
        {!isSubmitted ? (
          <div className={styles.window}>
            <div className={styles.welcomeTitle}>
              <Link to="/">
                <img src={LogoLight} alt="Logo" className={styles.Logo} />
              </Link>
            </div>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <p className={styles.title}>Персональные данные</p>
                <CustomInput
                  value={values.name}
                  error={touched.name ? errors.name : ""}
                  placeholder="Имя"
                  name="name"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  clearable={() => {
                    setFieldValue("name", "");
                  }}
                />
                <CustomInput
                  value={values.surname}
                  error={touched.surname ? errors.surname : ""}
                  placeholder="Фамилия"
                  name="surname"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  clearable={() => {
                    setFieldValue("surname", "");
                  }}
                />
                <CustomInput
                  value={values.middleName}
                  error={touched.middleName ? errors.middleName : ""}
                  placeholder="Отчество (при наличии)"
                  name="middleName"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  clearable={() => {
                    setFieldValue("middleName", "");
                  }}
                />
                <Row>
                  <Col xl={8} lg={7} md={7} sm={8} xs={8}>
                    <CustomInput
                      value={values.birthPlace}
                      error={touched.birthPlace ? errors.birthPlace : ""}
                      placeholder="Место рождения"
                      name="birthPlace"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      clearable={() => {
                        setFieldValue("birthPlace", "");
                      }}
                    />
                  </Col>
                  <Col xl={4} lg={5} md={5} sm={4} xs={4}>
                    <CustomInput
                      value={values.birthDate}
                      error={touched.birthDate ? errors.birthDate : ""}
                      placeholder="Дата рождения"
                      name="birthDate"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      type={isBDDate ? "date" : "text"}
                      onFocus={() => setIsBDDate(true)}
                    />
                  </Col>
                </Row>
                <CustomInput
                  value={values.phoneNumber}
                  error={touched.phoneNumber ? errors.phoneNumber : ""}
                  placeholder="Номер телефона"
                  name="phoneNumber"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  clearable={() => {
                    setFieldValue("phoneNumber", "");
                  }}
                />
                <CustomInput
                  value={values.email}
                  error={touched.email ? errors.email : ""}
                  placeholder="E-mail"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  clearable={() => {
                    setFieldValue("email", "");
                  }}
                />
                <CustomInput
                  value={values.tin}
                  error={touched.tin ? errors.tin : ""}
                  placeholder="ИНН"
                  name="tin"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="text"
                  clearable={() => {
                    setFieldValue("tin", "");
                  }}
                />
                <CustomInput
                  value={values.password}
                  placeholder="Пароль"
                  name="password"
                  error={touched.password ? errors.password : ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="password"
                />
                <CustomInput
                  value={values.confirmPassword}
                  placeholder="Подтвердить пароль"
                  name="confirmPassword"
                  error={touched.confirmPassword ? errors.confirmPassword : ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="password"
                />
                <CustomInput
                  value={values.msrnie}
                  placeholder="ОГРНИП"
                  name="msrnie"
                  error={touched.msrnie ? errors.msrnie : ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="text"
                  clearable={() => {
                    setFieldValue("msrnie", "");
                  }}
                />
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <p className={styles.title}>Паспортные данные</p>
                <Row>
                  <Col xl={4} lg={5} md={5} sm={4} xs={4}>
                    <CustomInput
                      value={values.passportSeries}
                      placeholder="Серия"
                      name="passportSeries"
                      error={
                        touched.passportSeries ? errors.passportSeries : ""
                      }
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      type="text"
                      clearable={() => {
                        setFieldValue("passportSeries", "");
                      }}
                    />
                  </Col>
                  <Col xl={8} lg={7} md={7} sm={8} xs={8}>
                    <CustomInput
                      value={values.passportNumber}
                      placeholder="Номер"
                      name="passportNumber"
                      error={
                        touched.passportNumber ? errors.passportNumber : ""
                      }
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      type="text"
                      clearable={() => {
                        setFieldValue("passportNumber", "");
                      }}
                    />
                  </Col>
                </Row>
                <CustomInput
                  value={values.issuedBy}
                  placeholder="Кем выдан"
                  name="issuedBy"
                  error={touched.issuedBy ? errors.issuedBy : ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="text"
                  clearable={() => {
                    setFieldValue("issuedBy", "");
                  }}
                />
                <CustomInput
                  value={values.passportIssueDate}
                  placeholder="Дата выдачи"
                  name="passportIssueDate"
                  error={
                    touched.passportIssueDate ? errors.passportIssueDate : ""
                  }
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  clearable={() => {
                    setFieldValue("passportIssueDate", "");
                  }}
                  type={isPDate ? "date" : "text"}
                  onFocus={() => setIsPDate(true)}
                />
                <CustomInput
                  value={values.departmentCode}
                  placeholder="Код подразделения"
                  name="departmentCode"
                  error={touched.departmentCode ? errors.departmentCode : ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="text"
                  clearable={() => {
                    setFieldValue("departmentCode", "");
                  }}
                />
                <CustomInput
                  value={values.registrationAddress}
                  placeholder="Адрес регистрации"
                  name="registrationAddress"
                  error={
                    touched.registrationAddress
                      ? errors.registrationAddress
                      : ""
                  }
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="text"
                  clearable={() => {
                    setFieldValue("registrationAddress", "");
                  }}
                />
                <CustomInput
                  value={values.actualAddress}
                  placeholder="Фактический адрес"
                  name="actualAddress"
                  error={touched.actualAddress ? errors.actualAddress : ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  type="text"
                  clearable={() => {
                    setFieldValue("actualAddress", "");
                  }}
                />
                {/*<Checkbox
                  checked={dataAccuracyConfirmed}
                  onChange={() => {
                    setDataAccuracyConfirmed(!dataAccuracyConfirmed);
                  }}
                  className={styles.normalCheckbox}
                  label={"Даю согласие на обработку персональных данных"}
                />
                <Checkbox
                  checked={personalDataProcecessingConsent}
                  onChange={() => {
                    setPersonalDataProcecessingConsent(
                      !personalDataProcecessingConsent
                    );
                  }}
                  className={styles.normalCheckbox}
                  label={"Ознакомлен с инструкциями, рекомендациями"}
                />
                <Checkbox
                  checked={roomsellerConditionsConsent}
                  onChange={() => {
                    setRoomsellerConditionsConsent(
                      !roomsellerConditionsConsent
                    );
                  }}
                  className={styles.normalCheckbox}
                  label={"И согласен с правилами работы на платформе румселлер"}
                /> */}
              </Col>
            </Row>
            <Button
              text={loading ? "" : "Зарегистрироваться"}
              children={
                loading ? <Spinner animation="grow" variant="light" /> : ""
              }
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
              disabled={
                !dataAccuracyConfirmed ||
                !personalDataProcecessingConsent ||
                !roomsellerConditionsConsent
              }
              color="primary"
              className={styles.back}
            />
            <Button
              text="Назад"
              fullWidth
              onClick={() => navigate("/login")}
              color="secondary"
            />
          </div>
        ) : (
          <div className={styles.window}>
            <p className={styles.subtitle}>
              Пожалуйста подтвердите адрес электронной почты
            </p>
            <Button
              text="Ок"
              fullWidth
              onClick={() => navigate("/login")}
              color="primary"
              className={styles.back}
            />
          </div>
        )}
        <div className={styles.options}>
          <Link to="/">На главную</Link>
          {/* <Link to="/">Помощь</Link>
          <div className={styles.divider} />
          <Link to="/">Политика конфиденциальности</Link> */}
        </div>
      </div>
    </div>
  );
});
export default Register;
