import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { RootStoreContext } from "stores/RootStore";
import { QuestionsChat } from "./../Home";
import s from "./ModalChat.module.scss";
import ReplySection from "./../ReplySection/ReplySection";
import DeleteModal from "components/DeleteModal";
interface Props {
  questions: QuestionsChat[];
  setQuestions: React.Dispatch<React.SetStateAction<any>>;
}
const ModalChat: React.FC<Props> = observer(({ questions, setQuestions }) => {
  const {
    warehouseStore: { deleteProductChat },
  } = useContext(RootStoreContext);
  const [isOpenReply, setIsOpenReply] = useState<boolean>(false);
  const [indexOpen, setIndexOpen] = useState<number | null>(null);
  const [deleteID, setDeleteId] = useState<number>(0);
  const [answerID, setanswerId] = useState<number>(0);
  const [openDeleteModal, setopenDeleteModal] = useState<boolean>(false);

  const deleteChat = (id: number) => {
    if (questions) {
      deleteProductChat(id).then((i) => {
        if (i) {
          setQuestions(questions.filter((i) => i.id !== id));
        }
        setopenDeleteModal(false);
        setIsOpenReply(false);
      });
    }
  };
  function closeDelete(): void {
    setopenDeleteModal(false);
  }
  return (
    <div className={s.questionsScroll}>
      {questions.map((i, index) => {
        return (
          <>
            {" "}
            <div className={s.questionsContainer}>
              <div className={s.questionsBox}>
                <p className={s.questionsText} key={i.id}>
                  {i.question}
                </p>
                <div className={s.questionsContainerBtns}>
                  <p
                    className={s.questionBtnView}
                    onClick={() => {
                      window.open(
                        `/warehouse/product/${i.productID}`,
                        "_blank"
                      );
                    }}
                  >
                    Смотреть Продукт
                  </p>
                  <p
                    className={s.questionBtnDelete}
                    onClick={() => {
                      setopenDeleteModal(true);
                      setDeleteId(i.id);
                    }}
                  >
                    Удалить
                  </p>
                  <p
                    className={s.questionBtnReply}
                    onClick={() => {
                      setIsOpenReply(true);
                      setIndexOpen(index);
                      setanswerId(i.id);
                    }}
                  >
                    Ответить
                  </p>
                </div>
              </div>
            </div>
            {index == indexOpen && isOpenReply && (
              <ReplySection
                setIsOpenReply={setIsOpenReply}
                answerID={answerID}
                questions={questions}
                setQuestions={setQuestions}
              />
            )}
            {openDeleteModal && (
              <DeleteModal
                text={"Вы уверены, что хотите удалить?"}
                close={() => closeDelete()}
                okFunc={() => {
                  deleteChat(deleteID);
                }}
              />
            )}
          </>
        );
      })}
    </div>
  );
});

export default ModalChat;
