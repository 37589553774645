import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { useFormik } from "formik";
import CustomInput from "components/CustomInput";
import Button from "components/Button";
import { toast } from "react-hot-toast";
import { Col, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";

import styles from "./SuppliersSection.module.scss";

const registerSchema = yup.object().shape({
  comanyName: yup.string().required("Поле обязательно для заполнения"),
  city: yup.string().required("Поле обязательно для заполнения"),
  tin: yup
    .string()
    .required("Поле обязательно для заполнения")
    .matches(/^[0-9]+$/, "поле должно содержать только цифры"),
  address: yup.string().required("Поле обязательно для заполнения"),
  phoneNumber: yup
    .string()
    .required("Поле обязательно для заполнения")
    .matches(/^[0-9]+$/, "поле должно содержать только цифры"),
  email: yup
    .string()
    .email("Вы ввели некорректный Email")
    .required("Поле обязательно для заполнения"),
  contactPerson: yup.string().required("Поле обязательно для заполнения"),
});

interface Props {
  setApplicationIsOpen: Dispatch<SetStateAction<boolean>>;
}
const Application: React.FC<Props> = observer(({ setApplicationIsOpen }) => {
  const {
    registerStore: { application },
  } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(false);

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      comanyName: "",
      city: "",
      tin: "",
      address: "",
      phoneNumber: "",
      email: "",
      contactPerson: "",
    },
    validationSchema: registerSchema,
    onSubmit: async () => {
      setSubmitting(true);
      setLoading(true);
      application(
        values.comanyName,
        values.city,
        values.tin,
        values.address,
        values.phoneNumber,
        values.email,
        values.contactPerson
      ).then((r) => {
        if (r) {
          toast.success("Заявка подана");
          setApplicationIsOpen(false);
          setLoading(false);
        } else {
          toast.error("Произошла ошибка");
          setLoading(false);
        }
      });
    },
  });

  return (
    <div className={styles.content}>
      <p className={styles.title}>Заявка на регистрацию</p>
      <Row>
        <Col xl={8} lg={8} md={7} sm={12}>
          <CustomInput
            value={values.comanyName}
            error={touched.comanyName ? errors.comanyName : ""}
            placeholder="Название компании"
            name="comanyName"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            clearable={() => {
              setFieldValue("comanyName", "");
            }}
            className={styles.applicationInput}
          />
        </Col>
        <Col xl={4} lg={4} md={5} sm={12}>
          <CustomInput
            value={values.city}
            error={touched.city ? errors.city : ""}
            placeholder="Город"
            name="city"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            clearable={() => {
              setFieldValue("city", "");
            }}
            className={styles.applicationInput}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <CustomInput
            value={values.tin}
            error={touched.tin ? errors.tin : ""}
            placeholder="ИНН"
            name="tin"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            type="text"
            clearable={() => {
              setFieldValue("tin", "");
            }}
            maxLength={12}
            className={styles.applicationInput}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <CustomInput
            value={values.phoneNumber}
            error={touched.phoneNumber ? errors.phoneNumber : ""}
            placeholder="Номер телефона"
            name="phoneNumber"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            clearable={() => {
              setFieldValue("phoneNumber", "");
            }}
            className={styles.applicationInput}
          />
        </Col>
        <Col xl={12}>
          <CustomInput
            value={values.address}
            error={touched.address ? errors.address : ""}
            placeholder="Адрес"
            name="address"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            clearable={() => {
              setFieldValue("address", "");
            }}
            className={styles.applicationInput}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <CustomInput
            value={values.email}
            error={touched.email ? errors.email : ""}
            placeholder="E-mail"
            name="email"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            clearable={() => {
              setFieldValue("email", "");
            }}
            className={styles.applicationInput}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <CustomInput
            value={values.contactPerson}
            error={touched.contactPerson ? errors.contactPerson : ""}
            placeholder="ФИО контактного лица"
            name="contactPerson"
            onChange={(e) => handleChange(e)}
            onBlur={handleBlur}
            clearable={() => {
              setFieldValue("contactPerson", "");
            }}
            className={styles.applicationInput}
          />
        </Col>
      </Row>
      <Button
        text={loading ? "" : "Отправить заявку"}
        children={loading ? <Spinner animation="grow" variant="light" /> : ""}
        onClick={() => {
          handleSubmit();
        }}
        fullWidth
        color="primary"
      />
    </div>
  );
});
export default Application;
