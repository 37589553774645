import {
  FC,
  useContext,
  useState,
  ReactElement,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { RootStoreContext } from "../../../../../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import { Close } from "@mui/icons-material";
import styles from "../../../modal/modal.module.scss";

interface Props {
  isOpen: boolean;
  id: number;
  order: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setInitDescp: Dispatch<SetStateAction<string>>;
  setInitTitle: Dispatch<SetStateAction<string>>;
  initDescp: string;
  initTitle: string;
}

export const EditeChildren: FC<Props> = observer(
  ({ isOpen, id, order, setOpen, initTitle, initDescp }): ReactElement => {
    const {
      faqStore: { updateFaq },
    } = useContext(RootStoreContext);

    const menuRef = useRef<HTMLInputElement>(null);
    const [title, setTitle] = useState<string>(initTitle);
    const [description, setDescription] = useState<string>(initDescp);
    let errorT = "";
    let errorD = "";
    let condition = false;

    if (title === "") {
      errorT = "Это поле не может быть пустым";
    }
    if (description === "") {
      errorD = "Это поле не может быть пустым";
    }

    if (title === "" || description === "") {
      condition = true;
    }

    //TODO: Need fix
    function update(): void {
      updateFaq(id, order, order);
    }

    function handlClick(): void {
      setOpen(false);
    }

    return (
      <div>
        <div
          className={isOpen ? styles.modal : styles.close}
          onClick={(e: any) => {
            if (!menuRef.current?.contains(e.target)) {
              setOpen(false);
              e.stopPropagation();
            }
          }}
        >
          <div
            className={styles.modalContent}
            ref={menuRef}
            onClick={(e: any) => {
              if (menuRef.current?.contains(e.target)) {
                e.stopPropagation();
              }
            }}
          >
            <div className={styles.modalContentForm}>
              <Close className={styles.closeModal} onClick={handlClick} />
              <p className={styles.modalContentFormTitle}>Изменить вопрос</p>
              <input
                type="text"
                className={styles.modalContentFormInput}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Изменить вопрос"
              />
              <div className={styles.errors}>{errorT}</div>

              <textarea
                rows={6}
                cols={40}
                className={styles.modalContentFormTextarea}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder="Изменить oтвет"
              />
              <div className={styles.errors}>{errorD}</div>

              <Button
                text="Изменить"
                color="primary"
                className={styles.modaleAdd}
                type="submit"
                onClick={update}
                {...(condition && { disabled: true })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
