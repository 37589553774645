import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { RootStoreContext } from "stores/RootStore";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PersonIcon from "@mui/icons-material/Person";

import "./Avatar.scss";
import { AiOutlineLogout } from "react-icons/ai";
import { UserSvg } from "../../../../components/SvgIcons";
import styles from "../MobileHeader/MobileHeader.module.scss";
import SvgIcon from "../../../../components/SvgIcon";

const Avatar = observer(() => {
  const {
    loginStore: { logout },
    userStore: { info },
  } = useContext(RootStoreContext);

  const navigate = useNavigate();

  const actions = [
    { icon: <UserSvg />, to: "/profile", title: info?.firstName, show: true },
  ];

  return (
    <div className="avatar">
      <SpeedDial
        className="AvatarSpeedDial"
        direction="down"
        ariaLabel="Avatar"
        icon={<PersonIcon fontSize="large" />}
      >
        {actions.map((item) => (
          <SpeedDialAction
            key={item.title}
            icon={
              <div className={styles.icon}>
                <SvgIcon
                  size={1.6}
                  color={
                    location.pathname === item.to ||
                    location.pathname.search(item.to) !== -1
                      ? "var(--fill-primary)"
                      : "var(--fill-medium-emphasis)"
                  }
                  component={item.icon}
                />
              </div>
            }
            onClick={() => navigate(item.to)}
            tooltipTitle={item.title}
          />
        ))}
        <SpeedDialAction
          icon={<AiOutlineLogout size={28} />}
          onClick={() => logout()}
          tooltipTitle={"Выход"}
        />
      </SpeedDial>
    </div>
  );
});

export default Avatar;
