import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import { RootStore } from "./RootStore";

interface CreateSessionToken {
  token: string;
  errors: [];
  status: number;
}

interface CreateSessionError {
  error_id: string;
  error_message: string;
  error_fields: Array<{
    code: string;
    name: string;
    message: string;
  }>;
  error_code: string;
  status_code?: number;
}

class RegisterStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  errorData: CreateSessionError | null = null;

  country = "1";

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setErrorData = (error: CreateSessionError | null): void => {
    this.errorData = error;
  };

  setCountry = (value: string): void => {
    this.country = value;
  };

  register = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    phoneNumber: string,
    tin: string,
    msrnie: string,
    passportSeries: string,
    passportNumber: string,
    issuedBy: string,
    passportIssueDate: string,
    departmentCode: string,
    registrationAddress: string,
    actualAddress: string,
    role: string,
    middleName: string,
    birthPlace: string,
    birthDate: string,
    dataAccuracyConfirmed: boolean,
    personalDataProcecessingConsent: boolean,
    roomsellerConditionsConsent: boolean
  ): Promise<string | null> => {
    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({}),
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        tin,
        msrnie,
        passportSeries,
        passportNumber,
        issuedBy,
        passportIssueDate,
        departmentCode,
        registrationAddress,
        actualAddress,
        role,
        middleName,
        birthPlace,
        birthDate,
        dataAccuracyConfirmed,
        personalDataProcecessingConsent,
        roomsellerConditionsConsent,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(endpoint, requestOptions);

      if (response.ok) {
        return null;
      } else {
        const data = await response.text();
        return data ? data : "Ошибка. Повторите попытку позже.";
      }
    } catch (error) {
      this.setIsLoading(false);

      return "Ошибка. Повторите попытку позже.";
    }
  };

  application = async (
    comanyName: string,
    city: string,
    tin: string,
    address: string,
    phoneNumber: string,
    email: string,
    contactPerson: string
  ): Promise<boolean | null> => {
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({}),
      body: JSON.stringify({
        comanyName,
        city,
        tin,
        address,
        phoneNumber,
        email,
        contactPerson,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(`${endpoint}/Application`, requestOptions);

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.setIsLoading(false);

      return false;
    }
  };

  logout = (): void => {
    const { userStore } = this.rootStore;

    Promise.all([userStore.clearStore()]).then(() => {
      userStore.clearStore();
    });
  };
}

export default RegisterStore;
