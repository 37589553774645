import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFoundPage.module.scss";
import NotFoundSvg from "./notFound.svg";

const NotFoundPage: React.FC = () => {
  return (
    <div className={styles.notFoundDiv}>
      <img src={NotFoundSvg} />
      <p>Попробуйте вернуться назад или поищите что-нибудь другое.</p>
      <p>Код ошибки: 404</p>
      <div>
        <Link to={"/home"}>На главную</Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
