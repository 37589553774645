import React, { SetStateAction, useContext, useState } from "react";
import styles from "./InputField.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import DeleteModal from "components/DeleteModal";
interface Props {
  productId: string;
}
const InputField: React.FC<Props> = observer(({ productId }) => {
  const {
    warehouseStore: { addProductChat },
  } = useContext(RootStoreContext);
  const [messege, setMessege] = useState<string>("");
  const [deletModalOpen, setDeletModalOpen] = useState<boolean>(false);
  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setMessege(event.target.value);
  };

  function handlerClick(): void {
    setDeletModalOpen(true);
  }
  function closeClick(): void {
    setDeletModalOpen(false);
    setMessege("");
  }
  return (
    <div className={styles.sendQuestionContainer}>
      <textarea
        value={messege}
        onChange={handleChange}
        placeholder="Введите текст здесь"
      ></textarea>
      <button
        className={styles.sendQuestionBtn}
        onClick={() => handlerClick()}
        disabled={!messege}
      >
        Отправить вопрос
      </button>
      {deletModalOpen && (
        <DeleteModal
          text={"Вы уверены, что хотите отправить вопрос?"}
          close={() => closeClick()}
          okFunc={() => addProductChat(productId, messege)}
        />
      )}
    </div>
  );
});
export default InputField;
