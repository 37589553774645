import Product from "../components/Product";
import CustomSelect, { SelectValue } from "components/CustomSelect";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import styles from "./Favorites.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";

const Favorites = observer(() => {
  const {
    warehouseStore: { products, getProducts, filters, setFilters },
  } = useContext(RootStoreContext);

  const viewType = 1;
  const [loading, setLoading] = useState<boolean>(true);

  const pageSize = useMemo(
    () => filters.find((item) => item.name === "pageSize"),
    [filters]
  );

  const pageNumber = useMemo(
    () => filters.find((item) => item.name === "pageNumber"),
    [filters]
  );

  useEffect(() => {
    setLoading(true);
    getProducts({
      isFavorite: true,
      ...(pageSize && { pageSize: Number(pageSize.value) }),
      ...(pageNumber && { pageNumber: Number(pageNumber.value) }),
    }).then(() => {
      setLoading(false);
    });
  }, [pageSize, pageNumber, getProducts]);

  const handleFilters = (e: SelectValue, name: string) => {
    if (filters && filters.find((item) => item.name === name)) {
      setFilters([
        ...filters.filter((x) => x.name !== name && x.name !== "pageNumber"),
        { name: name, value: e.value.toString(), label: e.label as string },
        {
          name: "pageNumber",
          value: "1",
          label: "1",
        },
      ]);
    } else {
      setFilters([
        ...filters.filter((x) => x.name !== "pageNumber"),
        { name: name, value: e.value.toString(), label: e.label as string },
        {
          name: "pageNumber",
          value: "1",
          label: "1",
        },
      ]);
    }
  };

  const handlePages = (updatePage: number) => {
    setFilters([
      ...filters.filter((x) => x.name !== "pageNumber"),
      {
        name: "pageNumber",
        value: updatePage.toString(),
        label: updatePage.toString(),
      },
    ]);
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>Избранное</p>
      <div className={styles.wrapper}>
        <Row>
          {loading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={loading} size={50} />
            </div>
          ) : products && products.products.length > 0 ? (
            products.products.map((item, index) => (
              <Col xl={3} lg={3} md={4} sm={6} xs={12} key={index + item.id}>
                <Product data={item} list={viewType === 1} />
              </Col>
            ))
          ) : (
            <p className={styles.noFavoritesTitle}>
              У Вас нет избранных товаров
            </p>
          )}
        </Row>
        {!loading
          ? products &&
            products.pageCount > 1 && (
              <div className={classNames(styles.pagination)}>
                <CustomSelect
                  className={styles.small}
                  name="pageSize"
                  placeholder="qty"
                  onChange={(e) => {
                    handleFilters(e, "pageSize");
                  }}
                  value={
                    pageSize?.value
                      ? pageSize?.value
                      : { value: "20", label: "20" }
                  }
                  list={[
                    { value: "20", label: "20" },
                    { value: "40", label: "40" },
                    { value: "60", label: "60" },
                  ]}
                />
                <Pagination
                  page={Number(pageNumber?.value)}
                  totalPages={products.pageCount}
                  handlePagination={handlePages}
                />
              </div>
            )
          : ""}
      </div>
    </div>
  );
});

export default Favorites;
