import { Table } from "react-bootstrap";
import React from "react";
import { observer } from "mobx-react-lite";
import { ProfileInfo } from "../../../stores/UserStore";
import { BeatLoader } from "react-spinners";
import { Analytics } from "../../../stores/MarketPlaceStore";
import styles from "./Analytics.module.scss";
import YandexLogo from "../../../assets/icons/yandex.png";
import OzonLogo from "../../../assets/icons/ozon.png";
import WildberriesLogo from "../../../assets/icons/wildberries.png";
import moment from "moment/moment";

interface Props {
  loading: boolean;
  info: ProfileInfo | null;
  analytics: Analytics[] | null;
}

const AnalyticsTable: React.FC<Props> = observer(
  ({ loading, info, analytics }) => {
    return (
      <div>
        {loading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={loading} size={50} />
          </div>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Дата</th>
                {info?.roles.includes("SuperAdmin") && <th>Продавец</th>}
                <th>МП</th>
                <th>Номер заказа</th>
                <th>Наименование товара</th>
                <th>Цена РС</th>
                <th>Упаковка, доставка</th>
                <th>Цена, по которой реализовали товар</th>
                <th>Комиссия / Логистика МП</th>
                <th>Возврат</th>
                <th>Итого к перечислению</th>
                <th>Итого наценка</th>
              </tr>
            </thead>
            <tbody>
              {analytics &&
                analytics.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.orderDate
                        ? moment(item.orderDate).format("DD.MM.YYYY")
                        : ""}
                    </td>
                    {info && info.roles.includes("SuperAdmin") && (
                      <td>{item.userFullName}</td>
                    )}
                    <td className={styles.MPLogos}>
                      {item.marketPlaceName === "YandexMarket" ? (
                        <img src={YandexLogo} alt="ozon logo" />
                      ) : item.marketPlaceName === "Ozon" ? (
                        <img src={OzonLogo} alt="ozon logo" />
                      ) : (
                        <img src={WildberriesLogo} alt="ozon logo" />
                      )}
                    </td>
                    <td>{item.orderNumbersFromInputParameter}</td>
                    <td>{item.productTitle}</td>
                    <td>
                      {parseFloat(
                        Number(item.productPriceOnRoomseller).toFixed(2)
                      )}{" "}
                      ₽
                    </td>
                    <td>
                      {parseFloat(
                        Number(item.packingDeliveryExpensies).toFixed(2)
                      )}{" "}
                      ₽
                    </td>
                    <td>
                      {parseFloat(Number(item.productSalePriceOnMP).toFixed(2))}{" "}
                      ₽
                    </td>
                    <td>
                      {parseFloat(
                        Number(item.logisticsCommisionExpensies).toFixed(2)
                      )}{" "}
                      ₽
                    </td>
                    <td>{parseFloat(Number(item.returnPrice).toFixed(2))} ₽</td>
                    <td>
                      {parseFloat(Number(item.finalSumToSeller).toFixed(2))} ₽
                    </td>
                    <td>{parseFloat(Number(item.finalMarkup).toFixed(2))} ₽</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
);
export default AnalyticsTable;
