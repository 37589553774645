import styles from "./Settings.module.scss";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { useState } from "react";
import Button from "../../../components/Button";
import CustomInput from "../../../components/CustomInput";

const Settings = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className={styles.root}>
      <p className={styles.title}>Импорт</p>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <p className={styles.subtitle}>Импорт</p>
          <ToggleSwitch
            checked={toggle}
            onChange={() => {
              setToggle(!toggle);
            }}
            name="import"
          />
          <p className={styles.subtitleUpdate}>Обновление</p>
          <Button text="Обновить" className={styles.btn} size="big" />
          <p className={styles.subtitleUpdate}>Промежуток времени</p>
          <div className={styles.inputs}>
            <CustomInput
              name="min"
              onChange={() => {}}
              className={styles.input}
            />
            <div className={styles.dots} />
            <CustomInput
              name="hour"
              onChange={() => {}}
              className={styles.input}
            />
            <Button text="Обновить" className={styles.btn} />
          </div>
        </div>
        <div className={styles.banner} />
      </div>
    </div>
  );
};
export default Settings;
