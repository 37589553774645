import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Returns.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import YandexLogo from "../../../assets/icons/yandex.png";
import OzonLogo from "../../../assets/icons/ozon.png";
import WildberriesLogo from "../../../assets/icons/wildberries.png";
import moment from "moment/moment";
import BeatLoader from "react-spinners/BeatLoader";
import { HiDocumentDownload } from "react-icons/hi";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import CustomSelect, { SelectValue } from "components/CustomSelect";
import { components } from "react-select";
import CustomInput from "components/CustomInput";
import Icon from "components/Icon";
import Pagination from "components/Pagination";
import { MarketPlaces } from "stores/MarketPlaceStore";
import { Seller } from "stores/OrderStore";
import { WarehouseFilterI } from "stores/WarehouseStore";
import classNames from "classnames";

const Returns: React.FC = observer(() => {
  const {
    orderStore: { getReturned, returns },
    userStore: { info },
    ozonStore: { getOzonReturnAct },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [labelLoading, setLabelLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>("");

  const [marketplaces, setMarketplaces] = useState<MarketPlaces[]>([]);
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseFilterI[]>([]);

  const newParams = new URLSearchParams(searchParams);

  const pageSize = searchParams.get("pageSize");
  const pageNumber = searchParams.get("pageNumber");
  const searchText = searchParams.get("search");
  const sellerID = searchParams.get("sellerID");
  const mpWarehouseID = searchParams.get("mpWarehouseID");
  const marketPlaceID = searchParams.get("marketPlaceID");

  const filters = [
    info?.roles.includes("SuperAdmin")
      ? {
          label: "sellerID",
          value: sellers.filter((i) => i.id === sellerID)[0]
            ? sellers.filter((i) => i.id === sellerID)[0].sellerName
            : null,
        }
      : {},
    {
      label: "marketPlaceID",
      value: marketplaces.filter((i) => i.id === Number(marketPlaceID))[0]
        ? marketplaces.filter((i) => i.id === Number(marketPlaceID))[0].name
        : null,
    },
    {
      label: "mpWarehouseID",
      value: warehouses.filter((i) => i.id === Number(mpWarehouseID))[0]
        ? warehouses.filter((i) => i.id === Number(mpWarehouseID))[0].name
        : null,
    },
    { label: "search", value: searchText },
  ];

  useEffect(() => {
    setLoading(true);
    getReturned({
      ...(pageSize && { pageSize: Number(pageSize) }),
      ...(pageNumber && { pageNumber: Number(pageNumber) }),
      ...(sellerID && { sellerID: sellerID }),
      ...(marketPlaceID && { marketPlaceID: Number(marketPlaceID) }),
      ...(mpWarehouseID && { mpWarehouseID: Number(mpWarehouseID) }),
      ...(searchText && { searchText: searchText }),
    }).then((data) => {
      if (data) {
        setMarketplaces(data.marketPlaces);
        setSellers(data.sellers);
        setWarehouses(data.warehouses);
      }
      setLoading(false);
    });
  }, [
    getReturned,
    setLoading,
    pageSize,
    pageNumber,
    searchText,
    sellerID,
    marketPlaceID,
    mpWarehouseID,
  ]);

  const handlePages = (updatePage: number) => {
    newParams.set("pageNumber", `${updatePage}`);
    setSearchParams(newParams);
  };

  const handleSearchFilter = () => {
    newParams.set("pageNumber", "1");
    newParams.set("search", searchValue);
    setSearchParams(newParams);
  };

  const handleFilters = (e: SelectValue, name: string) => {
    newParams.set("pageNumber", "1");
    newParams.set(name, e.value);
    setSearchParams(newParams);
  };

  const MenuList: React.FC<any> = (props) => {
    const { children, ...otherProps } = props;
    return (
      <components.MenuList {...otherProps}>{children}</components.MenuList>
    );
  };
  return (
    <div className={styles.root}>
      <p className={styles.title}>Возвраты</p>
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <CustomSelect
            className={styles.normal}
            name="mpWarehouseID"
            placeholder="Склад"
            onChange={(e) => {
              handleFilters(e, "mpWarehouseID");
            }}
            value={Number(mpWarehouseID)}
            list={
              warehouses.map((item) => ({
                value: item.id,
                label: item.name,
              })) as any
            }
          />
          <CustomSelect
            className={styles.normal}
            name="marketPlaceID"
            placeholder="Маркетплейс"
            onChange={(e) => {
              handleFilters(e, "marketPlaceID");
            }}
            value={Number(marketPlaceID)}
            list={
              marketplaces.map((item) => ({
                value: item.id,
                label: item.name,
              })) as any
            }
          />
          {info?.roles.includes("SuperAdmin") ? (
            <CustomSelect
              className={styles.normal}
              name="seller"
              placeholder="Продавец"
              onChange={(e) => {
                handleFilters(e, "sellerID");
              }}
              value={sellerID}
              list={sellers.map(
                (item) =>
                  ({
                    value: item.id,
                    label: item.sellerName,
                  } as any)
              )}
            />
          ) : (
            ""
          )}
          <div className={styles.search}>
            <CustomInput
              type="search"
              value={searchValue}
              placeholder="Поиск..."
              name={`search`}
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (!e.target.value) {
                  newParams.delete("search");
                  setSearchParams(newParams);
                }
              }}
              onKeyPress={(event) =>
                event.key === "Enter" && handleSearchFilter()
              }
              className={styles.searchInput}
            />
          </div>
        </div>
        {returns ? (
          <div className={styles.tags}>
            {filters.map(
              (item, index) =>
                item.value && (
                  <div className={styles.tag} key={index}>
                    <div className={styles.label}>{item.value}</div>
                    <Icon
                      onClick={() => {
                        newParams.delete(item.label);
                        setSearchParams(newParams);
                      }}
                      variant="cross"
                      size="small"
                    />
                  </div>
                )
            )}
            {filters.filter((i) => i.value).length ? (
              <a
                className={styles.reset}
                onClick={() => {
                  setSearchParams();
                }}
              >
                Сбросить все фильтры
              </a>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {loading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={loading} size={50} />
          </div>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Продавец</th>
                <th>Склад</th>
                <th>МП</th>
                <th>Наименование товара</th>
                <th>Код поставщика</th>
                <th>Номер заказа на МП</th>
                <th>Дата заказа</th>
                <th>Дата получения</th>
                <th>Дата возврата</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {returns &&
                returns.returns.map((item) => {
                  return (
                    <tr key={item.vendoreCode}>
                      <td>{item.fullName}</td>
                      <td>{item.warehouseName}</td>
                      <td className={styles.MPLogos}>
                        {item.marketplaceName === "YandexMarket" ? (
                          <img src={YandexLogo} alt="ozon logo" />
                        ) : item.marketplaceName === "Ozon" ? (
                          <img src={OzonLogo} alt="ozon logo" />
                        ) : (
                          <img src={WildberriesLogo} alt="ozon logo" />
                        )}
                      </td>
                      <td>{item.productTitle}</td>
                      <td>{item.vendoreCode}</td>
                      <td>{item.postingNumber}</td>
                      <td>
                        {item.orderDate
                          ? moment(item.orderDate).format("DD.MM.YYYY")
                          : ""}
                      </td>
                      <td>
                        {item.receiveDate
                          ? moment(item.receiveDate).format("DD.MM.YYYY")
                          : ""}
                      </td>
                      <td>
                        {item.returnDate
                          ? moment(item.returnDate).format("DD.MM.YYYY")
                          : ""}
                      </td>
                      {info?.roles.includes("SuperAdmin") &&
                      item.marketplaceName === "Ozon" ? (
                        <td className={styles.packageLabel}>
                          {
                            <div
                              style={{
                                cursor: labelLoading ? "progress" : "pointer",
                              }}
                              onClick={() => {
                                setLabelLoading(true);

                                if (item.marketplaceName === "Ozon") {
                                  getOzonReturnAct(
                                    item.userID,
                                    "download"
                                  ).then((i) => {
                                    if (!i) {
                                      toast.error("Этикетка еще не готова.");
                                    }
                                    setLabelLoading(false);
                                  });
                                }
                              }}
                              className={styles.packageLabelA}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Скачать</Tooltip>}
                              >
                                <div>
                                  <HiDocumentDownload size={38} />
                                </div>
                              </OverlayTrigger>
                            </div>
                          }
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
        {returns && returns.pageCount > 1 && (
          <div className={classNames(styles.pagination)}>
            <CustomSelect
              components={{ MenuList }}
              className={styles.small}
              name="pageSize"
              placeholder="qty"
              onChange={(e) => {
                handleFilters(e, "pageSize");
              }}
              value={pageSize}
              list={[
                { value: "20", label: "20" },
                { value: "40", label: "40" },
                { value: "60", label: "60" },
              ]}
            />
            <Pagination
              page={Number(pageNumber)}
              totalPages={returns.pageCount}
              handlePagination={handlePages}
            />
          </div>
        )}
      </div>
    </div>
  );
});
export default Returns;
