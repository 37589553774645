import React, { FocusEventHandler, ReactNode, useEffect, useRef } from "react";
import classnames from "classnames";
import Select, {
  ActionMeta,
  InputActionMeta,
  StylesConfig,
} from "react-select";

import styles from "./CustomSelect.module.scss";

export interface SelectValue<T = string> {
  value: T;
  label: string | JSX.Element;
  isDisabled?: boolean;
  groups?: number;
}

export type IsMulti = boolean;

export type Variant =
  | "primary"
  | "secondary"
  | "tenantSelect"
  | "appointmentSelect"
  | "hourCalendarSelect"
  | "outline"
  | "prefixSelect"
  | "light";

interface Props {
  type?: "default" | "autocomplete";
  variant?: Variant;
  customStyles?: StylesConfig<SelectValue, IsMulti>;
  text?: string;
  name: string;
  isMulti?: boolean;
  onChange: (value: SelectValue, meta: ActionMeta<SelectValue>) => void;
  onInputChange?: (value: string, meta: InputActionMeta) => void;
  onBlur?: FocusEventHandler;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  error?: any;
  defaultValue?: SelectValue | SelectValue[] | null;
  formatOptionLabel?: (option: SelectValue, labelMeta: any) => ReactNode;
  filterOption?:
    | ((option: { value: string; label: string }, rawInput: string) => boolean)
    | null;
  placeholder?: string;
  position?: string;
  components?: any | false;
  list: SelectValue[];
  value?: any | null;
  autocomplete?: boolean;
  getOptionLabel?: (option: SelectValue) => string;
  getOptionValue?: (option: SelectValue) => string;
  isClearable?: boolean;
  borderLeft?: boolean;
  isFlexibleWidth?: boolean;
  handleSetSelectRef?: (e: HTMLElement) => void;
  minWidth?: number;
  disableIndicatorMargin?: boolean;
  menuPlacement?: "auto" | "top" | "bottom";
  menuIsOpen?: boolean;
  noPadding?: boolean;
  blurInputOnSelect?: boolean;
  isSearchable?: boolean;
  menuIsPortal?: boolean;
  label?: string;
  required?: boolean;
}

const CustomSelect: React.FC<Props> = ({
  onChange,
  onInputChange,
  onBlur,
  formatOptionLabel,
  filterOption,
  isDisabled = false,
  isLoading = false,
  list,
  defaultValue,
  value,
  placeholder,
  name,
  error,
  getOptionLabel,
  getOptionValue,
  isClearable,
  borderLeft,
  isFlexibleWidth = true,
  handleSetSelectRef,
  minWidth = 8,
  disableIndicatorMargin = false,
  menuPlacement = "auto",
  menuIsOpen,
  blurInputOnSelect = false,
  isSearchable = true,
  className,
  isMulti = false,
  menuIsPortal = false,
  components = false,
  label,
  required,
}) => {
  const selectRef = useRef<any>(null);
  const placeholderValue = placeholder ? <span>{placeholder}</span> : null;

  useEffect(() => {
    const selectObj = selectRef.current;
    if (!selectObj || !handleSetSelectRef) return;
    handleSetSelectRef(selectObj.select.inputRef);
  }, [selectRef, handleSetSelectRef]);

  const customStyle: StylesConfig<SelectValue, IsMulti> = {
    container: (base): any => ({
      ...base,
      display: "flex",
      alignItems: "center",
      color: "var(--fill-low-emphasis)",
      width: "100%",
      borderRadius: "var(--border-radius-3)",
      border: "1px solid var(--fill-border)",
      fontSize: "14px",
    }),
    valueContainer: (base): any => ({
      ...base,
      padding: "0.2rem 1rem",
      alignSelf: "stretch",
      display: "flex",
      input: {
        position: isSearchable ? "relative" : "absolute",
        left: 0,
        transform: "none",
      },
    }),
    placeholder: (base): any => ({
      ...(isFlexibleWidth ? {} : base),
      color: "var(--fill-medium-emphasis)",
    }),
    option: (base, state): any => ({
      ...base,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      whiteSpace: "nowrap",
      padding: "0.8rem 1.2rem",
      color: "var(--fill-low-emphasis)",
      fontWeight: state.isSelected ? "bold" : "normal",
      borderInlineStart:
        state.data.groups === 1
          ? "0.5rem solid var(--fill-primary)"
          : state.data.groups === 2
          ? "1rem solid var(--fill-primary)"
          : "0rem solid var(--fill-primary)",
      ":hover": {
        fontWeight: "bold",
        color: "var(--fill-low-emphasis)",
      },
    }),
    singleValue: (base, state): any => {
      const isLabelString = typeof state.data.label === "string";

      return {
        ...(isFlexibleWidth ? {} : base),

        whiteSpace: "nowrap",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "100%",
        color: "var(--fill-low-emphasis)",

        ...(isLabelString
          ? {
              maxWidth: "calc(100% - 0.1rem)",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }
          : { paddingRight: "0.5rem" }),
      };
    },
    input: (base): any => ({
      ...base,
      position: "absolute",
    }),
    control: (base, state): any => ({
      flexWrap: isFlexibleWidth ? "nowrap" : "wrap",
      minWidth: `${minWidth}rem`,
      ...base,
      width: "100%",
      minHeight: "56px",
      border: "none",
      boxShadow: "none",
      // backgroundColor: "transparent",
      backgroundColor: "var(--fill-base)",
      borderRadius: "var(--border-radius-3)",
      // border: "1px solid var(--fill-border)",
      opacity: state.isDisabled ? 0.3 : 1,
    }),
    dropdownIndicator: () => ({
      display: "flex",
      margin: disableIndicatorMargin ? "0" : "0 0.8rem 0 0.4rem",
      svg: {
        width: "1rem",
        height: "1rem",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (base): any => {
      const { width, ...restBase } = base;

      return {
        ...restBase,
        boxShadow: "none",
        marginTop: "0.2rem",
        minWidth: "100%",
      };
    },
    menuList: (base): any => ({
      ...base,
      maxHeight: "23rem",
      "::-webkit-scrollbar": {
        width: "0.6rem",
      },
      "::-webkit-scrollbar-track": {
        // boxShadow: "inset 0 0 2px rgba(0, 0, 0, 0.3)",
        backgroundColor: "var(--fill-high-emphasis)",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "var(--fill-medium-emphasis)",
        cursor: "pointer",
        borderRadius: "1rem",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "var(--fill-medium-emphasis)",
      },
    }),
    multiValue: (base): any => ({
      ...base,
      borderRadius: "var(--border-radius-1)",
      backgroundColor: "var(--elevation-3)",
    }),
    menuPortal: (base): any => ({
      ...base,
      zIndex: 9999,
    }),

    multiValueRemove: (base): any => ({
      ...base,
      ":hover": {
        backgroundColor: "transparent",
        cursor: "pointer",
        svg: {
          transition: "color 0.3s",
        },
      },
    }),
  };

  return (
    <div className={classnames(styles.root, className)}>
      {!!label?.length && (
        <label htmlFor={name} className={styles.label}>
          {label}
          {required ? <span className={styles.requiredSpan}> *</span> : ""}
        </label>
      )}
      {components ? (
        <Select
          menuIsOpen={menuIsOpen}
          styles={customStyle}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "rgb(255,255,255)",
              primary25: "rgb(247,150,51,0.8)",
              primary50: "rgb(247,150,51)",
            },
          })}
          components={components}
          isMulti={isMulti}
          options={list}
          name={name}
          openMenuOnFocus
          placeholder={placeholderValue}
          onChange={(selectedOption, meta) => {
            onChange(selectedOption as SelectValue, meta);
          }}
          onBlur={onBlur}
          blurInputOnSelect={blurInputOnSelect}
          value={value ? list.find((x) => x.value === value) : null}
          onInputChange={onInputChange}
          defaultValue={defaultValue}
          formatOptionLabel={formatOptionLabel}
          filterOption={filterOption}
          isDisabled={isDisabled}
          isLoading={isLoading}
          classNamePrefix="react-select"
          isClearable={isClearable}
          menuPlacement={menuPlacement}
          isSearchable={isSearchable}
          tabSelectsValue={!value}
          menuPosition={menuIsPortal ? "fixed" : "absolute"}
          className={classnames({
            [styles.borderLeft]: borderLeft,
            [styles.errorInput]: error,
          })}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          ref={selectRef}
          menuPortalTarget={menuIsPortal ? document.body : null}
        />
      ) : (
        <Select
          menuIsOpen={menuIsOpen}
          styles={customStyle}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "rgb(255,255,255)",
              primary25: "rgb(247,150,51,0.8)",
              primary50: "rgb(247,150,51)",
            },
          })}
          isMulti={isMulti}
          options={list}
          name={name}
          openMenuOnFocus
          components={components}
          placeholder={placeholderValue}
          onChange={(selectedOption, meta) => {
            onChange(selectedOption as SelectValue, meta);
          }}
          onBlur={onBlur}
          blurInputOnSelect={blurInputOnSelect}
          value={value ? list.find((x) => x.value === value) : null}
          onInputChange={onInputChange}
          defaultValue={defaultValue}
          formatOptionLabel={formatOptionLabel}
          filterOption={filterOption}
          isDisabled={isDisabled}
          isLoading={isLoading}
          classNamePrefix="react-select"
          isClearable={isClearable}
          menuPlacement={menuPlacement}
          isSearchable={isSearchable}
          tabSelectsValue={!value}
          menuPosition={menuIsPortal ? "fixed" : "absolute"}
          className={classnames({
            [styles.borderLeft]: borderLeft,
            [styles.errorInput]: error,
          })}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          ref={selectRef}
          menuPortalTarget={menuIsPortal ? document.body : null}
        />
      )}
      {error && <label className={styles.error}>{error}</label>}
    </div>
  );
};

export default CustomSelect;
