import CustomSelect, { SelectValue } from "components/CustomSelect";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Cart.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { useSearchParams } from "react-router-dom";
import CustomInput from "../../../components/CustomInput";
import { MarketPlaces } from "stores/MarketPlaceStore";
import { Seller } from "stores/OrderStore";
import { WarehouseFilterI } from "stores/WarehouseStore";

interface Props {
  marketplace: string | null;
  seller: string | null | undefined;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  searchText: string | null;
  mpWarehouseID: string | null;
  marketPlaces: MarketPlaces[];
  sellers: Seller[];
  warehouses: WarehouseFilterI[];
}

const CartFilters: React.FC<Props> = observer(
  ({
    marketplace,
    seller,
    startDate,
    endDate,
    searchText,
    sellers,
    status,
    warehouses,
    marketPlaces,
    mpWarehouseID,
  }) => {
    const {
      userStore: { info },
      orderStore: { orderStatuses },
    } = useContext(RootStoreContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [searchValue, setSearchValue] = useState<string>(
      searchText ? searchText : ""
    );

    const newParams = new URLSearchParams(searchParams);

    useEffect(() => {
      if (!searchText) {
        setSearchValue("");
      }
    }, [searchText]);

    const handleSearchFilter = () => {
      newParams.set("pageNumber", "1");
      newParams.set("search", searchValue);
      setSearchParams(newParams);
    };

    const handleFilters = (e: SelectValue, name: string) => {
      newParams.set("pageNumber", "1");
      newParams.set(name, e.value);
      setSearchParams(newParams);
    };

    const handleDateFilters = (e: string, name: string) => {
      newParams.set("pageNumber", "1");
      newParams.set(name, e);
      setSearchParams(newParams);
    };
    return (
      <>
        {info?.roles.includes("SuperAdmin") ||
        info?.roles.includes("Seller") ? (
          <CustomSelect
            className={styles.normal}
            name="mpWarehouseID"
            placeholder="Склад"
            onChange={(e) => {
              handleFilters(e, "mpWarehouseID");
            }}
            value={Number(mpWarehouseID)}
            list={
              warehouses.map((item) => ({
                value: item.id,
                label: item.name,
              })) as any
            }
          />
        ) : (
          ""
        )}

        <CustomSelect
          className={styles.normal}
          name="marketplace"
          placeholder="Маркетплейс"
          onChange={(e) => {
            handleFilters(e, "marketplace");
          }}
          value={Number(marketplace)}
          list={
            marketPlaces.map((item) => ({
              value: item.id,
              label: item.name,
            })) as any
          }
        />
        {info?.roles.includes("SuperAdmin") ||
        info?.roles.includes("WarehouseOwner") ? (
          <CustomSelect
            className={styles.normal}
            name="seller"
            placeholder="Продавец"
            onChange={(e) => {
              handleFilters(e, "seller");
            }}
            value={seller}
            list={sellers.map(
              (item) =>
                ({
                  value: item.id,
                  label: item.sellerName,
                } as any)
            )}
          />
        ) : (
          ""
        )}
        {orderStatuses && (
          <CustomSelect
            className={styles.normal}
            name="status"
            placeholder="Статус"
            onChange={(e) => {
              handleFilters(e, "status");
            }}
            value={Number(status)}
            list={orderStatuses.map(
              (item) =>
                ({
                  value: item.id,
                  label: item.title,
                } as any)
            )}
          />
        )}
        <div className={styles.search}>
          <CustomInput
            type="search"
            value={searchValue}
            placeholder="Поиск..."
            name={`search`}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (!e.target.value) {
                newParams.delete("search");
                setSearchParams(newParams);
              }
            }}
            onKeyPress={(event) =>
              event.key === "Enter" && handleSearchFilter()
            }
            className={styles.searchInput}
          />
        </div>
        {startDate && endDate && endDate !== "null" && (
          <div className={styles.dates}>
            <div>
              <div>Период:</div>
              <div>
                {
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      if (e.target.value) {
                        handleDateFilters(e.target.value, "startDate");
                      }
                    }}
                  />
                }
              </div>
              <div>
                {
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      if (e.target.value) {
                        handleDateFilters(e.target.value, "endDate");
                      }
                    }}
                  />
                }
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
export default CartFilters;
