import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import { RootStore } from "./RootStore";
import { toast } from "react-hot-toast";

interface IYandexStocks {
  item1: number;
  item2: string;
}

class YandexStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  yandexStocks: IYandexStocks[] | null = null;

  mpYandexStocks: IYandexStocks[] | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setYandexStocks = (data: IYandexStocks[]): void => {
    this.yandexStocks = data;
  };

  setMPYandexStocks = (data: IYandexStocks[]): void => {
    this.mpYandexStocks = data;
  };

  getYandexShipmentAct = async (userId: string): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/GetShipmentAct?userID=${userId}`,
        requestOptions
      );
      const data = await response.blob();

      if (response.ok) {
        this.setIsLoading(false);
        return data;
      } else {
        this.setIsLoading(false);
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  GetYandexProductPrice = async (
    UserID: string,
    ProductID: string
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/GetYandexProductPrice?UserID=${UserID}&ProductID=${ProductID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  setYandexProductNewPrice = async (
    UserID: string,
    ProductID: string,
    NewPriceOffer: string
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/SetYandexProductNewPrice?UserID=${UserID}&ProductID=${ProductID}&NewPriceOffer=${NewPriceOffer}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Цена товара успешно установлена");
      } else {
        toast.error("Произошла ошибка");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Произошла ошибка");
    }
  };

  ApproveYandexShipment = async (
    UserID: string,
    StockID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/ApproveShipment/${UserID}/${StockID}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Акт отгрузки подтвержден");
      } else {
        toast.error("Повторите попытку позже");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Повторите попытку позже");
    }
  };

  yandexCancelOrder = async (OrderId: number): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/CancelOrder?OrderID=${OrderId}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Заказ отменён");
      } else {
        toast.error("Произошла ошибка");
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);

      toast.error("Произошла ошибка");
    }
  };

  getYandexToken = async (): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/GetYandexToken`,
        requestOptions
      );
      const data = response.text();
      if (response.ok) {
        return await data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getYandexStocks = async (id: string): Promise<IYandexStocks[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/UserYandexCampaigns?UserID=${id}`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        this.setYandexStocks(data);
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };

  getMPYandexStocks = async (): Promise<IYandexStocks[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/GetYandexStocks`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        this.setMPYandexStocks(data);
        return data;
      } else {
        return null;
      }

      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      return null;
    }
  };
}

export default YandexStore;
