import React, { useContext, useEffect, useState } from "react";
import styles from "./Collections.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { Col, Row } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import Product from "../components/Product";
import { useSearchParams } from "react-router-dom";

const Collections = observer(() => {
  const {
    collectionsStore: { getCollections, collections },
    marketPlacesStore: { userWarehouses },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const mpWarehouseID = searchParams.get("mpWarehouseID");

  useEffect(() => {
    if (mpWarehouseID) {
      setLoading(true);
      getCollections(Number(mpWarehouseID)).then(() => {
        setLoading(false);
      });
    }
  }, [getCollections, mpWarehouseID]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>
        Наборы{" "}
        <span className={styles.warehouseName}>
          {
            userWarehouses?.filter((i) => i.item1 === Number(mpWarehouseID))[0]
              .item2
          }
        </span>
      </p>
      <div className={styles.wrapper}>
        <Row>
          {loading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={loading} size={50} />
            </div>
          ) : collections && collections.length > 0 ? (
            collections.map((item, index) => (
              <Col xl={3} lg={3} md={4} sm={6} xs={12} key={index + item.id}>
                <Product data={item} list={true} isCollectionPage={true} />
              </Col>
            ))
          ) : (
            <p className={styles.noFavoritesTitle}>У Вас нет наборов</p>
          )}
        </Row>
      </div>
    </div>
  );
});

export default Collections;
