import styles from "./Suppliers.module.scss";
import MenuSection from "../Home/MenuSection";
import { Container } from "react-bootstrap";
import ContactSection from "../Home/ContactSection";
import Footer from "../Home/Footer";
import React, { useRef } from "react";
import { SuppliersSection } from "./SuppliersSection/SuppliersSection";
import { InfoSection } from "./InfoSection/infoSection";
import { SalesDynamicsSection } from "./SalesDynamicsSection/salesDynamicsSection";
import { SalesGrowth } from "./SalesGrowth/salesGrowth";
import { AlghorithmOfWorkSection } from "./AlghorithmOfWorkSection/alghorithmOfWorkSection";
import { ReturnsSection } from "./ReturnsSection/returnsSection";
import { BettingSection } from "./BettingSection/bettingSction";
const Suppliers = () => {
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const advantageRef = useRef(null);
  const sellerRef = useRef(null);
  const reliablyRef = useRef(null);

  return (
    <Container fluid className={styles.Suppliers}>
      <MenuSection
        aboutRef={aboutRef}
        contactRef={contactRef}
        advantageRef={advantageRef}
        sellerRef={sellerRef}
        reilablyRef={reliablyRef}
      />
      <Container className={styles.contentWrapper}>
        <SuppliersSection />
        <InfoSection />
        <SalesDynamicsSection />
        <SalesGrowth />
        <AlghorithmOfWorkSection />
        <ReturnsSection />
        <BettingSection />
        <ContactSection contactRef={contactRef} />
      </Container>
      <Footer
        aboutRef={aboutRef}
        contactRef={contactRef}
        advantageRef={advantageRef}
        sellerRef={sellerRef}
        reilablyRef={reliablyRef}
      />
    </Container>
  );
};

export default Suppliers;
