import styles from "./salesDynamicsSection.module.scss";
import { Diagram } from "./diagram";
import { Container } from "react-bootstrap";

export const SalesDynamicsSection = () => {
  return (
    <Container className={styles.salesDynamicsSection}>
      <h1>ДИНАМИКА ПРОДАЖ ПОСТАВЩИКОВ</h1>
      <div>
        <p>
          В 2022 году поставщики реализовывали свой товар на маркетплейсах
          самостоятельно. В 2023 году подключились к RoomSeller, где их
          ассортимент также стали продавать еще 2 продавца RoomSeller.
          Количество заказов в 2023 году выросло на 55%
        </p>
        <Diagram />
      </div>
    </Container>
  );
};
