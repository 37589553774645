import React, {
  Component,
  useState,
  useEffect,
  MouseEvent,
  ReactNode,
} from "react";
import cls from "classnames";
import ReactDOM from "react-dom";
import styles from "./Modal.module.scss";

const modalRoot = document.getElementById("modal-root");

interface PropsWrapper {
  id?: string;
  isOpen: boolean;
  onClose: (() => void) | null;
  onClickBackground?: () => void;
  children: ReactNode;
  className?: string;
  maxWidth?: string;
  variant?: "fixed" | "absolute";
  expandedHeight?: boolean;
  disableClose?: boolean;
}
type Props = PropsWrapper & { onExited: () => void };

type StateProps = {
  fadeType: string;
  scrollPosition: number;
};

class Modal extends Component<Props, StateProps> {
  backgroundRef = React.createRef<HTMLDivElement>();

  modalRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = { fadeType: "", scrollPosition: 0 };
  }

  componentDidMount() {
    // window.addEventListener("keydown", this.onEscKeyDown, false);
    setTimeout(() => this.setState({ ...this.state, fadeType: "in" }), 0);

    this.setState({
      ...this.state,
      scrollPosition: window.pageYOffset,
    });

    window.addEventListener("keydown", this.handlePress);
    document.body.style.overflow = "hidden";
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen } = this.props;

    if (!isOpen && prevProps.isOpen) {
      this.handleHideModal();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handlePress);
    document.body.style.overflow = "auto";
  }

  handlePress = (e: KeyboardEvent) => {
    const { onClose, disableClose } = this.props;

    if (!this.modalRef.current) return;

    if (
      (e.key === "Tab" || e.shiftKey || (e.shiftKey && e.key === "Tab")) &&
      !this.modalRef.current.contains(e.target as Node)
    ) {
      e.preventDefault();
    }

    if (e.key === "Escape" && !disableClose) {
      if (onClose) onClose();
    }
  };

  handleHideModal() {
    this.setState({ fadeType: "out" });
  }

  transitionEnd = () => {
    const { fadeType } = this.state;
    const { onExited } = this.props;

    if (fadeType === "out") onExited();
  };

  onEscKeyDown = (e: KeyboardEvent) => {
    if (e.key !== "Escape") return;
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  handleClick = (e: MouseEvent) => {
    const { onClose, onClickBackground, disableClose } = this.props;

    if (disableClose) return;
    e.preventDefault();

    if (onClickBackground) onClickBackground();
    else if (onClose) onClose();
  };

  render() {
    const { id, className, children, maxWidth, variant, expandedHeight } =
      this.props;
    const { fadeType } = this.state;

    const style = {
      maxWidth: maxWidth ? maxWidth : "100%",
    };

    const component = (
      <div
        className={cls(
          styles.modalWrap,
          styles[variant || "fixed"],
          styles[`fade-${fadeType}`]
        )}
        ref={this.modalRef}
        onTransitionEnd={this.transitionEnd}
      >
        <div
          className={styles.background}
          onMouseDown={this.handleClick}
          ref={this.backgroundRef}
        />
        <div
          id={id}
          className={cls(styles.modal, className, {
            [styles.expandedHeight]: expandedHeight,
          })}
          style={style}
        >
          <div className={styles.boxDialog}>{children}</div>
        </div>
      </div>
    );

    if (variant === "absolute") return component;

    if (!modalRoot) return null;

    return ReactDOM.createPortal(component, modalRoot);
  }
}

const ModalWrapper: React.FC<PropsWrapper> = (props) => {
  const { isOpen } = props;
  const [isExited, setExited] = useState(!isOpen);

  useEffect(() => {
    if (isOpen) setExited(false);
  }, [isOpen]);

  if (isExited) return null;

  return <Modal {...props} onExited={() => setExited(true)} />;
};

export default ModalWrapper;
