import { createContext } from "react";
import UserStore from "stores/UserStore";
import LoginStore from "stores/LoginStore";
import RegisterStore from "./RegisterStore";
import ForgotStore from "./ForgotStore";
import ErrorStore from "stores/ErrorStore";
import ToastStore from "stores/ToastStore";
import NavigationStore from "stores/NavigationStore";
import UiMultiplierStore from "./UiMultiplierStore";
import WarehouseStore from "./WarehouseStore";
import MarketPlaceStore from "./MarketPlaceStore";
import OrderStore from "./OrderStore";
import OzonStore from "./OzonStore";
import WildberriesStore from "./WildberriesStore";
import YandexStore from "./YandexStore";
import FaqStore from "./FaqStore";
import FinanceStore from "./FinanceStore";
import CollectionsStore from "./CollectionsStore";
import EmailStore from "./EmailStore";

export class RootStore {
  navigationStore = new NavigationStore(this);

  errorStore = new ErrorStore(this);

  toastStore = new ToastStore(this);

  userStore = new UserStore(this);

  loginStore = new LoginStore(this);

  registerStore = new RegisterStore(this);

  forgotStore = new ForgotStore(this);

  uiMultiplierStore = new UiMultiplierStore(this);

  warehouseStore = new WarehouseStore(this);

  marketPlacesStore = new MarketPlaceStore(this);

  orderStore = new OrderStore(this);

  ozonStore = new OzonStore(this);

  wildberriesStore = new WildberriesStore(this);

  yandexStore = new YandexStore(this);

  financeStore = new FinanceStore(this);

  collectionsStore = new CollectionsStore(this);

  faqStore = new FaqStore(this);

  emailStore = new EmailStore(this);
}

export const rootStore = new RootStore();

export const RootStoreContext = createContext(rootStore);
