import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./AboutUsSection.module.scss";
import LogoHeader from "../HomePageImages/LogoHeader.png";

interface Props {
  aboutRef: any;
}

const AboutUsSection: React.FC<Props> = ({ aboutRef }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.MenuSection} ref={aboutRef}>
      <div className={styles.Info}>
        <Link to="/">
          <img src={LogoHeader} alt="Logo" className={styles.Logo} />
        </Link>
        <p>8 (989) 299-10-10</p>
      </div>
      <div className={styles.AboutUsContent}>
        <h1>КАЖДЫЙ МОЖЕТ ТОРГОВАТЬ НА МАРКЕТПЛЕЙСЕ</h1>
        <p>
          RoomSeller - онлайн платформа, которая позволяет продавать на
          маркетплейсах из любой точки мира.
        </p>
        <p>
          Не нужно искать и закупать товар. На платформе уже собраны тысячи
          товаров с фотографиями и описанием для маркетплейсов. Просто добавьте
          товар!
        </p>
        <div className={styles.AboutUsButtons}>
          <button
            className={styles.AuthButton}
            onClick={() => navigate("/register")}
          >
            зарегистрироваться
          </button>
          <button
            className={styles.LoginButton}
            onClick={() => navigate("/login")}
          >
            войти
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
