import { RootStoreContext, rootStore } from "stores/RootStore";
import { observer } from "mobx-react-lite";
import DeleteModal from "components/DeleteModal";
import { DragEvent, createContext } from "react";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import styles from "./addFaqs.module.scss";
import { Accordion, Col, Row } from "react-bootstrap";
import { FAQ } from "stores/FaqStore";
import { EditeChildren } from "./editBtn/editChildren/editeChildren";

export const AddFaqs: FC = observer((): ReactElement => {
  const {
    faqStore: { getFAQs, setFAQs, faqs, deleteFAQ, updateFaq },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [deleteID, setDeleteId] = useState<number>(0);
  const [editId, setEditId] = useState<number>(0);
  const [order, setOrder] = useState<number>(0);
  const [currentFaqs, setCurrentFaqs] = useState<FAQ | null>(null);
  const [initTitle, setInitTitle] = useState<string>("");
  const [initDescp, setInitDescp] = useState<string>("");

  useEffect(() => {
    getFAQs();
  }, [getFAQs]);

  const close = () => {
    setIsOpen(false);
  };
  const deleteClick = (id: number): any => {
    if (faqs) {
      console.log(id);
      deleteFAQ(id).then((i) => {
        if (i) {
          setFAQs(faqs.filter((i) => i.id !== id));
        }
        setIsOpen(false);
      });
    }
  };
  function dragStartHandler(e: DragEvent<HTMLDivElement>, item: FAQ) {
    e.stopPropagation();
    setCurrentFaqs(item);
  }

  function dropHandler(e: any, item: FAQ) {
    e.stopPropagation();
    if (!faqs || !currentFaqs) {
      return;
    }

    updateFaq(
      currentFaqs.order,
      item.order,
      faqs[Number(faqs.indexOf(item)) - 1]
        ? faqs[Number(faqs.indexOf(item)) - 1].order
        : item.order - 1
    ).then(() => {
      getFAQs();
    });

    return item;
  }

  function dragOverHandler(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }
  function dragEndHandler(e: any) {
    e.stopPropagation();
  }
  function dragLeaveHandler(e: any) {
    e.stopPropagation();
  }
  const sortCards = (a: FAQ, b: FAQ) => {
    if (a.order > b.order) {
      return 1;
    } else return -1;
  };

  return (
    <div className="wrapper" onClick={(e) => e.stopPropagation()}>
      <Accordion onClick={(e) => e.stopPropagation()}>
        {faqs &&
          info &&
          faqs
            .slice()
            .sort(sortCards)
            .map((item, id) => {
              return (
                <Accordion.Item
                  eventKey={id.toString()}
                  key={item.id}
                  bsPrefix={styles.accordionButton}
                  onClick={(e) => e.stopPropagation()}
                  draggable={true}
                  onDragStart={(e: DragEvent<HTMLDivElement>) =>
                    dragStartHandler(e, item)
                  }
                  onDragOver={(e: any) => dragOverHandler(e)}
                  onDrop={(e: DragEvent<HTMLDivElement>) =>
                    dropHandler(e, item)
                  }
                  onDragLeave={(e: DragEvent<HTMLDivElement>) =>
                    dragLeaveHandler(e)
                  }
                  onDragEnd={(e: DragEvent<HTMLDivElement>) =>
                    dragEndHandler(e)
                  }
                >
                  <Accordion.Header
                    bsPrefix={styles.accordionButton1}
                    onClick={(e) => e.stopPropagation()}
                    onDragStart={(e: any) => dragStartHandler(e, item)}
                  >
                    <Row className={styles.row}>
                      <Col className={styles.col2}>{item.title}</Col>
                      <Col className={styles.col}>
                        {info?.roles.includes("SuperAdmin") ? (
                          <div>
                            <svg
                              onClick={(e) => {
                                setIsOpenEditModal(true);
                                setEditId(item.id);
                                setOrder(item.order);
                                setInitTitle(item.title);
                                setInitDescp(item.description);
                                e.stopPropagation();
                              }}
                              width="20px"
                              height="25px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                                fill="#0d6efd"
                              />
                            </svg>
                          </div>
                        ) : (
                          ""
                        )}
                        {info?.roles.includes("SuperAdmin") ? (
                          <div>
                            <svg
                              onClick={(e) => {
                                setIsOpen(true);
                                setDeleteId(item.id);
                                e.stopPropagation();
                              }}
                              width="20"
                              height="25"
                              viewBox="0 0 20 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.08704 7.73016V20.0776C1.08704 22.4094 3.06125 24.3836 5.39308 24.3836H14.524C16.8558 24.3836 18.83 22.4094 18.83 20.0776V7.73016C18.83 6.3868 16.5863 6.13181 16.5978 7.88446L16.5994 19.7147C16.6069 21.1641 15.803 22.1527 14.3163 22.1527H5.60045C4.82131 22.1527 4.32526 21.8926 3.92625 21.4922C3.50383 21.0682 3.31298 20.5468 3.31741 19.7147L3.31845 7.93689C3.33159 6.07483 1.08691 6.42036 1.08691 7.73003L1.08704 7.73016Z"
                                fill="#dc3545"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.77908 2.23088H12.137V3.32043H7.77908V2.23088ZM5.49642 1.24515V3.3203H1.08656C-0.375324 3.3203 -0.331742 5.55118 1.03465 5.55118H18.8813C20.2477 5.55118 20.2913 3.3203 18.8294 3.3203H14.4196V1.24515C14.4196 0.531571 13.9417 0 13.2263 0H6.68939C5.974 0 5.49616 0.531571 5.49616 1.24515H5.49642Z"
                                fill="#dc3545"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.0479 9.96094V17.743C11.0479 19.2049 13.2787 19.1613 13.2787 17.7949V9.90917C13.2787 8.54277 11.0479 8.49919 11.0479 9.96107V9.96094Z"
                                fill="#dc3545"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.6377 9.90917V17.7949C6.6377 19.1699 8.86858 19.1948 8.86858 17.743V9.96095C8.86858 8.49907 6.6377 8.54265 6.6377 9.90904V9.90917Z"
                                fill="#dc3545"
                              />
                            </svg>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body className={styles.faqBody}>
                    {item.description}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
      </Accordion>

      {isOpen && (
        <DeleteModal
          text={"Вы уверены, что хотите удалить?"}
          close={close}
          okFunc={() => deleteClick(deleteID)}
        />
      )}

      {isOpenEditModal && (
        <EditeChildren
          isOpen={isOpenEditModal}
          setOpen={setIsOpenEditModal}
          id={editId}
          order={order}
          initTitle={initTitle}
          setInitTitle={setInitTitle}
          initDescp={initDescp}
          setInitDescp={setInitDescp}
        />
      )}
    </div>
  );
});
