import React from "react";
import { observer } from "mobx-react-lite";
import { Document as ReactDocument, Page, pdfjs } from "react-pdf";
import styles from "./Cart.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  printItems: {
    marketPlaceId: number;
    itemId: number;
    file: string;
  }[];
}

/*const PrintContent: React.FC<Props> = observer(({ printItems }) => {
  return (
    <Row className={styles.cartModalRoot}>
      <DocumentPage>
        {printItems
          .filter((i) => i.marketPlaceId === 3)
          .map((i) => {
            return (
              <PageH style={styler.page}>
                <View>
                  <img style={styler.image} src={i.file} />
                </View>
              </PageH>
            );
          })}
        {printItems
          .filter((i) => i.marketPlaceId === 2)
          .map((i) => {
            return (
              <PageH style={styler.page}>
                <View>
                  <ReactDocument file={i.file}>
                    <Page pageNumber={1} renderTextLayer={false} />
                  </ReactDocument>
                </View>
              </PageH>
            );
          })}
        {printItems
          .filter((i) => i.marketPlaceId === 1)
          .map((i) => {
            return (
              <PageH style={styler.page}>
                <View>
                  <ReactDocument file={i.file}>
                    <Page pageNumber={1} renderTextLayer={false} />
                  </ReactDocument>
                </View>
              </PageH>
            );
          })}
      </DocumentPage>
    </Row>
  );
});
export default PrintContent;*/

const PrintContent: React.FC<Props> = observer(({ printItems }) => {
  return (
    <div>
      {printItems
        .filter((i) => i.marketPlaceId === 3)
        .map((i) => {
          return <img src={i.file} />;
        })}
      {printItems
        .filter((i) => i.marketPlaceId === 2 || i.marketPlaceId === 1)
        .map((i) => {
          return (
            <ReactDocument file={i.file}>
              <Page scale={5} pageNumber={1} renderTextLayer={false} />
            </ReactDocument>
          );
        })}
    </div>
  );
});
export default PrintContent;
