import { Dispatch, SetStateAction, useContext, useState } from "react";
import styles from "./replySection.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { QuestionsChat } from "../Home";
interface Props {
  setIsOpenReply: Dispatch<SetStateAction<boolean>>;
  answerID: number;
  questions: QuestionsChat[];
  setQuestions: React.Dispatch<React.SetStateAction<any>>;
}
const ReplySection: React.FC<Props> = observer(
  ({ setIsOpenReply, answerID, questions, setQuestions }) => {
    const {
      warehouseStore: { AnswerProductChat },
    } = useContext(RootStoreContext);
    const [answer, setAnswer] = useState<string>("");
    function addAnswer() {
      if (answer) {
        AnswerProductChat(answerID, answer).then((i) => {
          if (i) {
            setQuestions(questions.filter((i) => i.id !== answerID));
          }
          setIsOpenReply(false);
          setAnswer("");
        });
      }
    }

    return (
      <div className={styles.replySection}>
        <textarea
          placeholder="Ваш ответ..."
          className={styles.replySectionInput}
          onChange={(e) => setAnswer(e.target.value)}
        ></textarea>
        <button
          className={styles.replySectionBtnAnswer}
          onClick={() => addAnswer()}
        >
          Отправить
        </button>
        <button
          className={styles.replySectionBtnCancle}
          onClick={() => setIsOpenReply(false)}
        >
          Отменить
        </button>
      </div>
    );
  }
);

export default ReplySection;
