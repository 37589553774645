import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import styles from "./Email.module.scss";
import { OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import { LuMailPlus } from "react-icons/lu";
import Modal from "../../../components/Modal";
import EmailAddPopup from "./EmailAddPopup";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import DeleteModal from "../../../components/DeleteModal";

const Email: React.FC = observer(() => {
  const {
    emailStore: { getEmails, emails, deleteEmail, setEmails, getSend },
  } = useContext(RootStoreContext);

  const [emailsLoading, setEmailsLoading] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenSendModal, setisOpenSendModal] = useState<boolean>(false);

  const [eHeader, setEHeader] = useState<string>("");
  const [eContent, setEContent] = useState<string>("");
  const [eId, setEId] = useState<number>(0);
  const [eIsHtmlContent, setEIsHtmlContent] = useState<boolean>(false);
  const [eImportance, setEImportance] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [sendId, setSendId] = useState<number>(0);

  useEffect(() => {
    setEmailsLoading(true);
    getEmails().then(() => {
      setEmailsLoading(false);
    });
  }, [getEmails]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Подписки</p>
      <div className={styles.wrapper}>
        <div className={styles.subscribtionsDiv}>
          <Row className={styles.emailAddDiv}>
            {emailsLoading ? (
              <div style={{ width: "fit-content", margin: "auto" }}>
                <BeatLoader
                  color={"#f49100"}
                  loading={emailsLoading}
                  size={50}
                />
              </div>
            ) : (
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Тема</th>
                    <th>Контент</th>
                    <th>Важное</th>
                    <th className={styles.addEmail} style={{ width: "38px" }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Добавить письмо</Tooltip>}
                      >
                        <div className={styles.addEmailIcon}>
                          <LuMailPlus
                            size={38}
                            color={"#fff"}
                            onClick={() => {
                              setEHeader("");
                              setEContent("");
                              setIsEdit(false);
                              setIsOpen(true);
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emails?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.emailHeader}</td>
                        <td>
                          {item.emailContent.slice(0, 36)}
                          {item.emailContent.length > 36 ? "..." : ""}
                        </td>
                        <td>{item.importance ? "Да" : "Нет"}</td>
                        <td className={styles.payCheckbox}>
                          <svg
                            onClick={(e) => {
                              setSendId(item.id);
                              setisOpenSendModal(true);
                            }}
                            fill="#000000"
                            height="30px"
                            width="30px"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 512.001 512.001"
                            xmlSpace="preserve"
                          >
                            <g>
                              <g>
                                <path
                                  d="M501.801,90.455H10.199C4.567,90.455,0,95.021,0,100.654v268.728c0,28.764,23.4,52.165,52.165,52.165h407.671
			c28.764,0,52.165-23.401,52.165-52.165V100.654C512,95.021,507.433,90.455,501.801,90.455z M491.602,369.381
			c0,17.516-14.25,31.766-31.766,31.766H52.165c-17.516,0-31.766-14.251-31.766-31.766V122.906l229.14,187.584
			c1.88,1.538,4.17,2.307,6.461,2.307c2.287,0,4.574-0.767,6.452-2.3l177.306-144.829c4.362-3.564,5.01-9.988,1.447-14.351
			c-3.565-4.363-9.988-5.011-14.351-1.446L256.007,289.423L37.879,110.853h453.723V369.381z"
                                />
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M469.923,127.859c-3.563-4.363-9.99-5.011-14.35-1.447l-3.06,2.499c-4.363,3.564-5.011,9.988-1.448,14.351
			c2.015,2.468,4.948,3.747,7.905,3.747c2.269,0,4.552-0.754,6.445-2.3l3.06-2.499C472.838,138.647,473.486,132.222,469.923,127.859
			z"
                                />
                              </g>
                            </g>
                          </svg>
                        </td>
                        <td>
                          <CiEdit
                            size={38}
                            className={styles.editIcon}
                            onClick={() => {
                              setEId(item.id);
                              setEHeader(item.emailHeader);
                              setEContent(item.emailContent);
                              setEIsHtmlContent(item.isHtmlContent);
                              setEImportance(item.importance);
                              setIsEdit(true);
                              setIsOpen(true);
                            }}
                          />
                        </td>
                        <td style={{ width: "38px" }}>
                          <MdDeleteOutline
                            size={38}
                            onClick={() => {
                              setIsDelete(true);
                              setDeleteId(item.id);
                            }}
                            className={styles.deleteIcon}
                            color={"#f33"}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}

            {isOpenSendModal && emails && (
              <DeleteModal
                text={"Отправить письмо?"}
                okFunc={() => {
                  getSend(sendId);
                  setisOpenSendModal(false);
                }}
                close={() => setisOpenSendModal(false)}
              />
            )}

            {isOpen && (
              <Modal
                className={styles.cartModal}
                children={
                  <EmailAddPopup
                    isEdit={isEdit}
                    eHeader={eHeader}
                    eContent={eContent}
                    eImportance={eImportance}
                    eIsHtmlContent={eIsHtmlContent}
                    id={eId}
                  />
                }
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(false);
                }}
              />
            )}

            {isDelete && emails && (
              <DeleteModal
                text={"Удалить письмо?"}
                close={() => setIsDelete(false)}
                okFunc={() => {
                  deleteEmail(deleteId).then((i) => {
                    if (i) {
                      setEmails(emails?.filter((i) => i.id !== deleteId));
                      setIsDelete(false);
                    }
                  });
                }}
              />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
});
export default Email;
