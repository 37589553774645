import styled from "@emotion/styled";
import React from "react";
import Logo from "assets/icons/logo.png";
import { useNavigate } from "react-router-dom";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  rtl: boolean;
}

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledLogo = styled.div<{ rtl?: boolean; target?: string }>`
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  children,
  rtl,
  ...rest
}) => {
  const navigate = useNavigate();
  return (
    <StyledSidebarHeader {...rest}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <StyledLogo rtl={rtl} target="_blank">
          <a href="/" target="_blank">
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "100px", cursor: "pointer" }}
            />
          </a>
        </StyledLogo>
      </div>
    </StyledSidebarHeader>
  );
};
