import SvgIcon from "components/SvgIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "stores/RootStore";
import {
  CartSvg,
  CollationSvg,
  HeartSvg,
  HomeSvg,
  InvoicesSvg,
  MarketplacesSvg,
  PriceSvg,
  ReturnIcon,
  UsersSvg,
  VideosIcon,
  WarehouseSvg,
} from "components/SvgIcons";
import Logo from "assets/icons/logo.png";

import styles from "./MobileHeader.module.scss";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { HiOutlineMenu } from "react-icons/hi";

interface Props {
  avatar: any;
}

const MobileHeader: React.FC<Props> = observer(({ avatar }) => {
  const {
    warehouseStore: { setFilters, setSearchTextEmpty, getFavoriteCount },
    userStore: { info },
  } = useContext(RootStoreContext);

  useEffect(() => {
    getFavoriteCount();
  }, [getFavoriteCount]);

  const navigate = useNavigate();
  const location = useLocation();
  const menu = [
    { icon: <HomeSvg />, to: "/home", title: "Домой", show: true },
    {
      icon: <MarketplacesSvg />,
      to: "/marketplaces",
      title: "Маркетплейс",
      show: true,
    },
    {
      icon: <WarehouseSvg />,
      to: "/warehouse?pageNumber=1&pageSize=20",
      title: "Склад",
      show: true,
    },
    {
      icon: <CartSvg />,
      to: "/cart?pageNumber=1&pageSize=20",
      title: "Заказы",
      show: true,
    },
    {
      icon: <HeartSvg />,
      to: "/favorites",
      title: "Избранные",
      show: true,
    },
    {
      icon: <CollationSvg />,
      to: "/collations?pageNumber=1&pageSize=20",
      title: "Сопоставления",
      show: true,
    },
    {
      icon: <PriceSvg />,
      to: "/priceHistory",
      title: "Изменения цен",
      show: true,
    },
    {
      icon: <UsersSvg />,
      to: "/users",
      title: "Пользователи",
      show: info?.roles.includes("SuperAdmin") ? true : false,
    },
    {
      icon: <InvoicesSvg />,
      to: "/invoices",
      title: "Накладные",
      show: true,
    },
    {
      icon: <ReturnIcon />,
      to: "/returns",
      title: "Возвраты",
      show: true,
    },
    {
      icon: <VideosIcon />,
      to: "/videos",
      title: "Видео",
      show: true,
    },
    /*{
      icon: <StatisticsSvg />,
      to: "/statistics",
      title: "Графики",
      show: true,
    },*/
    //{ icon: <FinanceSvg />, to: "/finance", title: "Финансы", show: true },
    // { icon: <SettingsSvg />, to: "/settings", title: "Настройки" }
  ];

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Link to="/" target="_blank">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className={styles.seedDials}>
          <div className={styles.avatar}>{avatar}</div>
          <div className={styles.menu}>
            <SpeedDial
              className="AvatarSpeedDial"
              direction="down"
              ariaLabel="Avatar"
              icon={<HiOutlineMenu size={38} />}
            >
              {menu.map((item) => (
                <SpeedDialAction
                  key={item.title}
                  icon={
                    <div className={styles.icon}>
                      <SvgIcon
                        size={1.6}
                        color={
                          location.pathname === item.to ||
                          location.pathname.search(item.to) !== -1
                            ? "var(--fill-primary)"
                            : "var(--fill-medium-emphasis)"
                        }
                        component={item.icon}
                      />
                    </div>
                  }
                  onClick={() => {
                    if (
                      item.to === "/favorites" ||
                      item.to === "/warehouse?pageNumber=1&pageSize=20"
                    ) {
                      navigate(item.to);
                      setSearchTextEmpty(true);
                      setFilters([
                        { name: "pageSize", value: "20", label: "20" },
                        { name: "pageNumber", value: "1", label: "1" },
                      ]);
                    } else {
                      navigate(item.to);
                    }
                  }}
                  tooltipTitle={item.title}
                />
              ))}
            </SpeedDial>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MobileHeader;
