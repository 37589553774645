import React, { Suspense, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { observer } from "mobx-react-lite";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./scenes/Home";
import { Toaster } from "react-hot-toast";
import "./custom.scss";
import Login from "./scenes/Auth/Login";
import Forgot from "./scenes/Auth/Forgot";
import Register from "./scenes/Auth/Register";
import Dashboard from "./scenes/Dashboard";
import { RootStoreContext } from "./stores/RootStore";
import ResetPassword from "./scenes/Auth/ResetPassword";
import SuccessfullyRegistered from "./scenes/Auth/SuccessfullyRegistered";
import Suppliers from "./scenes/Suppliers";

const App: React.FC = observer(() => {
  const navigate = useNavigate();
  const {
    userStore: { isAuthorized },
    navigationStore: { path, setPath },
  } = useContext(RootStoreContext);

  useEffect(() => {
    if (!path) {
      return;
    }

    navigate(path);
    setPath(null);
  }, [navigate, path, setPath]);

  const toastOptions: any = {
    style: {
      backgroundColor: "#fff",
      borderRadius: "var(--border-radius-2)",
    },
  };
  return (
    // <>
    <Suspense fallback={"loading"}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/login" element={<Login />} />
        {/*<Route path="/register" element={<FirstStep />} />*/}
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/successfully-registered"
          element={<SuccessfullyRegistered />}
        />

        <Route path="/*" element={<Dashboard user={isAuthorized} />} />
      </Routes>
      <Toaster toastOptions={toastOptions} position="top-right" />
    </Suspense>
    // </>
  );
});

export default App;
