import { Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import styles from "./Collations.module.scss";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import CustomInput from "../../../components/CustomInput";
import { RootStoreContext } from "../../../stores/RootStore";
import { toast } from "react-hot-toast";

interface Props {
  productId: string;
  marketplaceId: number;
  supplierVendorCode: string;
}

const CollatePopup: React.FC<Props> = observer(
  ({ productId, marketplaceId, supplierVendorCode }) => {
    const {
      warehouseStore: { collateProduct },
    } = useContext(RootStoreContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [vendorCode, setVendorCode] = useState<string>(supplierVendorCode);
    const collateClick = () => {
      setLoading(true);
      collateProduct(productId, marketplaceId, vendorCode).then((i) => {
        if (i) {
          toast.success("Товар сопоставлен");
        }
        setLoading(false);
      });
    };
    return (
      <Row className={styles.CollateModalRoot}>
        <CustomInput
          name={"collate"}
          placeholder={"Артикул"}
          value={vendorCode}
          onChange={(e) => setVendorCode(e.target.value)}
        />
        <Button
          text="Сопоставить"
          className={loading ? styles.collateButtonLoad : styles.collateButton}
          onClick={() => collateClick()}
        />
      </Row>
    );
  }
);

export default CollatePopup;
