import { Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import styles from "./ProductItem.module.scss";

interface Props {
  productID: string;
  description: string;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditCollectionDesc: React.FC<Props> = observer(
  ({ productID, description, setClose }) => {
    const {
      collectionsStore: { editCollectionDescription },
      warehouseStore: { setProduct, product },
    } = useContext(RootStoreContext);

    const [editDescription, setEditDescription] = useState<string>(description);
    const [loading, setLoading] = useState<boolean>(false);

    const editDesc = () => {
      setLoading(true);
      editCollectionDescription(productID, editDescription).then((i) => {
        if (i) {
          setProduct({ ...product, description: editDescription });
        }
        setLoading(false);
      });
    };
    return (
      <Row className={styles.editCollectionDescModalRoot}>
        <textarea
          className={styles.collectionEditInput}
          name="collections"
          placeholder="Описание набора..."
          onChange={(e) => {
            setEditDescription(e.target.value);
          }}
          value={editDescription}
        />

        <Button
          text="Изменить описание набора"
          className={
            loading
              ? styles.addToCollectionButtonLoad
              : styles.addToCollectionButton
          }
          onClick={() => {
            setClose(false);
            editDesc();
          }}
          disabled={editDescription === description || editDescription === ""}
        />
      </Row>
    );
  }
);

export default EditCollectionDesc;
