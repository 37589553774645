import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./SuppliersSection.module.scss";
import LogoHeader from "../../Home/HomePageImages/LogoHeader.png";
import Modal from "../../../components/Modal";
import Application from "./application";

export const SuppliersSection = () => {
  const navigate = useNavigate();

  const [applicationIsOpen, setApplicationIsOpen] = useState<boolean>(false);
  return (
    <>
      <div className={styles.SuppliersSection}>
        <div className={styles.Info}>
          <Link to="/">
            <img src={LogoHeader} alt="Logo" className={styles.Logo} />
          </Link>
          <p>8 (989) 299-10-10</p>
        </div>

        <div className={styles.SuppliersSectionContent}>
          <h1>ПОСТАВЩИКАМ</h1>
          <div className={styles.SuppliersSectionContentText}>
            <p>
              Если вы производитель или оптовая компания и продаете на
              маркетплейсах, то платформа RoomSeller поможет увеличить ваши
              продажи.
            </p>
            <p className={styles.SuppliersSectionContentP}>
              RoomSeller — это онлайн платформа, на которую вы (производители и
              оптовые компании) загружаете свои товары, а продавцы маркетплейсов
              размещают их у себя в магазине. Таким образом, ваши товары продают
              сотни продавцов.
            </p>
            <p>Таким образом, ваши товары продают сотни продавцов</p>
          </div>
          <div className={styles.SupplierSectionButtons}>
            <button
              className={styles.AuthButton}
              onClick={() => setApplicationIsOpen(true)}
            >
              зарегистрироваться
            </button>
            <button
              className={styles.LoginButton}
              onClick={() => navigate("/login")}
            >
              войти
            </button>
          </div>
        </div>
      </div>
      {applicationIsOpen && (
        <Modal
          className={styles.applicationModal}
          children={<Application setApplicationIsOpen={setApplicationIsOpen} />}
          isOpen={applicationIsOpen}
          onClose={() => {
            setApplicationIsOpen(false);
          }}
        />
      )}
    </>
  );
};
