import { Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import styles from "./ProductItem.module.scss";
import { toast } from "react-hot-toast";
import CustomSelect from "../../../components/CustomSelect";
import BeatLoader from "react-spinners/BeatLoader";
import CustomInput from "../../../components/CustomInput";

interface Props {
  productID: string;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
  warehouseID: number;
}

const AddProductToCollection: React.FC<Props> = observer(
  ({ productID, setClose, warehouseID }) => {
    const {
      collectionsStore: { addProductToCollection, getCollections, collections },
    } = useContext(RootStoreContext);

    const [collectionID, setCollectionID] = useState<string>("");

    const [quantity, setQuantity] = useState<number>(1);

    const [collectionsLoading, setCollectionsLoading] =
      useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      setCollectionsLoading(true);
      getCollections(warehouseID).then((i) => {
        setCollectionsLoading(false);
      });
    }, [getCollections, warehouseID]);

    const addProduct = () => {
      setLoading(true);
      addProductToCollection(collectionID, productID, quantity).then((i) => {
        console.log(i);
        if (i) {
          toast.success(i, { duration: 5000 });
        } else {
          toast.error("Товар уже добавлен в данный набор");
        }
        setLoading(false);
      });
    };
    return (
      <Row className={styles.addToCollectionModalRoot}>
        {collectionsLoading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={true} size={25} />
          </div>
        ) : (
          <div className={styles.addToCollectionSelect}>
            <CustomSelect
              name="collections"
              placeholder="Наборы"
              onChange={(e) => {
                setCollectionID(e.value);
              }}
              value={collectionID}
              list={
                collections?.map((item) => ({
                  value: item.id,
                  label: item.title,
                })) as any
              }
            />
            <CustomInput
              placeholder="Количество"
              name="quantity"
              value={quantity}
              onChange={(e) => {
                const value = e.target.value;
                if (!Number(isFinite(Number(value)))) {
                  return;
                }
                if (Number(value) < 0) {
                  return;
                }
                setQuantity(Number(value));
              }}
              className={styles.addToCollectionCount}
            />
          </div>
        )}

        <Button
          text="Добавить товар в набор"
          className={
            loading
              ? styles.addToCollectionButtonLoad
              : styles.addToCollectionButton
          }
          onClick={() => {
            setClose(false);
            addProduct();
          }}
          disabled={collectionID === "" || loading}
        />
      </Row>
    );
  }
);

export default AddProductToCollection;
