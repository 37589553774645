import * as React from "react";
import "./index.scss";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiImageAdd } from "react-icons/bi";

interface Props {
  image: string;
  zoom: string;
  isCollection: boolean;
  isImage: boolean;
  addImage: (file: any, productID: string) => void;
  productID: string;
}

const ZoomPreview: React.FC<Props> = ({
  image,
  zoom,
  isImage,
  isCollection,
  productID,
  addImage,
}) => {
  const onMouseMove = (e: React.MouseEvent<HTMLElement>): void => {
    const target = e.currentTarget as HTMLElement;
    const x = (e.nativeEvent.offsetX / target.offsetWidth) * 100;
    const y = (e.nativeEvent.offsetY / target.offsetHeight) * 100;
    target.style.backgroundPosition = x + "% " + y + "%";
  };

  const onMouseEnter = (e: React.MouseEvent<HTMLElement>): void => {
    const target = e.currentTarget;
    const zoom = target.getAttribute("data-zoom");
    const $img = target.querySelector("img");

    if ($img) {
      ($img.nextSibling as HTMLElement).style.display = "block";
    }

    if (zoom) {
      const temp = new Image();
      temp.src = zoom;
      temp.onload = () => {
        target.style.backgroundImage = `url(${zoom})`;
        if ($img) {
          $img.style.opacity = "0";
          ($img.nextSibling as HTMLElement).style.display = "none";
        }
      };
    }
  };

  const onMouseLeave = (e: React.MouseEvent<HTMLElement>): void => {
    const target = e.currentTarget;
    const $img = target.querySelector("img");
    target.removeAttribute("style");
    if ($img) {
      $img.style.opacity = "1";
      ($img.nextSibling as HTMLElement).style.display = "none";
    }
  };
  const onChange = (e: any) => {
    addImage(e.target.files[0], productID);
  };
  return (
    <div
      className="pdp-slider__galleries zoom"
      data-zoom={zoom}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isImage && (
        <OverlayTrigger overlay={<Tooltip>Скачать фотографию</Tooltip>}>
          <a href={image} download className="downloadIcon">
            <AiOutlineCloudDownload size={32} />
          </a>
        </OverlayTrigger>
      )}
      {isCollection && (
        <OverlayTrigger overlay={<Tooltip>Добавить фотографию</Tooltip>}>
          <span className="addIcon">
            <label htmlFor="file-input">
              <BiImageAdd size={32} />
            </label>
          </span>
        </OverlayTrigger>
      )}
      <input
        id="file-input"
        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
        onChange={(e) => {
          onChange(e);
          e.target.value = "";
        }}
        type="file"
        style={{ display: "none" }}
      />
      <img alt="" src={image} />
      <div className="zoom__loading" />
    </div>
  );
};

export default ZoomPreview;
