import React, { ReactChild } from "react";
import classnames from "classnames";

import styles from "./Button.module.scss";

interface Props {
  children?: ReactChild;
  variant?:
    | "primary"
    | "accessory"
    | "dropdown"
    | "select"
    | "outlined"
    | "round"
    | "link";
  size?: "small" | "big" | "normal" | "huge";
  text?: string;
  name?: string;
  textColor?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseOver?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onFocus?: () => void;
  className?: string;
  disabled?: boolean;
  borderRadius?: "radius1" | "radius2" | "radius3";
  color?: "primary" | "secondary" | "error" | "transparent";
  type?: "button" | "submit";
  fullWidth?: boolean;
  loading?: boolean;
  stopPropagation?: boolean;
}

const Button: React.FC<Props> = ({
  children,
  text,
  variant,
  size = "normal",
  onClick,
  onMouseOver,
  onMouseLeave,
  onFocus,
  name,
  className = "",
  textColor = "",
  disabled = false,
  color = "primary",
  borderRadius = "radius3",
  type,
  fullWidth,
  loading,
  stopPropagation,
}) => {
  return (
    <button
      type={type === "submit" ? "submit" : "button"}
      name={name}
      className={classnames(className, styles.button, styles[textColor], {
        [styles[`${variant}-variant`]]: true,
        [styles[size]]: styles[size],
        [styles[color]]: styles[color],
        [styles[borderRadius]]: borderRadius,
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
      })}
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }

        if (!loading && onClick) {
          onClick(e);
        }
      }}
      onMouseOver={(e) => {
        if (onMouseOver) {
          onMouseOver(e);
        }
      }}
      onMouseLeave={(e) => {
        if (onMouseLeave) {
          onMouseLeave(e);
        }
      }}
      onFocus={onFocus}
      disabled={disabled}
    >
      <div
        className={classnames(styles.content, styles.buttonContent, {
          [styles.hide]: loading,
        })}
      >
        {children}
        <span>{text}</span>
      </div>

      {loading && <div className={styles.spinner}>loading</div>}
    </button>
  );
};

export default Button;
