import { rootStore } from "stores/RootStore";

const handleInternalServerError = ({
  error_id,
  error_message,
  error_code,
}: ErrorResponse): void => {
  rootStore.errorStore.setErrorId(error_id);
  rootStore.errorStore.setErrorMessage(error_message);
  rootStore.errorStore.setErrorCode(error_code);

  rootStore.toastStore.notify.error(error_message);
};

export default handleInternalServerError;
