import React from "react";
import styles from "./ToggleSwitch.module.scss";
import { TbPlugConnected, TbPlugConnectedX } from "react-icons/tb";

interface Props {
  checked: boolean;
  onChange: () => void;
  id?: string;
  name: string;
  color?: "primary" | "secondary";
  leftText?: string;
  rightText?: string;
  width?: string;
  cursor?: string;
  icons?: boolean;
}
const ToggleSwitch: React.FC<Props> = ({
  checked,
  onChange,
  id,
  name,
  leftText,
  rightText,
  color = "primary",
  width = "11.45rem",
  cursor = "pointer",
  icons = true,
}) => {
  return (
    <div
      style={{ width: width, cursor: cursor }}
      className={`${styles.toggleSwitch} ${
        color === "primary" ? styles.primary : styles.secondary
      }`}
    >
      <input
        onChange={onChange}
        type="checkbox"
        className={styles.toggleSwitchCheckbox}
        name={name}
        id={id}
        checked={checked}
      />
      <div onClick={onChange} className={styles.toggleSwitchLabel}>
        <span className={styles.toggleSwitchInner} />
        <span className={styles.toggleSwitchSwitch} />
      </div>
      <div className={styles.texts} onClick={onChange}>
        <p className={`${checked ? "" : styles.active}`}>
          {icons ? <TbPlugConnectedX size={32} /> : ""}
        </p>
        <p className={`${checked ? styles.active : ""}`}>
          {icons ? <TbPlugConnected size={32} /> : ""}
        </p>
      </div>
    </div>
  );
};

export default ToggleSwitch;
