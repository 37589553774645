import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";

class ErrorStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  errorId = "";

  errorMessage = "";

  errorCode = "";

  setErrorId = (value: string): void => {
    this.errorId = value;
  };

  setErrorMessage = (value: string): void => {
    this.errorMessage = value;
  };

  setErrorCode = (value: string): void => {
    this.errorCode = value;
  };
}

export default ErrorStore;
