import { Row, Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Users.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import ToggleSwitch from "components/ToggleSwitch";
import toast from "react-hot-toast";
import BeatLoader from "react-spinners/BeatLoader";
import Modal from "../../../components/Modal";

interface InfoUser {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  mpWarehouseID: number;
  roles: string[];
  documentUrls: string[];
}
const Users: React.FC = observer(() => {
  const {
    userStore: {
      getAllUsers,
      allUsers,
      setAllUsers,
      getSuspendUser,
      getReactivateUser,
      setStockState,
      getUserInfo,
      getUserPopupInfo,
      userPopupInfo,
    },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(true);
  const [statusLoad, setStatusLoad] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getAllUsers().then((users) => {
      if (users) {
        setLoading(false);
      }
    });
  }, [setLoading, getAllUsers]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Пользователи</p>
      <div className={styles.wrapper}>
        <div className={styles.dates}>
          {loading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={loading} size={50} />
            </div>
          ) : (
            <Row>
              <Table striped hover>
                <thead>
                  <tr>
                    <th colSpan={2}>Имя пользователя</th>
                    <th>Ozon</th>
                    <th>Wildberries</th>
                    <th>Yandex</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers &&
                    allUsers.map((item, index) => (
                      <tr key={index}>
                        <td
                          onClick={() => {
                            setOpen(true);
                            setLoadingUserInfo(true);
                            getUserPopupInfo(item.id).then((info) => {
                              setStatusLoad(false);
                              if (info) {
                                setLoadingUserInfo(false);
                              }
                            });
                          }}
                        >
                          {item.lastName + " " + item.firstName}
                        </td>

                        <td>
                          <ToggleSwitch
                            checked={item.userStatus === 0 ? true : false}
                            cursor={statusLoad ? "progress" : "pointer"}
                            onChange={() => {
                              setStatusLoad(true);
                              if (item.userStatus === 0) {
                                getSuspendUser(item.id).then((res) => {
                                  if (res) {
                                    setAllUsers(
                                      allUsers.map((el) =>
                                        el.id === item.id
                                          ? { ...el, userStatus: 1 }
                                          : el
                                      )
                                    );
                                    toast.success("Аккаунт отключён");
                                  }
                                  setStatusLoad(false);
                                });
                              } else {
                                getReactivateUser(item.id).then((res) => {
                                  if (res) {
                                    setAllUsers(
                                      allUsers.map((el) =>
                                        el.id === item.id
                                          ? { ...el, userStatus: 0 }
                                          : el
                                      )
                                    );
                                    toast.success("Аккаунт подключён");
                                  }
                                  setStatusLoad(false);
                                });
                              }
                            }}
                            name="import"
                            leftText="Отключить"
                            rightText="Подключить"
                          />
                        </td>
                        {["Ozon", "Wildberries", "YandexMarket"].map(
                          (marketplace) => {
                            return (
                              <td key={marketplace}>
                                {item.marketPlaces.filter(
                                  (i) => i.marketPlaceName === marketplace
                                ).length
                                  ? item.marketPlaces
                                      .filter(
                                        (i) => i.marketPlaceName === marketplace
                                      )[0]
                                      .marketPlaceStocks.map((stock) => {
                                        return (
                                          <div className={styles.stockSwitch}>
                                            <p>{stock.stockName}</p>
                                            <ToggleSwitch
                                              checked={stock.stockState}
                                              cursor={
                                                statusLoad
                                                  ? "progress"
                                                  : "pointer"
                                              }
                                              onChange={() => {
                                                setStatusLoad(true);
                                                setStockState(
                                                  stock.stockId,
                                                  !stock.stockState
                                                ).then((res) => {
                                                  if (res) {
                                                    setAllUsers(
                                                      allUsers.map((el) =>
                                                        el.id === item.id
                                                          ? {
                                                              ...el,
                                                              marketPlaces: [
                                                                ...item.marketPlaces.filter(
                                                                  (i) =>
                                                                    i.marketPlaceName !==
                                                                    marketplace
                                                                ),
                                                                {
                                                                  id: 1,
                                                                  marketPlaceName:
                                                                    marketplace,
                                                                  marketPlaceStocks:
                                                                    [
                                                                      ...item.marketPlaces
                                                                        .filter(
                                                                          (i) =>
                                                                            i.marketPlaceName ===
                                                                            marketplace
                                                                        )[0]
                                                                        .marketPlaceStocks.filter(
                                                                          (s) =>
                                                                            s.stockId !==
                                                                            stock.stockId
                                                                        ),
                                                                      {
                                                                        stockId:
                                                                          stock.stockId,
                                                                        stockName:
                                                                          stock.stockName,
                                                                        stockState:
                                                                          !stock.stockState,
                                                                      },
                                                                    ],
                                                                },
                                                              ],
                                                            }
                                                          : el
                                                      )
                                                    );
                                                    toast.success(
                                                      stock.stockName +
                                                        !stock.stockState
                                                        ? "подключён"
                                                        : "отключён"
                                                    );
                                                  }
                                                  setStatusLoad(false);
                                                });
                                              }}
                                              name="import"
                                              leftText="Отключить"
                                              rightText="Подключить"
                                            />
                                          </div>
                                        );
                                      })
                                  : ""}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Row>
          )}
        </div>
      </div>
      {open && (
        <Modal
          isOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          children={
            <div className={styles.modalWindow}>
              {loadingUserInfo ? (
                <div style={{ width: "fit-content", margin: "auto" }}>
                  <BeatLoader
                    color={"#f49100"}
                    loading={loadingUserInfo}
                    size={38}
                  />
                </div>
              ) : userPopupInfo ? (
                <Table striped hover responsive>
                  <tbody>
                    <tr>
                      <td colSpan={2} style={{ textAlign: "center" }}>
                        <b>Личные данные</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Имя</b>
                      </td>
                      <td>{userPopupInfo?.firstName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Фамилия</b>
                      </td>
                      <td>
                        {userPopupInfo?.lastName
                          ? userPopupInfo?.lastName
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Почта</b>
                      </td>
                      <td>{userPopupInfo?.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Номер телефона</b>
                      </td>
                      <td>
                        {userPopupInfo?.phoneNumber
                          ? userPopupInfo?.phoneNumber
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Адрес регистрации</b>
                      </td>
                      <td>
                        {userPopupInfo?.phoneNumber
                          ? userPopupInfo?.phoneNumber
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Роль</b>
                      </td>
                      <td>
                        {userPopupInfo?.roles.includes("WarehouseOwner")
                          ? "Владелец склада"
                          : "Продавец"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>ИНН</b>
                      </td>
                      <td>{userPopupInfo?.tin ? userPopupInfo?.tin : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Код подразделения</b>
                      </td>
                      <td>
                        {userPopupInfo?.departmentCode
                          ? userPopupInfo?.departmentCode
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Кем выдан</b>
                      </td>
                      <td>
                        {userPopupInfo?.issuedBy
                          ? userPopupInfo?.issuedBy
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Наименование банка</b>
                      </td>
                      <td>
                        {userPopupInfo?.bankName
                          ? userPopupInfo?.bankName
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Корреспондентский счет</b>
                      </td>
                      <td>
                        {userPopupInfo?.correspAccount
                          ? userPopupInfo?.correspAccount
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Номер счета в банке</b>
                      </td>
                      <td>
                        {userPopupInfo?.correspAccount
                          ? userPopupInfo?.correspAccount
                          : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                ""
              )}
            </div>
          }
        />
      )}
    </div>
  );
});
export default Users;
