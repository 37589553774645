import { makeAutoObservable } from "mobx";
import { toast } from "react-hot-toast";
import { RootStore } from "./RootStore";

class ToastStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  notify = {
    success: toast.success,
    error: toast.error,
    info: toast,
    loading: toast.loading,
    promise: toast.promise,
  };
}

export default ToastStore;
