import {
  useRef,
  FC,
  ReactElement,
  SetStateAction,
  Dispatch,
  useContext,
} from "react";
import styles from "./modal.module.scss";
import { useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types";
import Button from "../../../../../components/Button/Button";
import { observer } from "mobx-react-lite";
import FaqStore from "../../../../../stores/FaqStore";
import { RootStoreContext, rootStore } from "stores/RootStore";
import { Close } from "@mui/icons-material";
import { Context } from "../../Faq";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setReRender: Dispatch<SetStateAction<boolean>>;
};
interface FaqForm {
  question: string;
  answer: string;
  order: number;
}
export const Modal: FC<Props> = observer(
  ({ open, setOpen, setReRender }): ReactElement => {
    const {
      faqStore: { addFAQ, setFAQs, faqs },
    } = useContext(RootStoreContext);
    const menuRef = useRef<HTMLInputElement>(null);
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
      clearErrors,
    } = useForm<FaqForm>({
      mode: "onBlur",
      defaultValues: {
        question: "",
        answer: "",
        order: 0,
      },
    });
    const value = useContext(Context);
    const onSubmit: SubmitHandler<FaqForm> = (data: any) => {
      setReRender(true);
      addFAQ(data.question, data.answer, data.order).then((faq) => {
        if (faq && faqs) {
          setFAQs([
            ...faqs,
            {
              id: faq.id,
              description: data.answer,
              title: data.question,
              order: faq.order,
            },
          ]);
        }
      });
      value.setReRender(true);
      reset();
      setOpen(false); /*ստեղծել կոճակը սեղմելուց հետո մոդալն ավտոմատ փակվի*/
    };

    function handlClick(): void {
      setOpen(false);
      clearErrors();
      reset();
    }

    return (
      <div>
        <div
          className={open ? styles.modal : styles.close}
          onClick={(e: any) => {
            if (!menuRef.current?.contains(e.target)) {
              setOpen(false);
              clearErrors();
              reset();
              e.stopPropagation();
            }
          }}
        >
          <div
            className={styles.modalContent}
            ref={menuRef}
            onClick={(e: any) => {
              if (menuRef.current?.contains(e.target)) {
                clearErrors();
                e.stopPropagation();
              }
            }}
          >
            <form
              className={styles.modalContentForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Close className={styles.closeModal} onClick={handlClick} />
              <p className={styles.modalContentFormTitle}>Добавить вопрос</p>
              <input
                type="text"
                className={styles.modalContentFormInput}
                placeholder="Добавить вопрос"
                {...register("question", {
                  required: "Это поле не может быть пустым",
                })}
              />

              <div className={styles.errors}>
                {errors?.question && (
                  <p className={styles.errorsText}>
                    {errors?.question?.message || "Error"}
                  </p>
                )}
              </div>

              <textarea
                rows={6}
                cols={40}
                placeholder="Добавить oтвет"
                className={styles.modalContentFormTextarea}
                {...register("answer", {
                  required: "Это поле не может быть пустым",
                })}
              />
              <div className={styles.errors}>
                {errors?.answer && (
                  <p className={styles.errorsText}>
                    {errors?.answer?.message || "Error"}
                  </p>
                )}
              </div>

              <Button
                text="Cоздать"
                color="primary"
                className={styles.modaleAdd}
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
);
