import React from "react";
import classnames from "classnames";

import styles from "./Icon.module.scss";

export type IconVariant =
  | "add"
  | "arrow-left"
  | "arrow-right"
  | "avatar"
  | "visible"
  | "invisible-eye"
  | "edit"
  | "bin"
  | "tick"
  | "search"
  | "heart"
  | "dropdown-arrow"
  | "cross"
  | "cancel"
  | "ok"
  | "upload-image"
  | "arrow-up";

export type IconSize =
  | "small"
  | "normal"
  | "lite"
  | "medium"
  | "big"
  | "huge"
  | "extra-huge"
  | "extra-big";

interface Props {
  variant: IconVariant;
  size: IconSize;
  className?: string;
  onClick?: () => void;
  cursor?: boolean;
  color?: "blue" | "default";
  opacity?: string;
  rotate?: "rotate180" | "rotate90" | "";
  contentMargin?: "right04" | "right08" | "left04" | "left12";
  customSize?: number;
  disabled?: boolean;
  title?: string;
}

const Icon: React.FC<Props> = ({
  variant,
  size,
  className = "",
  onClick,
  cursor = false,
  color = "default",
  opacity = "1",
  rotate = "",
  contentMargin = "",
  title = "",
  customSize,
  disabled,
}) => {
  return (
    <div
      data-variant={variant}
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
      style={{
        opacity,
        ...(customSize
          ? {
              width: `${customSize}rem`,
              height: `${customSize}rem`,
              minWidth: `${customSize}rem`,
              minHeight: `${customSize}rem`,
            }
          : {}),
      }}
      className={classnames(
        styles.wrapper,
        styles[color],
        styles[rotate],
        styles[contentMargin],
        className,
        {
          [styles[variant]]: styles[variant],
          [styles[size]]: styles[size],
          [styles.cursor]: cursor || !!onClick,
        }
      )}
      title={title}
      role="img"
    />
  );
};

export default Icon;
