import React, { useContext, useEffect, useState } from "react";
import styles from "./Analytics.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { useSearchParams } from "react-router-dom";
import AnalyticsTable from "./AnalyticsTable";
import AnalyticsFilters from "./AnalyticsFilters";

const Analytics: React.FC = observer(() => {
  const {
    orderStore: { getSellers },
    marketPlacesStore: { getAnalyticsData, analytics },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const seller = info?.roles.includes("SuperAdmin")
    ? searchParams.get("seller")
    : info?.id;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  useEffect(() => {
    if (seller && startDate && endDate) {
      setLoading(true);
      getSellers();
      getAnalyticsData(seller, startDate, endDate).then(() => {
        setLoading(false);
      });
    }
  }, [
    getAnalyticsData,
    setSearchParams,
    getSellers,
    seller,
    startDate,
    endDate,
  ]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Аналитика</p>
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <AnalyticsFilters />
        </div>
        <AnalyticsTable loading={loading} info={info} analytics={analytics} />
      </div>
    </div>
  );
});
export default Analytics;
