import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import extendDefaultHeaders from "../utils/extendDefaultHeaders";
import handleApiPath from "../utils/handleApiPath";
import { toast } from "react-hot-toast";

interface CreateSessionError {
  error_id: string;
  error_message: string;
  error_fields: Array<{
    code: string;
    name: string;
    message: string;
  }>;
  error_code: string;
  status_code?: number;
}

interface IFinanceSellers {
  [key: string]: string;
}

interface IFinanceActs {
  [key: string]: string;
}

class FinanceStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  financeSellers: [string, any][] | null = null;

  acts: [string, string][] | null = null;

  filters: { name: string; value: string; label: string }[] = [
    { name: "pageSize", value: "20", label: "20" },
  ];

  errorData: CreateSessionError | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setFinanceSellers = (data: IFinanceSellers[]): void => {
    this.financeSellers = Object.entries(data);
  };

  setActs = (data: [string, string][]): void => {
    this.acts = data;
  };

  setErrorData = (error: CreateSessionError | null): void => {
    this.errorData = error;
  };

  getWarehouseReconciliationActs = async (
    userId: string,
    mpWarehouseID: number
  ): Promise<any | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FINANCE");
      const response = await fetch(
        `${endpoint}/GetWarehouseReconciliationActs?mpWarehouseID=${mpWarehouseID}&userId=${userId}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setActs(Object.entries(data));
      }

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getSellers = async (): Promise<IFinanceSellers | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FINANCE");
      const response = await fetch(`${endpoint}/GetSellers`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        this.setFinanceSellers(data);
      }

      return data;
    } catch (error) {
      return null;
    }
  };

  uploadFinance = async (
    file: any,
    userId: string,
    mpWarehouseID: number
  ): Promise<string | null> => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("file", file);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FINANCE");
      const response = await fetch(
        `${endpoint}?mpWarehouseID=${mpWarehouseID}&userId=${userId}`,
        requestOptions
      );

      const data = await response.text();

      if (response.ok) {
        toast.success("Документ загружен");
        return data;
      } else {
        toast.error("Произошла ошибка");
        return "";
      }
    } catch (error) {
      toast.error("Произошла ошибка 1");
      return "";
    }
  };

  deleteFinanceAct = async (
    actId: string,
    userID: string
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "DELETE",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FINANCE");
      const response = await fetch(
        `${endpoint}/DeleteReconciliationAct?actId=${actId}&userID=${userID}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  uploadPaymetsOrders = async (file: any) => {
    const { userStore } = this.rootStore;

    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
      },
      body: formData,
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FINANCE");
      const response = await fetch(
        `${endpoint}/UploadPaymetsOrders`,
        requestOptions
      );
      if (response.ok) {
        toast.success("Документ загружен");
        return true;
      } else {
        toast.error("Произошла ошибка");
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return false;
    }
  };
}

export default FinanceStore;
