import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { FormikErrors, FormikValues, useFormik } from "formik";
import { ReactComponent as Wallpaper } from "assets/icons/login-wallpaper.svg";
import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";

import styles from "./SuccessfullyRegistered.module.scss";

const SuccessfullyRegistered: React.FC = observer(() => {
  const {
    loginStore: { login },
  } = useContext(RootStoreContext);

  const navigate = useNavigate();
  const { values } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: async () => {
      const errors: FormikErrors<FormikValues> = {};
      return errors;
    },
    onSubmit: async () => {
      login(values.email, values.password);
    },
  });

  return (
    <div className={styles.root}>
      <Wallpaper className={styles.wallpaper} />
      <div className={styles.content}>
        <div className={styles.window}>
          <p className={styles.title}>Поздравляем</p>
          <p className={styles.subtitle}>Вы успешно зарегистрированы.</p>
          <Button
            text="Ок"
            fullWidth
            onClick={() => {
              navigate("/login");
            }}
            color="primary"
            className={styles.back}
          />
        </div>

        <div className={styles.options}>
          <Link to="/">На главную</Link>
          {/* <Link to="/">Помощь</Link>
          <div className={styles.divider} />
          <Link to="/">Политика конфиденциальности</Link> */}
        </div>
      </div>
    </div>
  );
});
export default SuccessfullyRegistered;
