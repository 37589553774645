import { Col, Container, Row } from "react-bootstrap";
import styles from "./returnsSection.module.scss";
import { DiagramOne } from "./diagramOne";
import { DiagramTwo } from "./diagramTwo";
import { Dot } from "./dot";

export const ReturnsSection = () => {
  const returnsAccpected = [
    "Целостность упаковки не нарушена, товар без  повреждений",
    " Заводской брак ",
    "Отправлен не тот товар, который был указан в заказе",
  ];

  const returnRefused = [
    "Товар поврежден при транспортировке после сдачи на Маркетплейс ",
    "Вернули не тот товар, который был отправлен Поставщиком ",
    "Упаковка товара повреждена, повторная реализация невозможна ",
    "Товар поврежден при эксплуатации",
  ];
  return (
    <div className={styles.returnsSection}>
      <h1>ВОЗВРАТЫ</h1>
      <p>Критерии работы с возвратами из маркетплейсов</p>
      <Container className={styles.returnsConditions}>
        <Row>
          <Col className={styles.returnsConditation}>
            <DiagramOne />
            <div>
              <p>
                <b>Возврат принимается Поставщиком </b>
              </p>
              <div>
                <div className={styles.containerTextDot}>
                  {returnsAccpected.map((str, index) => (
                    <div key={index} className={styles.xx}>
                      <div key={index} className={styles.containerDot}>
                        <Dot />
                      </div>
                      <div key={index} className={styles.containerText}>
                        <li>{str}</li>
                      </div>
                    </div>
                  ))}
                </div>
                <div></div>
              </div>
            </div>
          </Col>
          <Col className={styles.returnsConditation}>
            <DiagramTwo />
            <div>
              <p>
                <b>Возврат не принимается Поставщиком </b>
              </p>
              <div>
                <div className={styles.containerTextDot}>
                  {returnRefused.map((str, index) => (
                    <div className={styles.xx}>
                      <div key={index} className={styles.containerDot}>
                        <Dot />
                      </div>
                      <div key={index} className={styles.containerText}>
                        <li>{str}</li>
                      </div>
                    </div>
                  ))}
                </div>
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
