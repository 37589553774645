import { makeAutoObservable } from "mobx";
import { useLocation, useNavigate } from "react-router-dom";
import { RootStore } from "./RootStore";

export type History = ReturnType<typeof useNavigate>;
export type Location = ReturnType<typeof useLocation>;

const getDefaultLocation = (): Location => ({
  hash: window.location.hash,
  key: window.history.state?.key || "",
  pathname: window.location.pathname,
  search: window.location.search,
  state: window.history.state?.state || null,
});

class NavigationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  path: string | null = null;

  history: null | History = null;

  location: Location = getDefaultLocation();

  setPath = (value: string | null): void => {
    this.path = value;
  };

  navigateTo = (path: string): void => {
    this.path = path;
  };
}

export default NavigationStore;
