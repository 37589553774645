import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "stores/RootStore";
import {
  Analytics,
  CartSvg,
  CollationSvg,
  CollectionsSvg,
  EmailSvg,
  FaqSvg,
  FinanceSvg,
  GuideSvg,
  HeartSvg,
  HomeSvg,
  InvoicesSvg,
  LogoutSvg,
  MarketplacesSvg,
  New,
  ObligationSvg,
  PriceSvg,
  ReturnIcon,
  UpdateInfo,
  UsersSvg,
  UserSvg,
  VideosIcon,
  WarehouseSvg,
} from "components/SvgIcons";
import {
  Menu,
  MenuItem,
  MenuItemStyles,
  Sidebar,
  SubMenu,
} from "react-pro-sidebar";
import { SidebarHeader } from "./sidebarHeader";
import SvgIcon from "../../../../components/SvgIcon";
import styles from "./Drawer.module.scss";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface MenuI {
  icon: any;
  to: string;
  title: string | undefined;
  show: boolean;
  subMenu: { title: string; id: number; to: string }[] | null;
}

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#ffffff",
      icon: "rgb(157,163,172)",
      hover: {
        backgroundColor: "rgba(244, 145, 0, 0.2)",
        color: "#44596e",
      },
    },
  },
};

const Drawer = observer(() => {
  const {
    warehouseStore: { setFilters, getFavoriteCount },
    userStore: { info },
    loginStore: { logout },
    marketPlacesStore: { getUserWarehouses, userWarehouses },
  } = useContext(RootStoreContext);

  const [isLogout, setIsLogout] = useState<boolean>(false);
  const [broken, setBroken] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [toggled, setToggled] = useState<boolean>(false);
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(true);

  useEffect(() => {
    getUserWarehouses();
  }, [getFavoriteCount, getUserWarehouses]);

  const navigate = useNavigate();
  const location = useLocation();
  const menu: MenuI[] = [
    {
      icon: <SvgIcon size={1.9} component={<HomeSvg />} />,
      to: "/home",
      title: "Домой",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<UserSvg />} />,
      to: "/profile",
      title: info?.firstName,
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<Analytics />} />,
      to: `/analytics?seller=${info?.id}&startDate=${moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD")}&endDate=${moment().format("YYYY-MM-DD")}`,
      title: "Аналитика",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<UpdateInfo />} />,
      to: "/updateInfo?pageNumber=1&pageSize=20",
      title: "Изменение остатков",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<EmailSvg />} />,
      to: "/subscribtions",
      title: "Подписки",
      show: info?.roles.includes("SuperAdmin") ? true : false,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<MarketplacesSvg />} />,
      to: "/marketplaces",
      title: "Маркетплейс",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<WarehouseSvg />} />,
      to: "/warehouse?pageNumber=1&pageSize=20",
      title: "Склад",
      show: true,
      subMenu:
        userWarehouses &&
        userWarehouses.map((i) => {
          return {
            title: i.item2,
            id: i.item1,
            to: `/warehouse?pageNumber=1&pageSize=20&mpWarehouseID=${i.item1}`,
          };
        }),
    },
    {
      icon: <SvgIcon size={1.9} component={<CollectionsSvg />} />,
      to: "/collections",
      title: "Наборы",
      show: true,
      subMenu:
        userWarehouses &&
        userWarehouses.map((i) => {
          return {
            title: i.item2,
            id: i.item1,
            to: `/collections?mpWarehouseID=${i.item1}`,
          };
        }),
    },
    {
      icon: <SvgIcon size={1.9} component={<CartSvg />} />,
      to: `/cart?pageNumber=1&pageSize=20&startDate=${moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD")}&endDate=null`,
      title: "Заказы",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<HeartSvg />} />,
      to: "/favorites",
      title: "Избранное",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={2.1} component={<New />} />,
      to: "/new",
      title: "Новинки",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<CollationSvg />} />,
      to: "/collations?pageNumber=1&pageSize=20",
      title: "Сопоставление",
      show: info?.roles.includes("SuperAdmin") ? true : false,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<PriceSvg />} />,
      to: "/priceHistory",
      title: "Изменение цен",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<UsersSvg />} />,
      to: "/users",
      title: "Пользователи",
      show: info?.roles.includes("SuperAdmin") ? true : false,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<InvoicesSvg />} />,
      to: "/invoices",
      title: "Накладные",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<ReturnIcon />} />,
      to: "/returns?pageNumber=1&pageSize=20",
      title: "Возвраты",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<VideosIcon />} />,
      to: "/videos",
      title: "Видео",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<FinanceSvg />} />,
      to: "/finance",
      title: "Финансы",
      show: true,
      subMenu: null,
    },
    {
      icon: <SvgIcon size={1.9} component={<ObligationSvg />} />,
      to: "/obligations",
      title: "Задолженность",
      show: info?.roles.includes("SuperAdmin") ? true : false,
      subMenu: null,
    },
    // { icon: <SettingsSvg />, to: "/settings", title: "Настройки" }
    {
      icon: <SvgIcon size={1.9} component={<FaqSvg />} />,
      to: "/faq",
      title: "Часто задаваемые вопросы",
      show: true,
      subMenu: null,
    },
  ];
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: ({ active }) => ({
      color: active ? "rgb(244, 145, 0)" : themes.light.menu.icon,
    }),
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? themes.light.menu.menuContent : "transparent",
    }),
    button: {
      "&:hover": {
        backgroundColor: themes.light.menu.hover.backgroundColor,
        color: themes.light.menu.hover.color,
      },
    },
    label: ({ open, active }) => ({
      fontWeight: open || active ? 600 : undefined,
    }),
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: "ltr",
      }}
    >
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        rtl={false}
        breakPoint="md"
        backgroundColor={themes.light.sidebar.backgroundColor}
        rootStyles={{
          color: themes.light.sidebar.color,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <SidebarHeader
            rtl={false}
            style={{ marginBottom: "24px", marginTop: "16px" }}
          />

          <div className={styles.sideBarNav}>
            <Menu menuItemStyles={menuItemStyles}>
              {menu
                .filter((i) => i.show)
                .map((item) => {
                  return item.subMenu ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <span>{item.title}</span>
                        </Tooltip>
                      }
                      placement="right"
                      key={item.title}
                    >
                      <SubMenu
                        label={item.title}
                        icon={item.icon}
                        onClick={() => setSubmenuOpen(true)}
                        active={
                          location.pathname.split("?")[0] ===
                          item.to.split("?")[0]
                        }
                        id="submenu"
                      >
                        {item.subMenu.map(({ title, id, to }) => {
                          return (
                            <MenuItem
                              key={title}
                              style={{
                                display: submenuOpen ? "" : "none",
                              }}
                              onClick={() => {
                                navigate(`${to}`);

                                //setSearchTextEmpty(true);
                                setFilters([
                                  {
                                    name: "pageSize",
                                    value: "20",
                                    label: "20",
                                  },
                                  {
                                    name: "pageNumber",
                                    value: "1",
                                    label: "1",
                                  },
                                ]);
                                setSubmenuOpen(false);
                                const element =
                                  document.getElementById("submenu");
                                if (element) {
                                  element.click();
                                }
                              }}
                            >
                              {title}
                            </MenuItem>
                          );
                        })}
                      </SubMenu>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <span>{item.title}</span>
                        </Tooltip>
                      }
                      placement="right"
                      key={item.title}
                    >
                      <MenuItem
                        active={
                          location.pathname.split("?")[0] ===
                          item.to.split("?")[0]
                        }
                        icon={item.icon}
                        onClick={() => {
                          if (
                            item.to === "/favorites" ||
                            item.to === "/warehouse?pageNumber=1&pageSize=20"
                          ) {
                            navigate(item.to);
                            //setSearchTextEmpty(true);
                            setFilters([
                              { name: "pageSize", value: "20", label: "20" },
                              { name: "pageNumber", value: "1", label: "1" },
                            ]);
                          } else {
                            navigate(item.to);
                          }
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    </OverlayTrigger>
                  );
                })}
            </Menu>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            {true ? (
              <MenuItem
                className={styles.guideItem}
                onClick={() => {
                  navigate("/termsOfUsage");
                }}
                icon={
                  <div>
                    <SvgIcon
                      size={1.9}
                      color={"var(--fill-medium-emphasis)"}
                      component={<GuideSvg />}
                    />
                  </div>
                }
              >
                Руководство
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem
              className={styles.logoutItem}
              icon={
                <SvgIcon
                  size={1.9}
                  color={"var(--fill-medium-emphasis)"}
                  component={<LogoutSvg />}
                />
              }
              onClick={() => {
                setIsLogout(true);
              }}
            >
              Выход
            </MenuItem>
          </Menu>
          {isLogout && (
            <Modal
              isOpen={!!isLogout}
              onClose={() => {
                setIsLogout(false);
              }}
              maxWidth={"350px"}
            >
              <div className={styles.logoutModal}>
                <p className={styles.text}>Вы действительно хотите выйти?</p>
                <div className={styles.btns}>
                  <Button
                    text="Нет"
                    fullWidth
                    color="secondary"
                    onClick={() => setIsLogout(false)}
                  />
                  <Button
                    text="Да"
                    fullWidth
                    onClick={() => {
                      setIsLogout(false);
                      logout();
                    }}
                  />
                </div>
              </div>
            </Modal>
          )}
        </div>
      </Sidebar>
      {!broken ? (
        collapsed ? (
          <div className={styles.sidebarToggle}>
            <FaChevronCircleRight
              size={25}
              color={"rgb(244, 145, 0)"}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
        ) : (
          <div className={styles.sidebarToggle}>
            <FaChevronCircleLeft
              size={25}
              color={"rgb(244, 145, 0)"}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
});

export default Drawer;
