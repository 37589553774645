import { Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import styles from "./Products.module.scss";
import CustomInput from "../../../components/CustomInput";

interface Props {
  setCollectionMode: React.Dispatch<React.SetStateAction<boolean>>;
  setCollectionID: React.Dispatch<React.SetStateAction<string>>;
  setCollectionPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  warehouseID: string;
}

const CollectionCreate: React.FC<Props> = observer(
  ({
    setCollectionMode,
    warehouseID,
    setCollectionID,
    setCollectionPopupOpen,
  }) => {
    const {
      collectionsStore: { newCollection },
    } = useContext(RootStoreContext);

    const [collectionName, setCollectionName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const createCollection = () => {
      setLoading(true);
      newCollection(collectionName, warehouseID).then((i) => {
        if (i) {
          setCollectionID(i);
          setCollectionMode(true);
          setCollectionPopupOpen(false);
        }
        setLoading(false);
      });
    };
    return (
      <Row className={styles.collectionModalRoot}>
        <CustomInput
          type="text"
          value={collectionName}
          placeholder="Наименование набора"
          name={`collectionName`}
          onChange={(e) => {
            setCollectionName(e.target.value);
          }}
        />
        <Button
          text="Добавить товары в набор"
          className={
            loading ? styles.collectionButtonLoad : styles.collectionButton
          }
          onClick={() => createCollection()}
          disabled={collectionName === ""}
        />
      </Row>
    );
  }
);

export default CollectionCreate;
