import styles from "./Marketplaces.module.scss";
import Button from "components/Button";
import CustomInput from "components/CustomInput";
import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomSelect from "components/CustomSelect";
import MarketplacesTB from "./marketPlacesTB";
import { MPParams } from "../../../stores/MarketPlaceStore";
import BeatLoader from "react-spinners/BeatLoader";

const registerSchema = yup.object().shape({
  marketplace: yup.string(),
  clientId: yup.string(),
  campaignId: yup.string(),
  marketplaceName: yup.string(),
  key: yup.string(),
});

const Marketplaces = observer(() => {
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(0);
  const [deleteParams, setDeleteParams] = useState<{
    marketPlaceParametersId: number;
    marketPlaceID: number;
    stockID: number;
    mpWarehouseID: number;
  }>({
    marketPlaceParametersId: 0,
    marketPlaceID: 0,
    stockID: 0,
    mpWarehouseID: 0,
  });

  const [ozonParams, setOzonParams] = useState<MPParams[]>();
  const [yandexParams, setYandexParams] = useState<MPParams[]>();
  const [wildberriesParams, setWildberriesParams] = useState<MPParams[]>();

  const [ozonLoading, setOzonLoading] = useState<boolean>(false);
  const [yandexLoading, setYandexLoading] = useState<boolean>(false);
  const [wildberriesLoading, setWildberriesLoading] = useState<boolean>(false);

  const {
    marketPlacesStore: {
      getMarketPlacesList,
      getMarketPlaces,
      addMarketPlaceParameters,
      deleteMarketPlaceParameters,
      updateMarketPlaceParameters,
      getMarketPlaceParametres,
      getContractedWarehouses,
      contractedWarehouses,
    },
    userStore: { info },
  } = useContext(RootStoreContext);

  useEffect(() => {
    if (info) {
      setOzonLoading(true);
      setWildberriesLoading(true);
      setYandexLoading(true);
      getContractedWarehouses();
      getMarketPlaceParametres(info?.id, 1).then((ozonData) => {
        if (ozonData) {
          setOzonParams(ozonData);
        }
        setOzonLoading(false);
      });
      getMarketPlaceParametres(info?.id, 2).then((yandexData) => {
        if (yandexData) {
          setYandexParams(yandexData);
        }
        setYandexLoading(false);
      });
      getMarketPlaceParametres(info?.id, 3).then((wildberriesData) => {
        if (wildberriesData) {
          setWildberriesParams(wildberriesData);
        }
        setWildberriesLoading(false);
      });
    }
    getMarketPlaces();
  }, [
    getContractedWarehouses,
    getMarketPlaceParametres,
    info,
    getMarketPlaces,
  ]);

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      marketPlaceParameterID: "",
      marketPlaceID: "",
      apiKey: "",
      clientId: "",
      campaignId: "",
      mpName: "",
      stockID: "",
      stockName: "",
      mpWarehouseID: "",
    },
    validationSchema: registerSchema,
    onSubmit: async () => {
      if (!!isEdit) {
        updateMarketPlaceParameters(
          Number(values.marketPlaceParameterID),
          values.apiKey,
          values.clientId ? values.clientId : " ",
          values.campaignId ? values.campaignId.toString() : " ",
          Number(values.stockID),
          isEdit
        ).then((r) => {
          if (r && info?.id) {
            setOpen(false);
            setEdit(0);
            setFieldValue("marketPlaceID", "");
            setFieldValue("apiKey", "");
            setFieldValue("clientId", "");
            setFieldValue("campaignId", "");
            setFieldValue("stockID", "");
            setFieldValue("stockName", "");
            setFieldValue("mpWarehouseID", "");
            getMarketPlacesList();
            if (values.mpName === "Ozon") {
              setOzonLoading(true);
            } else if (values.mpName === "Wildberries") {
              setWildberriesLoading(true);
            } else {
              setYandexLoading(true);
            }
            getMarketPlaceParametres(
              info.id,
              Number(values.marketPlaceID)
            ).then((data) => {
              if (data) {
                if (values.mpName === "Ozon") {
                  setOzonParams(data);
                  setOzonLoading(false);
                } else if (values.mpName === "Wildberries") {
                  setWildberriesParams(data);
                  setWildberriesLoading(false);
                } else {
                  setYandexParams(data);
                  setYandexLoading(false);
                }
              }
            });
          }
        });
      } else {
        addMarketPlaceParameters(
          Number(values.marketPlaceID),
          values.apiKey,
          values.clientId,
          values.campaignId,
          Number(values.stockID),
          values.stockName,
          values.mpName,
          Number(values.mpWarehouseID)
        ).then((r) => {
          if (r && info?.id) {
            setOpen(false);
            setFieldValue("marketPlaceID", "");
            setFieldValue("apiKey", "");
            setFieldValue("clientId", "");
            setFieldValue("campaignId", "");
            setFieldValue("stockID", "");
            setFieldValue("stockName", "");
            setFieldValue("mpWarehouseID", "");
            if (Number(values.marketPlaceID) === 1) {
              setOzonLoading(true);
            } else if (Number(values.marketPlaceID) === 3) {
              setWildberriesLoading(true);
            } else {
              setYandexLoading(true);
            }
            getMarketPlaceParametres(
              info.id,
              Number(values.marketPlaceID)
            ).then((data) => {
              if (data) {
                if (Number(values.marketPlaceID) === 1) {
                  setOzonParams(data);
                  setOzonLoading(false);
                } else if (Number(values.marketPlaceID) === 3) {
                  setWildberriesParams(data);
                  setWildberriesLoading(false);
                } else {
                  setYandexParams(data);
                  setYandexLoading(false);
                }
              }
            });
          }
        });
      }
    },
  });

  return (
    <div className={styles.root}>
      <p className={styles.title}>Маркетплейс</p>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {ozonLoading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={ozonLoading} size={50} />
            </div>
          ) : (
            ozonParams && (
              <MarketplacesTB
                setOpen={setOpen}
                setFieldValue={setFieldValue}
                setEdit={setEdit}
                marketPlaceName={"Ozon"}
                marketPlaceID={1}
                mpParams={ozonParams}
                setDeleteParams={setDeleteParams}
              />
            )
          )}
          {yandexLoading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader color={"#f49100"} loading={yandexLoading} size={50} />
            </div>
          ) : (
            yandexParams && (
              <MarketplacesTB
                setOpen={setOpen}
                setFieldValue={setFieldValue}
                setEdit={setEdit}
                marketPlaceName={"YandexMarket"}
                marketPlaceID={2}
                mpParams={yandexParams}
                setDeleteParams={setDeleteParams}
              />
            )
          )}
          {wildberriesLoading ? (
            <div style={{ width: "fit-content", margin: "auto" }}>
              <BeatLoader
                color={"#f49100"}
                loading={wildberriesLoading}
                size={50}
              />
            </div>
          ) : (
            wildberriesParams && (
              <MarketplacesTB
                setOpen={setOpen}
                setFieldValue={setFieldValue}
                setEdit={setEdit}
                marketPlaceName={"Wildberries"}
                marketPlaceID={3}
                mpParams={wildberriesParams}
                setDeleteParams={setDeleteParams}
              />
            )
          )}
        </div>
      </div>
      {deleteParams.marketPlaceParametersId && info?.id ? (
        <Modal
          isOpen={true}
          onClose={() => {
            setDeleteParams({
              marketPlaceParametersId: 0,
              marketPlaceID: 0,
              stockID: 0,
              mpWarehouseID: 0,
            });
          }}
          maxWidth={"350px"}
        >
          <div className={styles.delete}>
            <p className={styles.text}>Хотите удалить Маркетплейс?</p>
            <div className={styles.btns}>
              <Button
                text="Нет"
                fullWidth
                color="secondary"
                onClick={() =>
                  setDeleteParams({
                    marketPlaceParametersId: 0,
                    marketPlaceID: 0,
                    stockID: 0,
                    mpWarehouseID: 0,
                  })
                }
              />
              <Button
                text="Да"
                fullWidth
                onClick={() =>
                  deleteMarketPlaceParameters(
                    deleteParams.marketPlaceParametersId,
                    deleteParams.marketPlaceID,
                    deleteParams.stockID,
                    deleteParams.mpWarehouseID
                  ).then((r) => {
                    if (r) {
                      if (deleteParams.marketPlaceID === 1) {
                        setOzonLoading(true);
                      } else if (deleteParams.marketPlaceID === 3) {
                        setWildberriesLoading(true);
                      } else {
                        setYandexLoading(true);
                      }
                      getMarketPlaceParametres(
                        info.id,
                        deleteParams.marketPlaceID
                      ).then((data) => {
                        if (data) {
                          if (deleteParams.marketPlaceID === 1) {
                            setOzonParams(data);
                            setOzonLoading(false);
                          } else if (deleteParams.marketPlaceID === 3) {
                            setWildberriesParams(data);
                            setWildberriesLoading(false);
                          } else {
                            setYandexParams(data);
                            setYandexLoading(false);
                          }
                        }
                      });
                      getMarketPlaceParametres(
                        info.id,
                        deleteParams.marketPlaceID
                      ).then(() => {});
                    }
                    setDeleteParams({
                      marketPlaceParametersId: 0,
                      marketPlaceID: 0,
                      stockID: 0,
                      mpWarehouseID: 0,
                    });
                  })
                }
              />
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            setOpen(false);
            setEdit(0);
          }}
          maxWidth={"600px"}
        >
          <div className={styles.modal}>
            {!isEdit ? (
              <div className={styles.input}>
                <CustomInput
                  className={styles.marketplaceInput}
                  name="marketplace"
                  value={values.mpName}
                  onChange={handleChange}
                  label={isEdit ? "Marketplace" : ""}
                  placeholder="Marketplace"
                  required={true}
                  disabled
                />
              </div>
            ) : (
              ""
            )}
            {values.marketPlaceID ? (
              <>
                <div className={styles.input}>
                  <CustomInput
                    className={styles.marketplaceInput}
                    name="apiKey"
                    value={values.apiKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.apiKey ? errors.apiKey : ""}
                    label={
                      isEdit
                        ? values.mpName === "YandexMarket"
                          ? "Токен (access_token)"
                          : values.mpName === "Ozon"
                          ? "Api key"
                          : "Токен combined"
                        : ""
                    }
                    placeholder={
                      values.mpName === "YandexMarket"
                        ? "Токен (access_token)"
                        : values.mpName === "Ozon"
                        ? "Api key"
                        : "Токен combined"
                    }
                    required={true}
                    maxLength={1000}
                  />
                </div>
                {values.mpName !== "Wildberries" ? (
                  <div className={styles.input}>
                    <CustomInput
                      className={styles.marketplaceInput}
                      label={isEdit ? "Client ID" : ""}
                      name="clientId"
                      onBlur={handleBlur}
                      error={touched.clientId ? errors.clientId : ""}
                      value={values.clientId}
                      onChange={handleChange}
                      placeholder={"Client ID"}
                      required={true}
                      maxLength={1000}
                    />
                  </div>
                ) : (
                  ""
                )}

                {values.mpName === "YandexMarket" ? (
                  <div className={styles.input}>
                    <CustomInput
                      className={styles.marketplaceInput}
                      label={isEdit ? "Номер компании" : ""}
                      name="campaignId"
                      onBlur={handleBlur}
                      error={touched.campaignId ? errors.campaignId : ""}
                      value={values.campaignId.toString()}
                      onChange={handleChange}
                      placeholder="Номер компании"
                      required={true}
                      maxLength={1000}
                    />
                  </div>
                ) : (
                  ""
                )}
                {
                  <div className={styles.input}>
                    <CustomInput
                      className={styles.marketplaceInput}
                      label={isEdit ? "Stock ID" : ""}
                      name="stockID"
                      onBlur={handleBlur}
                      error={touched.stockID ? errors.stockID : ""}
                      value={values.stockID}
                      onChange={handleChange}
                      placeholder={"Stock ID"}
                      required={true}
                      maxLength={1000}
                    />
                  </div>
                }
                {contractedWarehouses && !isEdit ? (
                  <div className={styles.input}>
                    <CustomSelect
                      className={styles.marketplaceInput}
                      name="mpWarehouseID"
                      label={isEdit ? "Склад" : ""}
                      placeholder={"Склад"}
                      onChange={(e) => {
                        setFieldValue("mpWarehouseID", e.value);
                      }}
                      value={values.mpWarehouseID}
                      list={
                        contractedWarehouses?.map((item) => ({
                          value: item.item1,
                          label: item.item2,
                        })) as any
                      }
                      required={true}
                    />
                  </div>
                ) : (
                  ""
                )}
                <Button
                  className={styles.marketplacesButton}
                  text={!!isEdit ? "Редактировать" : "Подключить"}
                  fullWidth
                  onClick={() => handleSubmit()}
                  disabled={
                    isEdit
                      ? values.apiKey === ""
                      : values.marketPlaceID === "" ||
                        values.apiKey === "" ||
                        (values.clientId === "" &&
                          values.mpName !== "Wildberries") ||
                        values.stockID === "" ||
                        (values.campaignId === "" &&
                          values.mpName === "YandexMarket") ||
                        values.mpWarehouseID === ""
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>
        </Modal>
      )}
    </div>
  );
});
export default Marketplaces;
