import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import handleInternalServerError from "utils/handleInternalServerError";
import { RootStore } from "./RootStore";
import { toast } from "react-hot-toast";

export interface FAQ {
  title: string;
  description: string;
  order: number;
  id: number;
}

class FaqStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  faqs: FAQ[] | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setFAQs = (faqs: FAQ[]): void => {
    this.faqs = faqs;
  };

  getFAQs = async (): Promise<FAQ[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FAQ");
      const response = await fetch(`${endpoint}/GetFAQs`, requestOptions);

      const data = await response.json();
      if (response.ok) {
        this.setFAQs(data);
        return data;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  getFAQById = async (id: number): Promise<FAQ | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FAQ");
      const response = await fetch(`${endpoint}/GetFAQ/${id}`, requestOptions);

      if (!response.ok) {
        throw new Error(`Failed to fetch FAQ with ID ${id}`);
      }

      const data = await response.json();
      this.setIsLoading(false);
      return data;
    } catch (error) {
      console.error(`Error fetching FAQ with ID ${id}:`, error);
      this.setIsLoading(false);
      return null;
    }
  };

  addFAQ = async (
    title: string,
    description: string,
    order: number
  ): Promise<FAQ | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        title: title,
        description: description,
        order: order,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_FAQ");
      const response = await fetch(`${endpoint}/AddFAQ`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        toast.success("Вопрос добавлен!");
        return data;
      } else {
        toast.error("Произошла ошибка");
        return null;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      return null;
    }
  };

  deleteFAQ = async (id: number): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "DELETE",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };
    try {
      const endpoint = handleApiPath("REACT_APP_API_FAQ");
      const response = await fetch(
        `${endpoint}/Delete?id=${id}`,
        requestOptions
      );
      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  updateFaq = async (
    orderDrag: number,
    orderDropUpper: number,
    orderDropLower: number
  ): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        orderDrag,
        orderDropUpper,
        orderDropLower,
      }),
    };
    try {
      const endpoint = handleApiPath("REACT_APP_API_FAQ");
      const response = await fetch(`${endpoint}/UpdateFAQ`, requestOptions);

      if (response.ok && this.faqs) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };
}

export default FaqStore;
