import React, { useContext, useEffect, useState } from "react";
import styles from "./Obligation.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { Tab, Table, Tabs } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import { MdOutlinePostAdd } from "react-icons/md";

const Obligation: React.FC = observer(() => {
  const {
    orderStore: {
      getObligationsByUsers,
      getObligationsByWarehouses,
      obligationsByUsers,
      obligationsByWarehouses,
    },
    financeStore: { uploadPaymetsOrders },
  } = useContext(RootStoreContext);

  const [startDate, setStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const [loadUserObligation, setLoadUserObligation] = useState<boolean>(false);
  const [loadWarehouseObligation, setLoadWarehouseObligation] =
    useState<boolean>(false);

  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  useEffect(() => {
    setLoadUserObligation(true);
    setLoadWarehouseObligation(true);
    getObligationsByUsers(startDate, endDate).then(() =>
      setLoadUserObligation(false)
    );
    getObligationsByWarehouses(startDate, endDate).then(() =>
      setLoadWarehouseObligation(false)
    );
  }, [getObligationsByUsers, getObligationsByWarehouses, startDate, endDate]);

  const uploadTermsOfCondition = (e: any) => {
    setLoadingFile(true);
    uploadPaymetsOrders(e.target.files[0]).then(() => {
      setLoadingFile(false);
    });
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>Задолженность</p>
      <div className={styles.wrapper}>
        <div style={loadingFile ? { cursor: "progress" } : {}}>
          <input
            id="usageFile-input"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => {
              uploadTermsOfCondition(e);
              e.target.value = "";
            }}
            type="file"
            style={{ display: "none" }}
          />
          <div
            className={styles.versionInput}
            style={{ justifyContent: "end" }}
          >
            <label htmlFor="usageFile-input" className={styles.packageLabelA}>
              <MdOutlinePostAdd
                size={32}
                color={"rgb(25, 135, 84)"}
                cursor={"pointer"}
              />
            </label>
          </div>
        </div>
        <Tabs
          defaultActiveKey="seller"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="seller" title="Задолженность продавцов платформе">
            <div className={styles.dates}>
              <div>Период изменений</div>

              <div>
                от:
                {
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                }
              </div>

              <div>
                до:
                {
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                }
              </div>
            </div>
            {loadUserObligation ? (
              <div style={{ width: "fit-content", margin: "auto" }}>
                <BeatLoader
                  color={"#f49100"}
                  loading={loadUserObligation}
                  size={50}
                />
              </div>
            ) : (
              obligationsByUsers?.map((user) => (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>{user.item1.item2}</th>
                    </tr>
                    <tr>
                      <th>Склад</th>
                      <th>Задолженность за товар</th>
                      <th>Задолженность за упаковку</th>
                      <th>Задолженность</th>
                      <th>Уплаченная сумма</th>
                      <th>Дата</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.item2.map((item, index) => (
                      <tr key={index}>
                        <td>{item.item2}</td>
                        <td>{item.item3.totalPriceWarehouse} ₽</td>
                        <td>{item.item3.totalPackWarehouse} ₽</td>
                        <td>{item.item3.totalByWarehouse} ₽</td>
                        <td>{item.item3.paidSum} ₽</td>
                        <td>
                          {item.item3.paidDate
                            ? moment(item.item3.paidDate).format("DD.MM.YYYY")
                            : ""}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <b>Итого:</b>
                      </td>
                      <td>{user.item3.totalPriceWarehouse} ₽</td>
                      <td>{user.item3.totalPackWarehouse} ₽</td>
                      <td>{user.item3.totalByWarehouse} ₽</td>
                      <td>{user.item3.paidSum} ₽</td>
                      <td>
                        {user.item3.paidDate
                          ? moment(user.item3.paidDate).format("DD.MM.YYYY")
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ))
            )}
          </Tab>
          <Tab
            eventKey="warehouse"
            title="Задолженность платформы каждому складу"
          >
            <div className={styles.dates}>
              <div>Период изменений</div>

              <div>
                от:
                {
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                }
              </div>

              <div>
                до:
                {
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                }
              </div>
            </div>
            {loadWarehouseObligation ? (
              <div style={{ width: "fit-content", margin: "auto" }}>
                <BeatLoader
                  color={"#f49100"}
                  loading={loadWarehouseObligation}
                  size={50}
                />
              </div>
            ) : (
              obligationsByWarehouses?.map((warehouse) => (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Склад</th>
                      <th>Задолженность за товар</th>
                      <th>Задолженность за упаковку</th>
                      <th>Задолженность</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>{warehouse.item2}</b>
                      </td>
                      <td>{warehouse.item3.totalPriceWarehouse} ₽</td>
                      <td>{warehouse.item3.totalPackWarehouse} ₽</td>
                      <td>{warehouse.item3.totalByWarehouse} ₽</td>
                    </tr>
                  </tbody>
                </Table>
              ))
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});
export default Obligation;
