import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { FormikErrors, FormikValues, useFormik } from "formik";
import { ReactComponent as Wallpaper } from "assets/icons/login-wallpaper.svg";
import CustomInput from "components/CustomInput";
import Button from "components/Button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import styles from "./ResetPassword.module.scss";
import {
  DIGIT_CHARACTER,
  MIN8CHAR,
  UPPERCASE_LOWERCASE,
} from "../../../constants/reg-ex";
import Icon from "../../../components/Icon";
import LogoLight from "../../Home/HomePageImages/LogoLight.png";

const ResetPassword: React.FC = observer(() => {
  const {
    forgotStore: { reset },
  } = useContext(RootStoreContext);

  const [searchParams] = useSearchParams();

  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const [conditions, setConditions] = useState([
    {
      id: 1,
      text: "• Минимальная длина в 6 символов",
      status: false,
      regex: MIN8CHAR,
    },
    {
      id: 2,
      text: "• Должен содержать прописную букву и строчную буку",
      status: false,
      regex: UPPERCASE_LOWERCASE,
    },
    {
      id: 3,
      text: "• Должн содержать число и специальнй символ.",
      status: false,
      regex: DIGIT_CHARACTER,
    },
  ]);

  const { values, setFieldValue, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validate: async (values) => {
        const errors: FormikErrors<FormikValues> = {};
        if (values.password === "") {
          errors.password = "Поле обязательно для заполнения";
        }
        if (values.confirmPassword === "") {
          errors.confirmPassword = "Поле обязательно для заполнения";
        }
        if (values.password !== values.confirmPassword) {
          errors.password = "Пароли не совпадают";
        }
        if (
          !MIN8CHAR.test(values.password) ||
          !UPPERCASE_LOWERCASE.test(values.password) ||
          !DIGIT_CHARACTER.test(values.password)
        ) {
          errors.password = "Пороль не подходит к требованиям";
        }
        return errors;
      },
      onSubmit: async () => {
        setSubmitted(true);
        if (searchParams.get("email") && searchParams.get("code")) {
          reset(
            searchParams.get("email") as string,
            values.password,
            values.confirmPassword,
            searchParams.get("code") as string
          );
        }

        // forgot(values.email).then((r) => setSubmitted(true));
      },
    });

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setConditions([
      ...conditions.map((condition, j) => {
        const match = conditions[j].regex.test(e.currentTarget.value);
        return { ...condition, status: match };
      }),
    ]);

    setFieldValue("password", e.currentTarget.value);
  };

  return (
    <div className={styles.root}>
      <Wallpaper className={styles.wallpaper} />
      <div className={styles.content}>
        {!isSubmitted ? (
          <div className={styles.window}>
            <div className={styles.welcomeTitle}>
              <Link to="/">
                <img src={LogoLight} alt="Logo" className={styles.Logo} />
              </Link>
              <p className={styles.title}>Создать новый пароль</p>
            </div>
            <CustomInput
              value={values.password}
              error={errors.password}
              placeholder="Пароль"
              name="password"
              onChange={(e) => handleInputChange(e)}
              type="password"
            />
            <CustomInput
              value={values.confirmPassword}
              error={errors.confirmPassword}
              placeholder="Потвердить пароль"
              name="confirmPassword"
              onChange={(e) => handleChange(e)}
              type="password"
            />
            <ul className={styles.list}>
              {conditions.map((condition) => {
                return (
                  <li className={styles.item} key={condition.id}>
                    <span
                      className={`${styles.condition} ${
                        condition.status ? styles.active : ""
                      }`}
                    >
                      {condition.text}
                    </span>
                    {condition.status && (
                      <Icon
                        variant="tick"
                        size="normal"
                        contentMargin="left04"
                      />
                    )}
                  </li>
                );
              })}
            </ul>
            <Button
              text="Готово"
              fullWidth
              onClick={() => handleSubmit()}
              color="primary"
              disabled={!!Object.keys(errors).length}
            />
          </div>
        ) : (
          <div className={styles.window}>
            <p className={styles.title}>Поздравляем</p>
            <p className={styles.subtitle}>Ваш пароль успешно изменен.</p>
            <Button
              text="Ок"
              fullWidth
              onClick={() => navigate("/login")}
              color="primary"
              className={styles.back}
            />
          </div>
        )}

        <div className={styles.options}>
          <Link to="/">На главную</Link>
          {/* <Link to="/">Помощь</Link>
          <div className={styles.divider} />
          <Link to="/">Политика конфиденциальности</Link> */}
        </div>
      </div>
    </div>
  );
});
export default ResetPassword;
