import { makeAutoObservable } from "mobx";
import { toast } from "react-hot-toast";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import { RootStore } from "./RootStore";
import { ProfileInfo } from "./UserStore";

interface CreateSessionError {
  error_id: string;
  error_message: string;
  error_fields: Array<{
    code: string;
    name: string;
    message: string;
  }>;
  error_code: string;
  status_code?: number;
}

class LoginStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  errorData: CreateSessionError | null = null;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setErrorData = (error: CreateSessionError | null): void => {
    this.errorData = error;
  };

  login = async (
    email: string,
    password: string
  ): Promise<ProfileInfo | CreateSessionError | null> => {
    const { userStore, navigationStore } = this.rootStore;

    this.setIsLoading(true);
    this.setErrorData(null);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({}),
      body: JSON.stringify({
        email,
        password,
        rememberMe: false,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_LOGIN");
      const response = await fetch(endpoint, requestOptions);
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        userStore.setToken(data.token);
        userStore.setInfo(data);
        // await toastStore.notify.success("Logged in successfully");
        toast.success("Logged in successfully", {
          style: {
            border: "1px solid #D8E8DC",
            padding: "16px",
            color: "#3B4859",
            backgroundColor: "#EAF7EE",
          },
          iconTheme: {
            primary: "#fff",
            secondary: "#8DC644",
          },
        });
        await navigationStore.navigateTo("/home");
      } else {
        this.setErrorData(data);
        toast.error(
          data["User login exception"]
            ? data["User login exception"][0]
            : "Не верный логин или пароль",
          {
            style: {
              border: "1px solid #F2DCD8",
              padding: "16px",
              color: "#3B4859",
              backgroundColor: "#F9ECEA",
            },
            iconTheme: {
              primary: "#fff",
              secondary: "#E8526D",
            },
          }
        );
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);
      toast.error("Не верный логин или пароль", {
        style: {
          border: "1px solid #F2DCD8",
          padding: "16px",
          color: "#3B4859",
          backgroundColor: "#F9ECEA",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#E8526D",
        },
      });
      return null;
    }
  };

  updateEnableTwoFactor = async (
    userId: string,
    enableTwoFactor: boolean
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_REGISTER");
      const response = await fetch(
        `${endpoint}/EnableTwoFactor?userId=${userId}&enableTwoFactor=${enableTwoFactor}`,
        requestOptions
      );

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  logout = (): void => {
    const { userStore } = this.rootStore;

    Promise.all([userStore.clearStore()]).then(() => {
      userStore.clearStore();
    });
  };
}

export default LoginStore;
