import * as React from "react";

import { GalleriesModel } from "components/Gallery/Gallery";
import GalleryMobile from "./../GalleryMobile";
import GalleryDesktop from "./../GalleryDesktop";

import "slick-carousel/slick/slick.css";
import "./index.scss";

interface Props {
  galleries: GalleriesModel[];
  thumbsPerView: number;
  isCollection: boolean;
  isImage: boolean;
  addImage: (file: any, productID: string) => void;
  productID: string;
}

const GalleryDetail: React.FC<Props> = ({
  galleries,
  thumbsPerView,
  isCollection,
  isImage,
  addImage,
  productID,
}) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 500);
    window.onresize = () => {
      setIsMobile(window.innerWidth <= 500);
    };
  }, [galleries]);

  return (
    <div className="pdp-slider">
      {isMobile ? (
        <GalleryMobile galleries={galleries} />
      ) : (
        <GalleryDesktop
          galleries={galleries}
          thumbsPerView={thumbsPerView}
          isCollection={isCollection}
          isImage={isImage}
          addImage={addImage}
          productID={productID}
        />
      )}
    </div>
  );
};

export default GalleryDetail;
