import * as React from "react";
import Slider from "react-slick";
import { GalleriesModel } from "../Gallery/Gallery";
import Thumbnail from "./../Thumbnail";
import ZoomPreview from "./../ZoomPreview";
import { ArrowDownSvg, ArrowUpSvg } from "components/SvgIcons";
import SvgIcon from "components/SvgIcon";
import styles from "./galleryDesktop.module.scss";
import { HiFolderDownload } from "react-icons/hi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  galleries: GalleriesModel[];
  thumbsPerView: number;
  isCollection: boolean;
  isImage: boolean;
  addImage: (file: any, productID: string) => void;
  productID: string;
}

const GalleryDesktop: React.FC<Props> = ({
  galleries,
  thumbsPerView,
  isCollection,
  isImage,
  productID,
  addImage,
}) => {
  const refSlider = React.useRef<any>();
  const [currentItem, setCurrentItem] = React.useState(0);

  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    ...props
  }: {
    currentSlide?: number;
    slideCount?: number;
    children: any;
  }) => <span {...props}>{children}</span>;

  const sliderProps = {
    vertical: true,
    accessibility: false,
    slidesToShow: thumbsPerView,
    centerMode: false,
    swipe: false,
    infinite: false,
    nextArrow: (
      <SlickButtonFix>
        <div className={styles.next}>
          <SvgIcon size={1} cursor={true} component={<ArrowDownSvg />} />
        </div>
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <div className={styles.prev}>
          <SvgIcon size={1} cursor={true} component={<ArrowUpSvg />} />
        </div>
      </SlickButtonFix>
    ),
  };

  const onHoverThumbnail = (position: number): void => {
    const $content = refSlider.current as HTMLElement;
    if ($content) {
      Array.from($content.querySelectorAll(".pdp-slider__thumb")).forEach(
        ($thumb) => {
          $thumb.classList.remove("pdp-slider__thumb--active");
        }
      );
      setCurrentItem(position);
    }
  };

  const thumbs = galleries.map((item, k) => (
    <Thumbnail
      key={k}
      active={k === currentItem}
      onHover={() => onHoverThumbnail(k)}
      path={item.thumbnail.url}
    />
  ));
  const downloadAllImages = (images: string[]) => {
    images.map((image, index) => {
      setTimeout(() => {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = image;
        a.download = image;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(image);
      }, index * 1000);
    });
  };
  return (
    <React.Fragment>
      <div
        className="pdp-slider__thumbs"
        ref={refSlider}
        style={{ textAlign: "center" }}
      >
        {thumbs.length > thumbsPerView ? (
          <React.Fragment>
            <Slider {...sliderProps}>{thumbs}</Slider>
          </React.Fragment>
        ) : (
          thumbs
        )}
        <OverlayTrigger overlay={<Tooltip>Скачать все фото</Tooltip>}>
          <div>
            <HiFolderDownload
              size={32}
              className={styles.downloadAllIcon}
              onClick={() => {
                if (isImage) {
                  downloadAllImages(galleries.map((i) => i.product.url));
                }
              }}
            />
          </div>
        </OverlayTrigger>
      </div>
      <ZoomPreview
        image={galleries[currentItem].product.url}
        zoom={galleries[currentItem].zoom.url}
        isCollection={isCollection}
        isImage={isImage}
        productID={productID}
        addImage={addImage}
      />
    </React.Fragment>
  );
};

export default GalleryDesktop;
