import React from "react";
import styles from "./ContactSection.module.scss";
import CallImage from "../HomePageImages/CallBlack.png";
import MailImage from "../HomePageImages/MailBlack.png";

interface Props {
  contactRef?: any;
}

const ContactSection: React.FC<Props> = ({ contactRef }) => {
  return (
    <div className={styles.ContactSection} ref={contactRef}>
      <div className={styles.ContactContent}>
        <h1>ОТВЕТИМ НА ЛЮБЫЕ ВОПРОСЫ</h1>
        <div className={styles.ContactInfo}>
          <div className={styles.ContactPhone}>
            <div className={styles.ContactPhoneIcon}>
              <img src={CallImage} alt="call" />
            </div>
            <div className={styles.ContactPhoneNumber}>
              <a href="tel:89892991010">8 (989) 299-10-10</a>
            </div>
          </div>
          <div className={styles.ContactMail}>
            <div className={styles.ContactMailIcon}>
              <img src={MailImage} alt="call" />
            </div>
            <div className={styles.ContactMailText}>
              <a href="mailto:info@roomseller.ru">info@roomseller.ru</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
