import { makeAutoObservable } from "mobx";
import extendDefaultHeaders from "utils/extendDefaultHeaders";
import handleApiPath from "utils/handleApiPath";
import handleInternalServerError from "utils/handleInternalServerError";
import { RootStore } from "./RootStore";
import toast from "react-hot-toast";

export interface MarketPlaces {
  id: number;
  marketPlaceParameter: string;
  name: string;
}

export interface MPParams {
  apiKey: string;
  userId: string;
  clientId: string;
  stockID: number;
  stockName: string;
  stockState: boolean;
  campaignId: string;
  mpWarehouseID: number;
  marketPlaceParameterID: number;
  customParameter: string;
}

interface MarketPlaceList {
  marketPlaceID: number;
  marketPlaceName: string;
  mpParams: {
    marketPlaceParameterID: number;
    apiKey: string;
    campaignId: number | null;
    clientId: string;
    stockID: number;
  }[];
}

export interface Analytics {
  orderNumbersFromInputParameter: string;
  orderDate: string;
  userFullName: string;
  marketPlaceName: string;
  orderPostingNumber: string;
  productTitle: string;
  productPriceOnRoomseller: number;
  packingDeliveryExpensies: number;
  productSalePriceOnMP: number;
  logisticsCommisionExpensies: number;
  returnPrice: number;
  finalSumToSeller: number;
  finalMarkup: number;
}

interface WarehouseI {
  item1: number;
  item2: string;
}

export interface OzonLogisticsI {
  workingMethodOzon: string;
  warehouseAddress: string;
  operatingMode: string;
  deliveryMethod: string;
  shippingPointAddress: string;
  shipmentTimeslot: string;
  acceptingOrders: string;
}

export interface YandexLogisticsI {
  workingMethodYandex: string;
  ordersPerDay: string;
  shippingPointAddressYandex: string;
  daysToShipping: string;
  shippingTime: string;
  shippingSchedule: string;
}

export interface WildberriesLogisticsI {
  workingMethodWildberries: string;
  warehouseAddress: string;
  deliveryType: string;
  deliveryAddress: string;
  returnedOrdersPoint: string;
}

class MarketPlaceStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  isLoading = false;

  marketplaces: MarketPlaces[] = [];

  marketplacesList: MarketPlaceList[] | null = null;

  comission: number | null = null;

  checkOzon: { [key: number]: boolean } = {};

  checkYandex: { [key: number]: boolean } = {};

  checkWildberries: boolean | null = null;

  userWarehouses: WarehouseI[] | null = null;

  contractedWarehouses: WarehouseI[] | null = null;

  notContractedWarehouses: WarehouseI[] | null = null;

  analytics: Analytics[] | null = null;

  ozonLogistics: OzonLogisticsI | null = null;

  yandexLogistics: YandexLogisticsI | null = null;

  wildberriesLogistics: WildberriesLogisticsI | null = null;

  ozonIsBanned = true;

  yandexIsBanned = true;

  wildberriesIsBanned = true;

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setCheckWildberries = (value: boolean): void => {
    this.checkWildberries = value;
  };

  setCheckOzon = (value: { [key: number]: boolean }): void => {
    this.checkOzon = { ...this.checkOzon, ...value };
  };

  setCheckYandex = (value: { [key: number]: boolean }): void => {
    this.checkYandex = { ...this.checkYandex, ...value };
  };

  setMarketplaces = (data: any): void => {
    this.marketplaces = data;
  };

  setMarketplacesList = (data: MarketPlaceList[]): void => {
    this.marketplacesList = data;
  };

  setUserWarehouses = (data: WarehouseI[]): void => {
    this.userWarehouses = data;
  };

  setContractedWarehouses = (data: WarehouseI[]): void => {
    this.contractedWarehouses = data;
  };

  setNotContractedWarehouses = (data: WarehouseI[]): void => {
    this.notContractedWarehouses = data;
  };

  setAnalytics = (data: Analytics[]): void => {
    this.analytics = data;
  };

  setOzonLogistics = (data: OzonLogisticsI): void => {
    this.ozonLogistics = data;
  };

  setYandexLogistics = (data: YandexLogisticsI): void => {
    this.yandexLogistics = data;
  };

  setWildberriesLogistics = (data: WildberriesLogisticsI): void => {
    this.wildberriesLogistics = data;
  };

  getMarketPlaces = async (): Promise<MarketPlaces[] | null> => {
    const { userStore, toastStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/GetMarketPlaces`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setMarketplaces(data);
      }
      if (!response.ok && [400, 401].indexOf(data.status_code) !== -1) {
        toastStore.notify.error(data.error_message);
      }
      if (!response.ok && !data.status_code) {
        handleInternalServerError(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getMarketPlacesList = async (): Promise<MarketPlaceList[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/GetSellerMarketPlacesList`,
        requestOptions
      );

      const data = response.status === 204 ? response : await response.json();
      if (response.ok) {
        this.setMarketplacesList(data.mpCommon);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  addMarketPlaceParameters = async (
    marketPlaceID: number,
    apiKey: string,
    clientId: string,
    campaignId: string,
    stockID: number,
    stockName: string,
    mpName: string,
    mpWarehouseID: number
  ): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        marketPlaceID,
        apiKey,
        clientId,
        stockID,
        campaignId,
        stockName,
        mpName,
        mpWarehouseID,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/AddMarketPlaceParametres`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        toast.success("Добавлено!");
        return true;
      } else {
        if (data.errors) {
          Object.values(data.errors).map((i: any) => {
            toast.error(i[0], { duration: 5000 });
          });
        } else {
          toast.error("Произошла ошибка");
        }
        return false;
      }
    } catch (error) {
      toast.error("Произошла ошибка");
      this.setIsLoading(false);

      return false;
    }
  };

  updateMarketPlaceParameters = async (
    id: number,
    apiKey: string,
    clientId: string,
    campaignId: string,
    stockID: number,
    marketPlaceId: number
  ): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        id,
        apiKey,
        clientId,
        campaignId,
        stockID,
        marketPlaceId,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/UpdateMarketPlaceParametres`,
        requestOptions
      );

      if (response.ok) {
        return true;
      }

      this.setIsLoading(false);

      return false;
    } catch (error) {
      this.setIsLoading(false);

      return false;
    }
  };

  deleteMarketPlaceParameters = async (
    marketPlaceParametersId: number,
    marketPlaceID: number,
    stockID: number,
    mpWarehouseID: number
  ): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "DELETE",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify({
        marketPlaceParametersId,
        marketPlaceID,
        stockID,
        mpWarehouseID,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(`${endpoint}/Delete`, requestOptions);

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.setIsLoading(false);

      return false;
    }
  };

  getCheckOzon = async (id: number): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_OZON");
      const response = await fetch(
        `${endpoint}/CheckOzon?marketPlaceParameterID=${id}`,
        requestOptions
      );
      if (response.ok) {
        this.setCheckOzon({ [id]: true });
      } else {
        this.setCheckOzon({ [id]: false });
      }
      this.setIsLoading(false);
      return null;
    } catch (error) {
      this.setCheckOzon({ [id]: false });
      this.setIsLoading(false);
      return null;
    }
  };

  getCheckYandex = async (id: number): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_YANDEX");
      const response = await fetch(
        `${endpoint}/CheckYandex?marketPlaceParameterID=${id}`,
        requestOptions
      );

      if (response.ok) {
        this.setCheckYandex({ [id]: true });
      } else {
        this.setCheckYandex({ [id]: false });
      }
      this.setIsLoading(false);
      return null;
    } catch (error) {
      this.setCheckYandex({ [id]: false });
      this.setIsLoading(false);
      return null;
    }
  };

  getCheckWildberries = async (id: number): Promise<boolean | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_WILDBERRIES");
      const response = await fetch(
        `${endpoint}/CheckWildberries?marketPlaceParameterID=${id}`,
        requestOptions
      );

      if (response.ok) {
        this.setCheckWildberries(true);
      } else {
        this.setCheckWildberries(false);
      }
      this.setIsLoading(false);
      return null;
    } catch (error) {
      this.setCheckWildberries(false);
      this.setIsLoading(false);
      return null;
    }
  };

  getUserWarehouses = async (): Promise<WarehouseI[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/MpWarehouseIndex`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setUserWarehouses(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getContractedWarehouses = async (): Promise<WarehouseI[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/MpWarehouseContracted`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setContractedWarehouses(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getNotContractedWarehouses = async (): Promise<WarehouseI[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/MpWarehouseNotContracted`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setNotContractedWarehouses(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getMarketPlaceParametres = async (
    userId: string,
    mpId: number
  ): Promise<MPParams[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/GetMarketPlaceParametres?UserID=${userId}&MarketplaceID=${mpId}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getAnalyticsReport = async (
    startDate: string,
    endDate: string,
    userId: string
  ) => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
    const response = await fetch(
      `${endpoint}/DownloadAnalyticsReport?UserID=${userId}&DateFrom=${startDate}&DateTo=${endDate}`,
      requestOptions
    );

    const data = await response.blob();
    if (response.ok) {
      return data;
    } else {
      return null;
    }
  };

  getAnalyticsData = async (
    userID: string,
    startDate: string,
    endDate: string
  ): Promise<Analytics[] | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/GetAnalyticsData?UserID=${userID}&DateFrom=${startDate}&DateTo=${endDate}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setAnalytics(data.item1);
      } else {
        this.setAnalytics([]);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setAnalytics([]);
      this.setIsLoading(false);

      return null;
    }
  };

  getOzonLogisticsProperties = async (
    MpWarehouseID: number
  ): Promise<OzonLogisticsI | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/GetOzonLogisticsProperties?MpWarehouseID=${MpWarehouseID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setOzonLogistics(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getYandexLogisticsProperties = async (
    MpWarehouseID: number
  ): Promise<YandexLogisticsI | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/GetYandexLogisticsProperties?MpWarehouseID=${MpWarehouseID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setYandexLogistics(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  getWildberriesLogisticsProperties = async (
    MpWarehouseID: number
  ): Promise<WildberriesLogisticsI | null> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/GetWildberriesLogisticsProperties?MpWarehouseID=${MpWarehouseID}`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        this.setWildberriesLogistics(data);
      }

      this.setIsLoading(false);

      return data;
    } catch (error) {
      this.setIsLoading(false);

      return null;
    }
  };

  changeOzonLogistics = async (
    ozonLogistics: OzonLogisticsI,
    MpWarehouseID: number
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify(ozonLogistics),
    };

    const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
    const response = await fetch(
      `${endpoint}/SetOzonLogisticsProperties?MpWarehouseID=${MpWarehouseID}`,
      requestOptions
    );

    if (response.ok) {
      toast.success("Данные Ozon изменены");
      return true;
    } else {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  changeYandexLogistics = async (
    yandexLogistics: YandexLogisticsI,
    MpWarehouseID: number
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify(yandexLogistics),
    };

    const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
    const response = await fetch(
      `${endpoint}/SetYandexLogisticsProperties?MpWarehouseID=${MpWarehouseID}`,
      requestOptions
    );

    if (response.ok) {
      toast.success("Данные Яндекс маркета изменены");
      return true;
    } else {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  changeWildberriesLogistics = async (
    wildberriesLogistics: WildberriesLogisticsI,
    MpWarehouseID: number
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    const requestOptions = {
      method: "PUT",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
      body: JSON.stringify(wildberriesLogistics),
    };

    const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
    const response = await fetch(
      `${endpoint}/SetWildberriesLogisticsProperties?MpWarehouseID=${MpWarehouseID}`,
      requestOptions
    );

    if (response.ok) {
      toast.success("Данные Wildberries изменены");
      return true;
    } else {
      toast.error("Произошла ошибка");
      return false;
    }
  };

  getIsMPBanned = async (
    MpWarehouseID: number,
    MarketplaceID: number
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/IsMPBanned?MpWarehouseID=${MpWarehouseID}&MarketPlaceID=${MarketplaceID}`,
        requestOptions
      );
      const data = await response.text();
      if (response.ok) {
        if (MarketplaceID === 1) {
          this.ozonIsBanned = data === "true" ? true : false;
        } else if (MarketplaceID === 2) {
          this.yandexIsBanned = data === "true" ? true : false;
        } else if (MarketplaceID === 3) {
          this.wildberriesIsBanned = data === "true" ? true : false;
        }
        return data === "true" ? true : false;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  addTradeBanMP = async (
    MpWarehouseID: number,
    MarketplaceID: number
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "GET",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/AddTradeBanMP?MpWarehouseID=${MpWarehouseID}&MarketPlaceID=${MarketplaceID}`,
        requestOptions
      );
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  deleteTradeBanMP = async (
    MpWarehouseID: number,
    MarketplaceID: number
  ): Promise<boolean> => {
    const { userStore } = this.rootStore;

    this.setIsLoading(true);

    const requestOptions = {
      method: "DELETE",
      headers: extendDefaultHeaders({
        Authorization: `Bearer ${userStore.token}`,
      }),
    };

    try {
      const endpoint = handleApiPath("REACT_APP_API_MARKETPLACE");
      const response = await fetch(
        `${endpoint}/DeleteTradeBan?MpWarehouseID=${MpWarehouseID}&MarketPlaceID=${MarketplaceID}`,
        requestOptions
      );
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
}

export default MarketPlaceStore;
