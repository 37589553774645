import React, { useContext, useEffect, useState } from "react";
import styles from "./Agreement.module.scss";
import SignUp from "./sign-up.svg";
import Checkbox from "../../../components/Checkbox";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import Modal from "components/Modal";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "components/Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Agreement: React.FC = observer(() => {
  const {
    userStore: {
      getTermsCondition,
      conditionLink,
      userInfo,
      info,
      getAgreement,
      agreementOwnerLink,
      agreementSellerLink,
      signAgreement,
      signTermsOfUsage,
      getUserInfo,
      getAgreementUserSignedVersion,
      getTermsConditionsLatestVersion,
      getAgreementLatest,
      getTermsConditionsUserSignedVersion,
    },
  } = useContext(RootStoreContext);

  const [versions, setVersions] = useState<{
    agreementSellerLatestVersion: string;
    agreementUserVersion: string;
    agreementWarehouseOwnerLatestVersion: string;
    conditionLatestVersion: string;
    conditionUserVersion: string;
  }>({
    agreementSellerLatestVersion: "",
    agreementUserVersion: "",
    agreementWarehouseOwnerLatestVersion: "",
    conditionLatestVersion: "",
    conditionUserVersion: "",
  });

  useEffect(() => {
    if (userInfo) {
      if (!userInfo.agreementDate) {
        getAgreement(
          userInfo.roles.includes("Seller") ? "Seller" : "WarehouseOwner"
        );
        getAgreementLatest(
          userInfo.roles.includes("Seller") ? "Seller" : "WarehouseOwner"
        ).then((i) => {
          if (i) {
            setVersions((state) => {
              return userInfo.roles.includes("Seller")
                ? {
                    ...state,
                    agreementSellerLatestVersion: i.item2,
                  }
                : {
                    ...state,
                    agreementWarehouseOwnerLatestVersion: i.item2,
                  };
            });
          }
        });
        getAgreementUserSignedVersion().then((i) => {
          if (i) {
            setVersions((state) => {
              return {
                ...state,
                agreementUserVersion: i,
              };
            });
          }
        });
      }
      if (!userInfo.roomsellerConditionsConsent) {
        getTermsCondition();
        getTermsConditionsLatestVersion().then((i) => {
          if (i) {
            setVersions((state) => {
              return {
                ...state,
                conditionLatestVersion: i.item2,
              };
            });
          }
        });
        getTermsConditionsUserSignedVersion().then((i) => {
          if (i) {
            setVersions((state) => {
              return {
                ...state,
                conditionUserVersion: i,
              };
            });
          }
        });
      }
    }
  }, [
    getTermsCondition,
    getAgreement,
    userInfo,
    getAgreementLatest,
    getAgreementUserSignedVersion,
    getTermsConditionsLatestVersion,
    getTermsConditionsUserSignedVersion,
  ]);

  const [canCheckedAgreement, setCanCheckedAgreement] =
    useState<boolean>(false);
  const [canCheckedTermsOfCondition, setCanCheckedTermsOfCondition] =
    useState<boolean>(false);

  return (
    <div className={styles.notFoundDiv}>
      <img src={SignUp} />
      <div className={styles.checkAndLinks}>
        {info && !userInfo?.roomsellerConditionsConsent && conditionLink ? (
          <div>
            <Modal
              className={styles.agreementModal}
              children={
                <div className={styles.agreementModalContent}>
                  <embed src={conditionLink} type="" />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={canCheckedTermsOfCondition}
                        onChange={() => {
                          setCanCheckedTermsOfCondition((prev) => !prev);
                        }}
                        className={styles.normalCheckbox}
                      />
                      <span>{`с условиями ознакомлен и согласен`}</span>
                    </div>
                    <div>
                      <Button
                        text="Подписать"
                        disabled={!canCheckedTermsOfCondition}
                        onClick={() => {
                          signTermsOfUsage(info.id).then(() => {
                            getUserInfo(info.id);
                          });
                        }}
                        className={styles.exportTableButton}
                      />
                    </div>
                  </div>
                </div>
              }
              isOpen={true}
              onClose={() => {}}
            />
          </div>
        ) : (
          ""
        )}
        {!userInfo?.agreementDate &&
        (agreementOwnerLink || agreementSellerLink) &&
        info ? (
          <div>
            <Modal
              className={styles.agreementModal}
              children={
                <div className={styles.agreementModalContent}>
                  <embed
                    src={
                      agreementSellerLink
                        ? agreementSellerLink
                        : agreementOwnerLink
                        ? agreementOwnerLink
                        : ""
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={canCheckedAgreement}
                        onChange={() => {
                          setCanCheckedAgreement((prev) => !prev);
                        }}
                        className={styles.normalCheckbox}
                      />
                      <span>{`с условиями ознакомлен и согласен`}</span>
                    </div>
                    <div>
                      <Button
                        text="Подписать"
                        disabled={!canCheckedAgreement}
                        onClick={() => {
                          signAgreement(info.id).then(() => {
                            getUserInfo(info.id);
                          });
                        }}
                        className={styles.exportTableButton}
                      />
                    </div>
                  </div>
                </div>
              }
              isOpen={true}
              onClose={() => {}}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default Agreement;
