import { Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../../stores/RootStore";
import styles from "./Email.module.scss";
import { observer } from "mobx-react-lite";
import Button from "components/Button";
import CustomInput from "../../../components/CustomInput";
import Checkbox from "../../../components/Checkbox";

interface Props {
  isEdit: boolean;
  id: number;
  eHeader: string;
  eContent: string;
  eIsHtmlContent: boolean;
  eImportance: boolean;
}

const EmailAddPopup: React.FC<Props> = observer(
  ({ isEdit, id, eHeader, eContent, eIsHtmlContent, eImportance }) => {
    const {
      emailStore: { addEmail, updateEmail, setEmails, emails },
    } = useContext(RootStoreContext);

    const [loading, setLoading] = useState<boolean>(false);

    const [header, setHeader] = useState<string>(eHeader);
    const [content, setContent] = useState<string>(eContent);
    const [isHtmlContent, setIsHtmlContent] = useState<boolean>(eIsHtmlContent);
    const [importance, setImportance] = useState<boolean>(eImportance);
    const addEmailClick = () => {
      setLoading(true);
      if (isEdit && emails) {
        updateEmail(id, header, content, isHtmlContent, importance).then(
          (i) => {
            if (i) {
              setEmails(
                emails.map((i) =>
                  i.id === id
                    ? {
                        id: id,
                        emailHeader: header,
                        emailContent: content,
                        isHtmlContent: isHtmlContent,
                        importance: importance,
                        createdDate: "",
                      }
                    : i
                )
              );
            }
            setLoading(false);
          }
        );
      } else {
        addEmail(header, content, isHtmlContent, importance).then(() => {
          setLoading(false);
        });
      }
    };
    return (
      <Row className={styles.EmailAddPopup}>
        <CustomInput
          className={styles.addSubscribtionName}
          name={"header"}
          onChange={(e) => setHeader(e.target.value)}
          value={header}
          placeholder={"Тема"}
        />
        <textarea
          rows={15}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Checkbox
          checked={isHtmlContent}
          onChange={() => {
            setIsHtmlContent(!isHtmlContent);
          }}
          className={styles.normalCheckbox}
          label={"HTML контент"}
        />
        <Checkbox
          checked={importance}
          onChange={() => {
            setImportance(!importance);
          }}
          className={styles.normalCheckbox}
          label={"Важное"}
        />
        <Button
          text={isEdit ? "Изменить " : "Добавить письмо"}
          className={loading ? styles.addButtonLoad : styles.addButton}
          onClick={() => addEmailClick()}
          disabled={header === "" || content === ""}
        />
      </Row>
    );
  }
);

export default EmailAddPopup;
