import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";

// const baseWidth = 1280;

// const pixelRatio = (): number => {
//   if (window?.devicePixelRatio) {
//     return window.devicePixelRatio;
//   }

//   if (window?.screen?.availWidth && document?.documentElement?.clientWidth) {
//     return window.screen.availWidth / document.documentElement.clientWidth;
//   }

//   return 0;
// };

// const defaultDevicePixelRatio = pixelRatio();

const fullHd = 1920;

const getDefaultMultiplier = (): number => {
  // const {width} = window.screen;
  const { width } = window.screen;

  let multiplier = 1;
  // const zoomMultiplier = pixelRatio() / defaultDevicePixelRatio;

  if (!width) {
    multiplier = 1;
  }

  multiplier = width < fullHd ? 1 : 1.25;

  if (width < 1280 && width > 1024) {
    multiplier = 0.9;
  }

  // multiplier = (width / baseWidth) * zoomMultiplier;

  // if (multiplier < 0.8) multiplier = 0.8;

  // if (multiplier > 1.5) multiplier = 1.5;

  // document.documentElement.style.setProperty(
  //   "--multiplier",
  //   String(multiplier)
  // );

  return multiplier;
};

class UiMultiplierStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  htmlFontSize = 10;

  multiplier = getDefaultMultiplier();

  rem = (rem: number): number => {
    return rem * this.multiplier * this.htmlFontSize;
  };

  px = (px: number): number => {
    return px / this.multiplier / this.htmlFontSize;
  };
}

export default UiMultiplierStore;
