import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import styles from "./ProfileSettings.module.scss";
import { Table } from "react-bootstrap";
import { MdOutlinePostAdd } from "react-icons/md";
import CustomInput from "components/CustomInput";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";

const AgreementUsage: React.FC = observer(() => {
  const {
    userStore: {
      getAgreement,
      addAgreement,
      getTermsCondition,
      addTermsOfCondition,
      conditionLink,
      agreementSellerLink,
      agreementOwnerLink,
      getAgreementLatest,
      getTermsConditionsLatestVersion,
    },
  } = useContext(RootStoreContext);

  const [sellerAgreementLatestVersion, setSellerAgreementLatestVersion] =
    useState<string>("");
  const [
    warehouseOwnerAgreementLatestVersion,
    setWarehouseOwnerAgreementLatestVersion,
  ] = useState<string>("");
  const [conditionLatestVersion, setConditionLatestVersion] =
    useState<string>("");

  const [loadingFiles, setLoadingFiles] = useState<{
    seller: boolean;
    warehouseOwner: boolean;
    condition: boolean;
  }>({
    seller: false,
    warehouseOwner: false,
    condition: false,
  });

  useEffect(() => {
    setLoadingFiles({
      seller: true,
      warehouseOwner: true,
      condition: true,
    });
    getTermsCondition().then(() => {
      setLoadingFiles((state) => {
        return {
          ...state,
          condition: false,
        };
      });
    });
    getAgreement("WarehouseOwner").then(() => {
      setLoadingFiles((state) => {
        return {
          ...state,
          warehouseOwner: false,
        };
      });
    });
    getAgreement("Seller").then(() => {
      setLoadingFiles((state) => {
        return {
          ...state,
          seller: false,
        };
      });
    });
    getAgreementLatest("WarehouseOwner").then((i) => {
      if (i) {
        setWarehouseOwnerAgreementLatestVersion(i.item2);
      }
    });
    getAgreementLatest("Seller").then((i) => {
      if (i) {
        setSellerAgreementLatestVersion(i.item2);
      }
    });
    getTermsConditionsLatestVersion().then((i) => {
      if (i) {
        setConditionLatestVersion(i.item2);
      }
    });
  }, [
    getTermsCondition,
    getAgreement,
    getAgreementLatest,
    getTermsConditionsLatestVersion,
  ]);

  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const uploadTermsOfCondition = (e: any) => {
    setLoadingFile(true);
    addTermsOfCondition(
      e.target.files[0],
      conditionLatestVersion
        ? `${moment().format("YY")}-${
            Number(moment().format("YY")) >
            Number(conditionLatestVersion.split("-")[0])
              ? "1"
              : Number(conditionLatestVersion.split("-")[1]) + 1
          }`
        : `${moment().format("YY")}-1`
    ).then(() => {
      setLoadingFile(false);
    });
  };
  const agreementRoles = ["Seller", "WarehouseOwner"];
  return (
    <Table striped hover style={loadingFile ? { cursor: "progress" } : {}}>
      <thead>
        <tr>
          <th>Наименование документа</th>
          <th>Скачать текущий файл</th>
          <th>Изменить файл</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Регламент</td>
          <td>
            {loadingFiles.condition ? (
              <div style={{ width: "fit-content", margin: "auto" }}>
                <BeatLoader color={"#f49100"} loading={true} size={38} />
              </div>
            ) : conditionLink ? (
              <a className={styles.fileDownloadA} href={conditionLink}>
                скачать
              </a>
            ) : (
              ""
            )}
          </td>
          <td>
            <input
              id="usageFile-input"
              accept=".pdf"
              onChange={(e) => {
                uploadTermsOfCondition(e);
                e.target.value = "";
              }}
              type="file"
              style={{ display: "none" }}
            />
            <div className={styles.versionInput}>
              <label htmlFor="usageFile-input" className={styles.packageLabelA}>
                <MdOutlinePostAdd
                  size={32}
                  color={"rgb(25, 135, 84)"}
                  cursor={"pointer"}
                />
              </label>
            </div>
          </td>
        </tr>
        {agreementRoles.map((role) => (
          <tr key={role}>
            <td>
              Договор для {role === "Seller" ? "продавца" : "владельца склада"}
            </td>
            <td>
              {role === "Seller" ? (
                loadingFiles.seller ? (
                  <div style={{ width: "fit-content", margin: "auto" }}>
                    <BeatLoader color={"#f49100"} loading={true} size={38} />
                  </div>
                ) : agreementSellerLink ? (
                  <a
                    className={styles.fileDownloadA}
                    href={agreementSellerLink}
                  >
                    скачать
                  </a>
                ) : (
                  ""
                )
              ) : loadingFiles.warehouseOwner ? (
                <div style={{ width: "fit-content", margin: "auto" }}>
                  <BeatLoader color={"#f49100"} loading={true} size={32} />
                </div>
              ) : agreementOwnerLink ? (
                <a className={styles.fileDownloadA} href={agreementOwnerLink}>
                  скачать
                </a>
              ) : (
                ""
              )}
            </td>
            <td>
              <input
                id={`${role}-input`}
                accept=".pdf"
                onChange={(e) => {
                  if (e.target.files) {
                    setLoadingFile(true);
                    if (role === "Seller") {
                      addAgreement(
                        e.target.files[0],
                        "Seller",
                        sellerAgreementLatestVersion
                          ? `${moment().format("YY")}-${
                              Number(moment().format("YY")) >
                              Number(sellerAgreementLatestVersion.split("-")[0])
                                ? "1"
                                : Number(
                                    sellerAgreementLatestVersion.split("-")[1]
                                  ) + 1
                            }`
                          : `${moment().format("YY")}-1`
                      ).then(() => {
                        setLoadingFile(false);
                      });
                    } else {
                      addAgreement(
                        e.target.files[0],
                        "WarehouseOwner",
                        warehouseOwnerAgreementLatestVersion
                          ? `${moment().format("YY")}-${
                              Number(moment().format("YY")) >
                              Number(
                                warehouseOwnerAgreementLatestVersion.split(
                                  "-"
                                )[0]
                              )
                                ? "1"
                                : Number(
                                    warehouseOwnerAgreementLatestVersion.split(
                                      "-"
                                    )[1]
                                  ) + 1
                            }`
                          : `${moment().format("YY")}-1`
                      ).then(() => {
                        setLoadingFile(false);
                      });
                    }
                  }

                  e.target.value = "";
                }}
                type="file"
                style={{ display: "none" }}
              />
              <div className={styles.versionInput}>
                <label
                  htmlFor={`${role}-input`}
                  className={styles.packageLabelA}
                >
                  <MdOutlinePostAdd
                    size={32}
                    color={"rgb(25, 135, 84)"}
                    cursor={"pointer"}
                  />
                </label>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});
export default AgreementUsage;
