import { Row, Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./PriceHistory.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import moment from "moment";
import Button from "../../../components/Button";
import BeatLoader from "react-spinners/BeatLoader";

const PriceHistory: React.FC = observer(() => {
  const {
    warehouseStore: {
      getPriceHistories,
      priceHistories,
      getPriceHistoriesCSV,
      csvText,
    },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    setLoading(true);
    getPriceHistories({
      startDate: startDate,
      endDate: endDate,
    }).then(() => {
      setLoading(false);
    });
    getPriceHistoriesCSV({
      startDate: startDate,
      endDate: endDate,
    });
  }, [getPriceHistories, startDate, endDate, getPriceHistoriesCSV]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Изменение цен</p>

      <div className={styles.wrapper}>
        <div className={styles.dates}>
          <div>
            <div>Период изменений</div>

            <div>
              от:
              {
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              }
            </div>

            <div>
              до:
              {
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              }
            </div>
          </div>
          <div>
            {csvText ? (
              <a href={csvText} download="priceHistory.csv">
                <Button
                  text="Экспорт таблицы"
                  className={styles.exportTableButton}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
        {loading ? (
          <div style={{ width: "fit-content", margin: "auto" }}>
            <BeatLoader color={"#f49100"} loading={loading} size={50} />
          </div>
        ) : (
          <Row>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Наименование товар</th>
                  <th>Код поставщика</th>
                  <th>Артикул</th>
                  <th>Было</th>
                  <th>Стало</th>
                </tr>
              </thead>
              <tbody>
                {priceHistories?.length ? (
                  priceHistories.map((item, index1) =>
                    item.priceHistory.map(
                      (i, index2) =>
                        index2 < item.priceHistory.length - 1 && (
                          <tr key={index1 + index2}>
                            <td>
                              {item.lastChangeDate
                                ? moment(item.lastChangeDate).format(
                                    "DD.MM.YYYY"
                                  )
                                : ""}
                            </td>
                            <td>
                              <a
                                href={`/warehouse/product/${item.productID}`}
                                target="_blank"
                                className={styles.cardTitle}
                              >
                                {item.productTitle}
                              </a>
                            </td>
                            <td>{item.sellerVendorCode}</td>
                            <td>{item.articul}</td>
                            <td>{item.priceHistory[index2 + 1].price}</td>
                            <td>{i.price}</td>
                          </tr>
                        )
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={6} style={{ textAlign: "center" }}>
                      В заданный период времени нет никаких изменений цен. Для
                      просмотра изменений выберите другой период.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        )}
      </div>
    </div>
  );
});
export default PriceHistory;
