import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Product.module.scss";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OzonLogo from "assets/icons/ozon.png";
import WildberriesLogo from "assets/icons/wildberries.png";
import YandexLogo from "assets/icons/yandex.png";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import {
  MdDeleteOutline,
  MdOutlineAddBox,
  MdOutlineFavorite,
  MdOutlineFavoriteBorder,
  MdOutlineIndeterminateCheckBox,
} from "react-icons/md";
import toast from "react-hot-toast";
import DeleteModal from "../../../../components/DeleteModal";

interface Props {
  data: {
    id: string;
    stockPrice: number;
    title: string;
    image: string;
    isAddedToOzon: boolean;
    isAddedToWildberries: boolean;
    isAddedToYandexMarket: boolean;
    isFavorite: boolean;
    amount: number;
    stockAmount?: number;
    mpWarehouseID?: number;
    childProducts?: string[];
  };
  list?: boolean;
  onClick?: (id: string) => void;
  collectionMode?: boolean;
  collectionAddProducts?: { product: string; quantity: number }[];
  setCollectionAddProducts?: React.Dispatch<
    React.SetStateAction<{ product: string; quantity: number }[]>
  >;
  isCollectionPage?: boolean;
}

const Product: React.FC<Props> = observer(
  ({
    data,
    collectionMode = false,
    collectionAddProducts = [],
    setCollectionAddProducts = () => {},
    isCollectionPage = false,
  }) => {
    const {
      warehouseStore: { updateProductFavorite, getFavoriteCount },
      userStore: { info },
      collectionsStore: { deleteCollection, setCollections, collections },
    } = useContext(RootStoreContext);
    const navigate = useNavigate();
    const [like, setLike] = useState<boolean>(data.isFavorite);

    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>("");

    const likeClick = () => {
      updateProductFavorite(data.id).then((data) => {
        if (data) {
          if (!like) {
            toast.success("Товар добавлен в избранные");
          } else {
            toast.success("Товар удален из избранных");
          }
          getFavoriteCount();
          setLike(!like);
        }
      });
    };
    return (
      <div className={classNames(styles.root)}>
        <Link
          to={`/warehouse/product/${data.id}`}
          className={styles.productLink}
        >
          <div className={styles.marketPlacesLogo}>
            {data.isAddedToOzon ? <img src={OzonLogo} alt="ozon logo" /> : ""}
            {data.isAddedToWildberries ? (
              <img
                src={WildberriesLogo}
                alt="wildberries logo"
                className={styles.wildberriesLogo}
              />
            ) : (
              ""
            )}
            {data.isAddedToYandexMarket ? (
              <img src={YandexLogo} alt="yandex market logo" />
            ) : (
              ""
            )}
          </div>
          <div className={styles.imageWrapper}>
            {data.image.split("/")[data.image.split("/").length - 1] !==
            "images" ? (
              <img className={styles.image} src={data.image} alt="" />
            ) : (
              <img
                className={styles.image}
                src={"https://kare.ee/images/no-image.jpg"}
                alt=""
              />
            )}
          </div>

          <div className={styles.content}>
            <p className={styles.price}>
              {parseFloat(Number(data.stockPrice).toFixed(2))} ₽
            </p>
            <p className={styles.amount}>
              Количество:{" "}
              {info?.roles.includes("SuperAdmin") && !isCollectionPage
                ? data.stockAmount
                : data.amount}
              шт.
            </p>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <span>{data.title}</span>
                </Tooltip>
              }
            >
              <p
                className={styles.title}
                onClick={() => {
                  navigate(`/warehouse/product/${data.id}`);
                }}
              >
                <span>{data.title}</span>
              </p>
            </OverlayTrigger>
            <div className={styles.footer}>
              <Button
                text="Обзор"
                size="big"
                onClick={() => {
                  navigate(`/warehouse/product/${data.id}`);
                }}
                className={
                  info?.roles.includes("SuperAdmin") && !isCollectionPage
                    ? data.stockAmount && data.stockAmount > 0
                      ? styles.viewButton
                      : styles.viewButton2
                    : data.amount > 0
                    ? styles.viewButton
                    : styles.viewButton2
                }
              />
            </div>
          </div>
        </Link>
        {collectionMode ? (
          <div className={styles.addProductToCollection}>
            <MdOutlineIndeterminateCheckBox
              size={32}
              color={"#f33"}
              onClick={() =>
                collectionAddProducts.filter((i) => i.product === data.id)[0]
                  ? collectionAddProducts.filter(
                      (i) => i.product === data.id
                    )[0].quantity === 1
                    ? setCollectionAddProducts(
                        collectionAddProducts.filter(
                          (i) => i.product !== data.id
                        )
                      )
                    : setCollectionAddProducts(
                        collectionAddProducts.map((i) =>
                          i.product === data.id
                            ? { ...i, quantity: i.quantity - 1 }
                            : i
                        )
                      )
                  : {}
              }
              className={styles.deleteProductIcon}
            />
            <div className={styles.productCount}>
              {collectionAddProducts.filter((i) => i.product === data.id)[0]
                ? collectionAddProducts.filter((i) => i.product === data.id)[0]
                    .quantity
                : 0}
            </div>

            <MdOutlineAddBox
              size={32}
              color={"#198754"}
              onClick={() =>
                collectionAddProducts.filter((i) => i.product === data.id)[0]
                  ? setCollectionAddProducts(
                      collectionAddProducts.map((i) =>
                        i.product === data.id
                          ? { ...i, quantity: i.quantity + 1 }
                          : i
                      )
                    )
                  : setCollectionAddProducts([
                      ...collectionAddProducts,
                      { product: data.id, quantity: 1 },
                    ])
              }
              className={styles.addIcon}
            />
          </div>
        ) : like ? (
          <MdOutlineFavorite
            size={32}
            onClick={() => likeClick()}
            className={styles.favoriteIcon}
            color="#f49100"
          />
        ) : (
          <MdOutlineFavoriteBorder
            size={32}
            onClick={() => likeClick()}
            className={styles.favoriteIcon}
            color="#f49100"
          />
        )}
        {isCollectionPage ? (
          <MdDeleteOutline
            size={32}
            onClick={() => {
              setIsDelete(true);
            }}
            className={styles.deleteIcon}
            color={"#f33"}
          />
        ) : (
          ""
        )}
        {isDelete && (
          <DeleteModal
            text={"Удалить  набор?"}
            close={() => setIsDelete(false)}
            okFunc={() => {
              deleteCollection(data.id).then((i) => {
                if (i && collections) {
                  setCollections(collections.filter((i) => i.id !== data.id));
                }
                setIsDelete(false);
              });
            }}
          />
        )}
      </div>
    );
  }
);
export default Product;
