import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import styles from "./Finance.module.scss";
import { OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { MdDeleteOutline, MdOutlinePostAdd } from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";
import { HiDocumentDownload } from "react-icons/hi";
import DeleteModal from "../../../components/DeleteModal";

interface Props {
  userId: string;
  mpWarehouseId: number;
}

const ABody: React.FC<Props> = observer(({ userId, mpWarehouseId }) => {
  const {
    financeStore: {
      getWarehouseReconciliationActs,
      acts,
      uploadFinance,
      deleteFinanceAct,
      setActs,
    },
  } = useContext(RootStoreContext);

  const [actsLoading, setActsLoading] = useState<boolean>(true);
  const [DocumentLoad, setDocumentLoad] = useState<boolean>(false);

  const [uploadFinanceDocument, setUploadFinanceDocument] = useState(null);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");

  useEffect(() => {
    setActsLoading(true);
    getWarehouseReconciliationActs(userId, mpWarehouseId).then(() => {
      setActsLoading(false);
    });
  }, [getWarehouseReconciliationActs, userId, mpWarehouseId]);

  const onChange = (e: any) => {
    setDocumentLoad(true);
    uploadFinance(e.target.files[0], userId, mpWarehouseId).then(() => {
      setDocumentLoad(false);
      setUploadFinanceDocument(null);
    });
  };
  return (
    <Row className={styles.financeUpload}>
      <input
        id="file-input"
        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
        onChange={(e) => {
          onChange(e);
          e.target.value = "";
        }}
        type="file"
        style={{ display: "none" }}
      />

      {actsLoading ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={actsLoading} size={50} />
        </div>
      ) : (
        <Table striped hover>
          <thead>
            <tr>
              <th>Наименование файла</th>
              <th className={styles.packageLabel}>
                {DocumentLoad ? (
                  <div style={{ width: "fit-content", margin: "auto" }}>
                    <BeatLoader
                      color={"#fff"}
                      loading={DocumentLoad}
                      size={38}
                    />
                  </div>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Добавить файл</Tooltip>}
                  >
                    <div>
                      <label
                        htmlFor="file-input"
                        className={styles.packageLabelA}
                      >
                        <MdOutlinePostAdd size={38} color={"#fff"} />
                      </label>
                    </div>
                  </OverlayTrigger>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {acts &&
              acts.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item[0]}</td>
                    <td className={styles.packageLabel}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Скачать</Tooltip>}
                      >
                        <div>
                          <a href={item[1]} className={styles.packageLabelA}>
                            <HiDocumentDownload size={38} />
                          </a>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td style={{ width: "38px" }}>
                      <MdDeleteOutline
                        size={38}
                        onClick={() => {
                          setIsDelete(true);
                          setDeleteId(item[0]);
                        }}
                        className={styles.deleteIcon}
                        color={"#f33"}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
      {isDelete && acts && (
        <DeleteModal
          text={"Удалить файл?"}
          close={() => setIsDelete(false)}
          okFunc={() => {
            deleteFinanceAct(deleteId, userId).then((i) => {
              if (i) {
                setActs(acts.filter((i) => i[0] !== deleteId));
              }
              setIsDelete(false);
            });
          }}
        />
      )}
    </Row>
  );
});
export default ABody;
