import styles from "./alghorithmOfWorkSection.module.scss";
import { Col, Row } from "react-bootstrap";
import { Diagram } from "./diagram";

export const AlghorithmOfWorkSection = () => {
  const alghorithms = [
    "Поставщик загружает товары на RS",
    "Продавец выбирает товар на RS",
    "Продавец добавляет товар в свой магазин на Маркетплейсе",
    "Поступает заказ из Маркетплейса на RS",
    "Продавец оплачивает  заказ, сборку и доставку Поставщику",
    "Поставщик	упаковывает и доставляет заказ на Маркетплейс от имени Продавца",
  ];
  return (
    <div className={styles.alghorithmOfWorkSection}>
      <h1>АЛГОРИТМ РАБОТЫ ЧЕРЕЗ RS</h1>
      <div className={styles.alghorithmOfWorkSectionDiagram}>
        <div>
          <Diagram />
        </div>

        <Row>
          <Col className={styles.alghorithmOfWorkSectionList}>
            {alghorithms.map((str, index) => (
              <p key={index + 1}>
                <span>{index + 1}</span>

                <p> {str}</p>
              </p>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};
