import styled from "styled-components";

export type IconSize =
  | "small"
  | "normal"
  | "lite"
  | "medium"
  | "big"
  | "huge"
  | "extra-huge"
  | "extra-big";

interface Props {
  component: React.ReactNode;
  color?: string;
  hoverColor?: string;
  className?: string;
  size: IconSize | number;
  title?: string;
  onClick?: () => void;
  cursor?: boolean;
  opacity?: number;
  transition?: number | "inherit";
}

interface SvgContainerProps {
  color: string;
  opacity: number;
  size: IconSize | number;
  hoverColor: string;
  cursor?: number;
  transition: string;
}

const sizes = {
  small: 0.8,
  normal: 1,
  lite: 1.2,
  medium: 1.4,
  big: 1.6,
  "extra-big": 2,
  huge: 2.4,
  "extra-huge": 3.3,
};

const getSize = (size: IconSize | number): string =>
  typeof size === "string" ? `${sizes[size]}rem` : `${size}rem`;

const SvgContainer = styled.div<SvgContainerProps>`
  color: ${(props) => props.color || "inherit"};
  opacity: ${(props) => props.opacity};
  width: ${(props) => getSize(props.size)};
  height: ${(props) => getSize(props.size)};
  cursor: ${(props) => (props.cursor ? "pointer" : "inherit")};
  transition: ${(props) => props.transition};

  &:hover {
    color: ${(props) => props.hoverColor || "inherit"};
  }

  & > svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const SvgIcon: React.FC<Props> = ({
  component,
  color = "inherit",
  hoverColor = color,
  size,
  className = "",
  title = "",
  onClick,
  cursor = false,
  opacity = 1,
  transition = 0.2,
}) => {
  return (
    <SvgContainer
      color={color}
      opacity={opacity}
      size={size}
      cursor={Boolean(cursor) || Boolean(onClick) ? 1 : 0}
      hoverColor={hoverColor}
      className={className}
      title={title}
      transition={
        typeof transition === "number" ? `color ${transition}s` : "inherit"
      }
      onClick={onClick}
      role="img"
    >
      {component}
    </SvgContainer>
  );
};

export default SvgIcon;
