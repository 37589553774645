import React, { useRef } from "react";
import styles from "./Home.module.scss";
import MenuSection from "./MenuSection";
import SellerSection from "./SellerSection";
// import AboutPlatformSection from "./AboutPlatformSection";
import AdvantageSection from "./AdvantageSection";
import ReliablySection from "./ReliablySection";
import ContactSection from "./ContactSection";
import Footer from "./Footer";
import { Container } from "react-bootstrap";
import AboutUsSection from "./AboutUsSection";

const Home: React.FC = () => {
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const advantageRef = useRef(null);
  const sellerRef = useRef(null);
  const reliablyRef = useRef(null);

  return (
    <Container fluid className={styles.Home}>
      <MenuSection
        aboutRef={aboutRef}
        contactRef={contactRef}
        advantageRef={advantageRef}
        sellerRef={sellerRef}
        reilablyRef={reliablyRef}
      />

      <Container className={styles.contentWrapper}>
        <AboutUsSection aboutRef={aboutRef} />
        <SellerSection sellerRef={sellerRef} />
        <ReliablySection reliablyRef={reliablyRef} />
        <AdvantageSection advantageRef={advantageRef} />
        <ContactSection contactRef={contactRef} />
      </Container>
      <Footer
        aboutRef={aboutRef}
        contactRef={contactRef}
        advantageRef={advantageRef}
        sellerRef={sellerRef}
        reilablyRef={reliablyRef}
      />
    </Container>
  );
};

export default Home;
