import CustomSelect, { SelectValue } from "components/CustomSelect";
import React, { useContext, useState } from "react";
import styles from "./Analytics.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import { useSearchParams } from "react-router-dom";
import Button from "../../../components/Button";
import { BeatLoader } from "react-spinners";

const AnalyticsFilters: React.FC = observer(() => {
  const {
    orderStore: { sellers },
    userStore: { info },
    marketPlacesStore: { getAnalyticsReport },
  } = useContext(RootStoreContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadAnalytics, setLoadAnalytics] = useState<boolean>(false);

  const seller = searchParams.get("seller");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  const newParams = new URLSearchParams(searchParams);

  const handleFilters = (e: SelectValue, name: string) => {
    newParams.set(name, e.value);
    setSearchParams(newParams);
  };

  const handleDateFilters = (e: string, name: string) => {
    newParams.set(name, e);
    setSearchParams(newParams);
  };
  return (
    <>
      <div style={{ display: "flex" }}>
        {info?.roles.includes("SuperAdmin") ? (
          <CustomSelect
            className={styles.normal}
            name="seller"
            placeholder="Продавец"
            onChange={(e) => {
              handleFilters(e, "seller");
            }}
            value={seller}
            list={sellers.map(
              (item) =>
                ({
                  value: item.id,
                  label: item.sellerName,
                } as any)
            )}
          />
        ) : (
          <div></div>
        )}

        {startDate && endDate && endDate !== "null" && (
          <div className={styles.dates}>
            <div>
              <div>Период:</div>

              <div>
                {
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      if (e.target.value) {
                        handleDateFilters(e.target.value, "startDate");
                      }
                    }}
                  />
                }
              </div>
              <div>
                {
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      if (e.target.value) {
                        handleDateFilters(e.target.value, "endDate");
                      }
                    }}
                  />
                }
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={loadAnalytics ? styles.analyticsLoad : styles.analytics}>
        {startDate && endDate && info && (
          <Button
            onClick={() => {
              setLoadAnalytics(true);
              getAnalyticsReport(startDate, endDate, info.id).then((file) => {
                if (file) {
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = URL.createObjectURL(file);
                  a.download = "Analytics";
                  document.body.appendChild(a);
                  a.click();
                }
                setLoadAnalytics(false);
              });
            }}
            className={
              loadAnalytics
                ? styles.analyticsButtonLoad
                : styles.analyticsButton
            }
            disabled={loadAnalytics}
          >
            {loadAnalytics ? (
              <div style={{ width: "fit-content", margin: "auto" }}>
                <BeatLoader color={"#f49100"} loading={true} size={20} />
              </div>
            ) : (
              "Экспорт"
            )}
          </Button>
        )}
      </div>
    </>
  );
});
export default AnalyticsFilters;
